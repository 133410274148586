var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.swiperShow
    ? _c(
        "div",
        { staticClass: "similar-list" },
        [
          _c(
            "swiper",
            { ref: "mySwiper", attrs: { options: _vm.swiperOptions } },
            _vm._l(_vm.listData, function (item, index) {
              return _c(
                "swiper-slide",
                {
                  key: index,
                  staticClass: "list-item",
                  attrs: { id: item.id },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "item-img",
                      on: {
                        click: function ($event) {
                          return _vm.itemClick(item)
                        },
                      },
                    },
                    [
                      item.imgUrl
                        ? _c("img", { attrs: { src: item.imgUrl } })
                        : [
                            _c(
                              "span",
                              {
                                staticClass:
                                  "pic-name text-ellipsis2 center-middle-box",
                              },
                              [_vm._v(_vm._s(item.title))]
                            ),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/business/logobg2.png"),
                                alt: "",
                              },
                            }),
                          ],
                    ],
                    2
                  ),
                  _c("div", { staticClass: "item-title" }, [
                    _vm._v(_vm._s(item.title)),
                  ]),
                  false
                    ? _c("div", { staticClass: "item-content" }, [
                        _vm._v(_vm._s(item.content)),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            1
          ),
          _c("div", {
            staticClass: "swiper-button-prev",
            attrs: { slot: "button-prev" },
            slot: "button-prev",
          }),
          _c("div", {
            staticClass: "swiper-button-next",
            attrs: { slot: "button-next" },
            slot: "button-next",
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "creativityPut-box" }, [
    _c("div", { staticClass: "content-item" }, [
      _c(
        "div",
        { staticClass: "item-title" },
        [
          _vm._v(" 投放媒体占比 "),
          _c(
            "Poptip",
            { attrs: { trigger: "hover", placement: "right", padding: "0 0" } },
            [
              _c("Icon", {
                staticClass: "help_icon",
                attrs: { type: "ios-help-circle-outline" },
              }),
              _c(
                "div",
                {
                  staticClass: "m-tips",
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [
                  _c("p", [
                    _c("span", [_vm._v("• 广告创意投放的媒体平台偏好占比。")]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "chart-box" },
        [
          _c("pieChart", {
            attrs: { echartData: _vm.PreferenceData, legendShow: true },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "content-item" }, [
      _c(
        "div",
        { staticClass: "item-title" },
        [
          _vm._v(" 创意投放趋势 "),
          _c(
            "Poptip",
            { attrs: { trigger: "hover", placement: "right", padding: "0 0" } },
            [
              _c("Icon", {
                staticClass: "help_icon",
                attrs: { type: "ios-help-circle-outline" },
              }),
              _c(
                "div",
                {
                  staticClass: "m-tips",
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [_c("p", [_c("span", [_vm._v("• 每日创意广告的投放趋势。")])])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "chart-box" },
        [
          _c("LineChart", {
            attrs: {
              echartData: _vm.putData,
              lineColor: "#3519FB",
              textColor: "#848295",
              colorStopsStart: "rgba(62,78,255,0.1)",
              colorStopsEnd: "rgba(62,78,255,0)",
              categoryTitle: "投放量",
              minLength: _vm.putData.length - 7,
              maxLength: _vm.putData.length - 1,
              isSmooth: false,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "content-item" }, [
      _c(
        "div",
        { staticClass: "item-title" },
        [
          _vm._v(" 创意曝光趋势 "),
          _c(
            "Poptip",
            { attrs: { trigger: "hover", placement: "right", padding: "0 0" } },
            [
              _c("Icon", {
                staticClass: "help_icon",
                attrs: { type: "ios-help-circle-outline" },
              }),
              _c(
                "div",
                {
                  staticClass: "m-tips",
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [
                  _c("p", [
                    _c("span", [_vm._v("• 每日广告投放曝光次数趋势。")]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "chart-box" },
        [
          _c("LineChart", {
            attrs: {
              echartData: _vm.exposureData,
              lineColor: "#03B7A3",
              textColor: "#848295",
              colorStopsStart: "rgba(0,187,135,0.08)",
              colorStopsEnd: "rgba(0,187,135,0)",
              categoryTitle: "曝光量",
              minLength: _vm.exposureData.length - 7,
              maxLength: _vm.exposureData.length - 1,
              isSmooth: false,
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "content-item" }, [
      _c(
        "div",
        { staticClass: "item-title" },
        [
          _vm._v(" 创意点击趋势 "),
          _c(
            "Poptip",
            { attrs: { trigger: "hover", placement: "right", padding: "0 0" } },
            [
              _c("Icon", {
                staticClass: "help_icon",
                attrs: { type: "ios-help-circle-outline" },
              }),
              _c(
                "div",
                {
                  staticClass: "m-tips",
                  attrs: { slot: "content" },
                  slot: "content",
                },
                [
                  _c("p", [
                    _c("span", [_vm._v("• 每日广告投放点击次数趋势。")]),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "chart-box" },
        [
          _c("LineChart", {
            attrs: {
              echartData: _vm.clickData,
              lineColor: "#F89C00",
              textColor: "#848295",
              colorStopsStart: "rgba(248,156,0,0.1) ",
              colorStopsEnd: "rgba(248,156,0,0)",
              categoryTitle: "点击量",
              minLength: _vm.clickData.length - 7,
              maxLength: _vm.clickData.length - 1,
              isSmooth: false,
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
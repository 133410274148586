export default {
  //产品数据
  //元素解析
  elementEchart: [
    {
      name: "视频混剪",
      data: [
        {
          value: 30,
          name: "单人情景剧",
          cost: "9.29%",
          conver: "1853.98",
          color: "#F89C00",
        },
        {
          value: 40,
          name: "演绎情景",
          cost: "7.09%",
          conver: "1388.65 ",
          color: "#00BB87",
        },
        {
          value: 30,
          name: "多人情景剧",
          cost: "7.09%",
          conver: "1388.65 ",
          color: "#3519FB",
        },
      ],
      option: {
        tooltip: {
          formatter: (params) => {
            return ` <div style="color:#999999;margin-bottom:10px"> ${params.seriesName} </div> 
                    <div style="display:flex; color:#333; width:150px; justify-content:space-between; line-height: 26px; padding:0 5px" ><p> ${params.marker}   ${params.data.name} </p> ${params.data.value}%</div>`;
          },
          borderColor: "#FFFFFF",
          textStyle: {
            fontSize: 12,
            color: "#333",
          },
        },
        legend: {
          show: false,
          icon: "circle",
          width: "80px",
          top: "10%",
          textStyle: {
            color: "#333333",
            fontSize: "12",
            fontWeight: "400",
          },
          left: "200px",
        },
        color: [
          {
            colorStops: [
              {
                color: "#F89C00",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
          {
            colorStops: [
              {
                color: "#00BB87",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
          {
            colorStops: [
              {
                color: "#3519FB",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
        ],
        series: [
          {
            name: "视频混剪",
            type: "pie",
            width: 66,
            left: 5,
            radius: ["75%", "88%", "88%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              formatter: "使用率",
              color: "#999999",
              fontSize: "11",
              fontWeight: "400",
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: "使用率",
                color: "#999999",
                fontSize: "11",
                fontWeight: "400",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 30,
                name: "单人情景剧",
                cost: "9.29%",
                conver: "1853.98",
                color: "#F89C00",
              },
              {
                value: 40,
                name: "演绎情景",
                cost: "7.09%",
                conver: "1388.65 ",
                color: "#00BB87",
              },
              {
                value: 30,
                name: "多人情景剧",
                cost: "7.09%",
                conver: "1388.65 ",
                color: "#3519FB",
              },
            ],
          },
        ],
      },
    },
    {
      name: "图文展示",
      data: [
        {
          value: 30,
          name: "应用录屏",
          cost: "9.29%",
          conver: "1853.98",
          color: "#F89C00",
        },
        {
          value: 40,
          name: "纸上文字",
          cost: "7.09%",
          conver: "1388.65 ",
          color: "#00BB87",
        },
        {
          value: 30,
          name: "图文快闪",
          cost: "7.09%",
          conver: "1388.65 ",
          color: "#3519FB",
        },
      ],
      option: {
        tooltip: {
          formatter: (params) => {
            return ` <div style="color:#999999;margin-bottom:10px"> ${params.seriesName} </div> 
                    <div style="display:flex; color:#333; width:150px; justify-content:space-between; line-height: 26px; padding:0 5px" ><p> ${params.marker}   ${params.data.name} </p> ${params.data.value}%</div>`;
          },
          borderColor: "#FFFFFF",
          textStyle: {
            fontSize: 12,
            color: "#333",
          },
        },
        legend: {
          show: false,
          icon: "circle",
          width: "80px",
          top: "10%",
          textStyle: {
            color: "#333333",
            fontSize: "12",
            fontWeight: "400",
          },
          left: "200px",
        },
        color: [
          {
            colorStops: [
              {
                color: "#F89C00",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
          {
            colorStops: [
              {
                color: "#00BB87",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
          {
            colorStops: [
              {
                color: "#3519FB",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
        ],
        series: [
          {
            name: "图文展示",
            type: "pie",
            width: 66,
            left: 5,
            radius: ["75%", "88%", "88%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              formatter: "使用率",
              color: "#999999",
              fontSize: "11",
              fontWeight: "400",
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: "使用率",
                color: "#999999",
                fontSize: "11",
                fontWeight: "400",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 30,
                name: "应用录屏",
                cost: "9.29%",
                conver: "1853.98",
                color: "#F89C00",
              },
              {
                value: 40,
                name: "纸上文字",
                cost: "7.09%",
                conver: "1388.65 ",
                color: "#00BB87",
              },
              {
                value: 30,
                name: "图文快闪",
                cost: "7.09%",
                conver: "1388.65 ",
                color: "#3519FB",
              },
            ],
          },
        ],
      },
    },
    {
      name: "真人口播",
      data: [
        {
          value: 30,
          name: "单人口播",
          cost: "9.29%",
          conver: "1853.98",
          color: "#F89C00",
        },
        {
          value: 40,
          name: "双人口播",
          cost: "7.09%",
          conver: "1388.65 ",
          color: "#00BB87",
        },
        {
          value: 30,
          name: "多人口播",
          cost: "7.09%",
          conver: "1388.65 ",
          color: "#3519FB",
        },
      ],
      option: {
        tooltip: {
          formatter: (params) => {
            return ` <div style="color:#999999;margin-bottom:10px"> ${params.seriesName} </div> 
                    <div style="display:flex; color:#333; width:150px; justify-content:space-between; line-height: 26px; padding:0 5px" ><p> ${params.marker}   ${params.data.name} </p> ${params.data.value}%</div>`;
          },
          borderColor: "#FFFFFF",
          textStyle: {
            fontSize: 12,
            color: "#333",
          },
        },
        legend: {
          show: false,
          icon: "circle",
          width: "80px",
          top: "10%",
          textStyle: {
            color: "#333333",
            fontSize: "12",
            fontWeight: "400",
          },
          left: "200px",
        },
        color: [
          {
            colorStops: [
              {
                color: "#F89C00",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
          {
            colorStops: [
              {
                color: "#00BB87",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
          {
            colorStops: [
              {
                color: "#3519FB",
                offset: 0,
              },
            ],
            global: false,
            type: "linear",
            x: 0,
            x2: 1,
            y: 0,
            y2: 0,
          },
        ],
        series: [
          {
            name: "真人口播",
            type: "pie",
            width: 66,
            left: 5,
            radius: ["75%", "88%", "88%"],
            avoidLabelOverlap: true,
            label: {
              show: true,
              formatter: "使用率",
              color: "#999999",
              fontSize: "11",
              fontWeight: "400",
              position: "center",
            },
            emphasis: {
              label: {
                show: true,
                formatter: "使用率",
                color: "#999999",
                fontSize: "11",
                fontWeight: "400",
              },
            },
            labelLine: {
              show: false,
            },
            data: [
              {
                value: 30,
                name: "单人口播",
                cost: "9.29%",
                conver: "1853.98",
                color: "#F89C00",
              },
              {
                value: 40,
                name: "双人口播",
                cost: "7.09%",
                conver: "1388.65 ",
                color: "#00BB87",
              },
              {
                value: 30,
                name: "多人口播",
                cost: "7.09%",
                conver: "1388.65 ",
                color: "#3519FB",
              },
            ],
          },
        ],
      },
    },
  ],
  sallList: [
    {
      name: "新人礼",
      value: 65,
    },
    {
      name: "折扣",
      value: 17,
    },
    {
      name: "送礼包",
      value: 17,
    },
  ],
  threeTimeList: [
    {
      name: "提问开头",
      value: 25.6,
    },
    {
      name: "反常猎奇",
      value: 20.5,
    },
    {
      name: "冲突打压",
      value: 5.1,
    },
    {
      name: "写字字幕",
      value: 7.7,
    },
    {
      name: "圈定用户",
      value: 15.4,
    },
    {
      name: "激动情绪",
      value: 5.1,
    },
    {
      name: "两性暗示",
      value: 5.1,
    },
    {
      name: "满足自尊",
      value: 2.6,
    },
    {
      name: "元素特写",
      value: 12.8,
    },
  ],
  performList: [
    {
      name: "使用者安利",
      value: 33.3,
    },
    {
      name: "熟人推荐",
      value: 23.3,
    },
    {
      name: "达人种草",
      value: 30.0,
    },
    {
      name: "对比解说",
      value: 6.7,
    },
    {
      name: "偶然发现",
      value: 3.3,
    },
    {
      name: "支付环节使用",
      value: 3.3,
    },
  ],
  //表格消耗数据
  gaugeEchart: {
    series: [
      {
        type: "gauge",
        startAngle: 180,
        endAngle: 0,
        min: 0,
        max: 4000,
        splitNumber: 8,
        axisLine: {
          lineStyle: {
            width: 4,
            color: [
              [0.25, "#5E90FF"],
              [0.5, "#20EEC9"],
              [0.75, "#FFC070"],
              [1, "#FF5381"],
            ],
          },
        },
        pointer: {
          icon: "path://M12.8,0.7l12,40.1H0.7L12.8,0.7z",
          length: "12%",
          width: 4,
          offsetCenter: [0, "-38%"],
          itemStyle: {
            color: "auto",
          },
        },
        axisTick: {
          length: 4,
          lineStyle: {
            color: "auto",
            width: 1,
          },
        },
        splitLine: {
          length: 8,
          lineStyle: {
            color: "auto",
            width: 1,
          },
        },
        axisLabel: {
          color: "#464646",
          fontSize: 12,
          distance: -38,
          formatter: function (value) {
            if (value === 3500) {
              return "{s| S}";
            } else if (value === 2500) {
              return "{a| A}";
            } else if (value === 1500) {
              return "{b| B}";
            } else if (value === 500) {
              return "{c| C}";
            }
            return "";
          },
          rich: {},
        },
        title: {
          offsetCenter: [0, "14%"],
          fontSize: 12,
          fontweight: 400,
          color: "#999999",
        },
        detail: {
          fontSize: 18,
          offsetCenter: [0, "56%"],
          valueAnimation: true,
          formatter: function (value) {
            return "¥" + value.toLocaleString("en-US");
          },
          fontweight: 600,
          color: "#333333",
        },
        data: [
          {
            value: 8000,
            name: "消耗",
          },
        ],
      },
    ],
  },
  //四项图
  fourOption: {
    title: [
      {
        text: "高消耗+高转化",
        subtext: "表现效果相对较好",
        z: 1,
        textAlign: "right",
        left: "96%",
        top: 10,
        width: 51,
        height: " 24px",
        backgroundColor: "#F1F2FB",
        padding: 10,
        textStyle: {
          fontSize: 12,
          fontWeight: 600,
          color: "#6E7ED5",
        },
        subtextStyle: {
          color: "#6E7ED5", // 主标题文字的颜色。
          fontWeight: 400,
          fontSize: 12, // 字体大小
          lineHeight: "12", // 行高
        },
      },
      {
        text: "低消耗+高转化",
        subtext: "建议持续关注",
        z: 1,
        textAlign: "right",
        left: "96%",
        bottom: 10,
        backgroundColor: "#F1F2FB",
        padding: 10,
        textStyle: {
          fontSize: 12,
          fontWeight: 600,
          color: "#6E7ED5",
        },
        subtextStyle: {
          color: "#6E7ED5", // 主标题文字的颜色。
          fontWeight: 400,
          fontSize: 12, // 字体大小
          lineHeight: "12", // 行高
        },
      },
      {
        text: "低消耗+低转化",
        subtext: "表现效果相对不佳",
        z: 1,
        textAlign: "left",
        left: 10,
        bottom: 10,
        backgroundColor: "#F1F2FB",
        padding: 10,
        textStyle: {
          fontSize: 12,
          fontWeight: 600,
          color: "#6E7ED5",
        },
        subtextStyle: {
          color: "#6E7ED5", // 主标题文字的颜色。
          fontWeight: 400,
          fontSize: 12, // 字体大小
          lineHeight: "12", // 行高
        },
      },
      {
        text: "高消耗+低转化",
        subtext: "建议持续关注",
        z: 1,
        textAlign: "left",
        left: 10,
        top: 10,
        backgroundColor: "#F1F2FB",
        padding: 10,
        textStyle: {
          fontSize: 12,
          fontWeight: 600,
          color: "#6E7ED5",
        },
        subtextStyle: {
          color: "#6E7ED5", // 主标题文字的颜色。
          fontWeight: 400,
          fontSize: 12, // 字体大小
          lineHeight: "12", // 行高
        },
      },
    ],
    grid: {
      top: "10%",
      left: "10%",
      right: "10%",
      bottom: "10%",
      containLabel: true,
    },
    tooltip: {
      showDelay: 10,
      borderColor: "#FFFFFF",
      color: "#333333",
      formatter: (params) => {
        if (params.value.length > 1) {
          return ` <div style="margin-bottom:10px color:#999999;"> ${params.name} </div> 
                    <div style="display:flex; color:#333333;  width:150px;justify-content:space-between; line-height: 26px; padding:0 5px" ><p>转化率</p>${params.value[0]}% </div>
                    <div style="display:flex; color:#333333; justify-content:space-between; line-height: 26px; padding:0 5px" ><p>消耗</p>${params.value[1]}元 </div>`;
        }
        return false;
      },
      textStyle: {
        fontSize: 12,
        //
      },
    },
    legend: [
      {
        orient: "horizontal",
        x: "83%",
        y: "7%",
        align: "left",
        data: ["二"],
        textStyle: {
          fontSize: 14,
        },
      },
      {
        orient: "horizontal",
        x: "94%",
        y: "7%",
        align: "left",
        data: ["一"],
        textStyle: {
          fontSize: 14,
          color: "#ffb400",
        },
      },
      {
        orient: "horizontal",
        x: "89%",
        y: "10%",
        align: "left",
        data: ["四"],
        textStyle: {
          fontSize: 14,
          color: "#ffb400",
        },
      },
      {
        orient: "horizontal",
        x: "94%",
        y: "10%",
        align: "left",
        data: ["三"],
        textStyle: {
          fontSize: 14,
          color: "#ffb400",
        },
      },
    ],
    xAxis: [
      {
        type: "value",
        //   name: '转化',
        scale: false,
        splitLine: {
          show: false,
        },
        alignTicks: false,
        axisLabel: {
          show: false,
        },
        axisTick: {
          show: false, //不显示坐标轴刻度线
        },
        axisLine: {
          show: false,
        },
      },
    ],
    yAxis: [
      {
        type: "value",
        scale: true,
        //   name: '消耗',
        alignTicks: false,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false, //不显示坐标轴刻度线
        },
        axisLabel: {
          show: false,
        },
        splitLine: {
          show: false,
        },
      },
    ],
    series: [
      {
        type: "scatter",
        radius: "60%",
        data: [
          {
            name: "新人礼",
            value: [6, 1163.35],
          },
          {
            name: "折扣",
            value: [5.93, 741.33],
          },
          {
            name: "送礼包",
            value: [4.58, 628.16],
          },
        ],

        label: {
          show: true,
          position: "bottom",
          formatter: "{b}",
        },
        symbolSize: function (val) {
          // var y = Math.sqrt(val[2] / 5e8) + 0.1;
          return 2 * val[0];
        },
        itemStyle: {
          shadowBlur: 0,

          // shadowColor: 'rgba(120, 36, 50, 0.8)',
          // shadowOffsetY: 4,

          color: function (e) {
            let colors = [
              "#415EF5",
              "#FF972E",
              "#25E1FE",
              "#FC44C9",
              "#00C1D4",
              "#FFC978",
              "#369AFE",
              "#8C54FF",
              "#F54141",
              "#BB41F5",
              "#F96658",
              "#415EF5",
              "#13CE9D",
            ];
            return `${colors[e.dataIndex]}`;
          },
        },
        // 各象限区域
        markArea: {
          silent: true,
          data: [
            // 第一象限
            [
              {
                name: "第一象限",
                xAxis: 40, // x 轴开始位置
                yAxis: 70, // y 轴结束位置(直接取最大值)
                itemStyle: {
                  color: "rgba(56, 180, 139, .1)",
                },
                label: {
                  position: "inside",
                  color: "rgba(0, 0, 0, .1)",
                  fontSize: 22,
                },
              },
              {
                yAxis: 40, // y轴开始位置
              },
            ],
            // 第二象限
            [
              {
                name: "第二象限",
                yAxis: 70, // y 轴结束位置(直接取最大值)
                itemStyle: {
                  color: "rgba(68, 97, 123, .1)",
                },
                label: {
                  position: "inside",
                  color: "rgba(0, 0, 0, .1)",
                  fontSize: 22,
                },
              },
              {
                xAxis: 40, // x 轴结束位置
                yAxis: 40, // y轴开始位置
              },
            ],
            // 第三象限
            [
              {
                name: "第三象限",
                yAxis: 40, // y 轴结束位置
                itemStyle: {
                  color: "rgba(191, 120, 58, .1)",
                },
                label: {
                  position: "inside",
                  color: "rgba(0, 0, 0, .1)",
                  fontSize: 22,
                },
              },
              {
                xAxis: 40, // x 轴结束位置
                yAxis: 10, // y轴开始位置
              },
            ],
            // 第四象限
            [
              {
                name: "第四象限",
                xAxis: 40, // x 轴开始位置
                yAxis: 40, // y 轴结束位置
                itemStyle: {
                  color: "rgba(116, 83, 153, .1)",
                },
                label: {
                  position: "inside",
                  color: "rgba(0, 0, 0, .1)",
                  fontSize: 22,
                },
              },
              {
                yAxis: 10, // y轴开始位置
              },
            ],
          ],
        },
        // 中心点交集象限轴
        markLine: {
          silent: true, // 是否不响应鼠标事件
          lineStyle: {
            type: "solid",
            color: "#E3E3E4",
          },
          label: {
            color: "#E3E3E4",
            position: "end",
            formatter: "{b}",
          },
          data: [
            {
              name: "平均消费",
              xAxis: 3,
            },
            {
              name: "转化率",
              yAxis: 900,
            },
          ],
        },
      },
    ],
  },
  //柱状图
  BarOption: {
    title: [
      {
        // 标题
        text: "占比",
        textStyle: {
          // 标题样式
          fontSize: 12,
          fontWeight: 400,
          color: "#666",
        },
        left: 46, // 位置
        top: 34, // 位置
      },
      {
        // 副标题
        text: "转化成本",
        textStyle: {
          // 标题样式
          fontSize: 12,
          fontWeight: 400,
          color: "#666",
        },
        left: 26, // 位置
        bottom: 56, // 位置
      },
      {
        // 副标题
        text: "平均消耗",
        textStyle: {
          // 标题样式
          fontSize: 12,
          fontWeight: 400,
          color: "#666",
        },
        left: 26, // 位置
        bottom: 36, // 位置
      },
    ],
    color: [
      {
        colorStops: [
          {
            color: "#6299FF",
            offset: 1,
          },
          {
            color: "#7A4CFF",
            offset: 0,
          },
        ],
        global: false,
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
      },
      {
        colorStops: [
          {
            color: "#42FFE2",
            offset: 1,
          },
          {
            color: "#00BCD1",
            offset: 0,
          },
        ],
        global: false,
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
      },
      {
        colorStops: [
          {
            color: "#48FFFF",
            offset: 1,
          },
          {
            color: "#0068FF",
            offset: 0,
          },
        ],
        global: false,
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
      },
      {
        colorStops: [
          {
            color: "#FF6B37",
            offset: 0,
          },
          {
            color: "#FF6B37",
            offset: 1,
          },
        ],
        global: false,
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
      },
      {
        colorStops: [
          {
            color: "#FC44C9",
            offset: 0,
          },
          {
            color: "#FC44C9",
            offset: 1,
          },
        ],
        global: false,
        type: "linear",
        x: 0,
        x2: 1,
        y: 0,
        y2: 0,
      },
    ],
    tooltip: {
      trigger: "axis",
      formatter: (params) => {
        let result =
          '<div style="color:#999999;margin-bottom:10px">' +
          params[0].name +
          "<div>";
        params.forEach(function (item) {
          if (item.seriesType === "line") {
            result +=
              '<div style="display:flex; color:#333; width:150px; justify-content:space-between; line-height: 26px; padding:0 5px" ><p>' +
              item.marker +
              " " +
              item.seriesName +
              " </p> " +
              item.value +
              "元</div>";
          } else {
            result +=
              '<div style="display:flex; color:#333; justify-content:space-between; line-height: 26px; padding:0 5px" ><p>' +
              item.marker +
              " " +
              item.seriesName +
              " </p> " +
              item.value +
              "%</div>";
          }
        });
        return result;
      },
      borderColor: "#FFFFFF",
      textStyle: {
        fontSize: 12,
        color: "#333333",
      },
      axisPointer: {
        type: "cross",
      },
    },
    grid: {
      left: 100,
      bottom: 80,
    },
    legend: {
      bottom: 0,
      itemWidth: 11,
      itemHeight: 4,
      textStyle: {
        color: "#999",
      },
      data: ["使用率", "点击率", "转化率", "转化成本", "平均消耗"],
    },
    yAxis: {
      type: "category",
      axisTick: {
        alignWithLabel: false,
      },
      alignTicks: false,
      axisLine: {
        show: false,
        lineStyle: {
          color: "#999999",
          textStyle: {
            align: "left",
            baseline: "middle",
          },
        },
      },
      data: [],
    },
    xAxis: [
      {
        type: "value",
        // name: '占比',
        position: "top",
        alignTicks: false,
        min: 0,
        max: 100,
        axisLine: {
          show: false,
        },
        axisTick: {
          show: false,
        },
        splitNumber: 5,
        axisLabel: {
          formatter: "{value} %",
        },
      },
      {
        type: "value",
        min: 0,
        // name: '转化成本',
        position: "bottom",
        splitNumber: 5,
        alignTicks: false,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: "{value} 元",
          interval: 5,
        },
      },
      {
        type: "value",
        min: 0,
        // name: '平均消耗',
        position: "bottom",
        nameLocation: "start",
        splitNumber: 5,
        nameGap: 20,
        offset: 20,
        alignTicks: false,
        axisLine: {
          show: false,
        },
        axisLabel: {
          formatter: "{value} 元",
          interval: 5,
        },
      },
    ],
    series: [
      {
        name: "使用率",
        type: "bar",
        barWidth: 12,
        data: [],
      },
      {
        name: "点击率",
        type: "bar",
        barWidth: 20,
        data: [],
      },
      {
        name: "转化率",
        type: "bar",
        barWidth: 20,
        xAxisIndex: 1,
        data: [],
      },
      {
        name: "转化成本",
        type: "shadow",
        xAxisIndex: 2,
        data: [],
      },
      {
        name: "平均消耗",
        type: "shadow",
        xAxisIndex: 2,
        data: [],
      },
    ],
  },

  relationOption: {
    tooltip: {
      borderColor: "#FFFFFF",
      formatter: function (x) {
        if (x.data.name) {
          if (x.data.des) {
            return ` <div style="color:#999999;margin-bottom:6px"> ${x.data.name} </div> 
                        <div style="display:flex; width:150px;justify-content:space-between; line-height: 26px; padding:0 5px" ><p>使用率</p>${x.data.des} </div>
                        <div style="display:flex; justify-content:space-between; line-height: 26px; padding:0 5px" ><p>平均消耗</p>${x.data.consume}元 </div>
                        <div style="display:flex; justify-content:space-between; line-height: 26px; padding:0 5px" ><p>点击率</p>${x.data.click} </div>
                        <div style="display:flex; justify-content:space-between; line-height: 26px; padding:0 5px" ><p>转化率</p>${x.data.covert} </div>
                        <div style="display:flex; justify-content:space-between; line-height: 26px; padding:0 5px" ><p>转化成本</p>${x.data.cost}元 </div>`;
          } else {
            return `${x.data.name}`;
          }
        }
      },
      textStyle: {
        fontSize: 12,
        color: "#333333",
      },
    },
    legend: [
      {
        icon: "circle",
        bottom: 20,
        textStyle: {
          color: "#999",
        },
        data: [
          {
            name: "平台电商",
          },
          {
            name: "视频版式",
          },
          {
            name: " 视频时长 ",
          },
          {
            name: " 角色人设 ",
          },
          {
            name: " 拍摄场景 ",
          },
          {
            name: " 音乐 ",
          },
          {
            name: " 转化引导 ",
          },
          {
            name: " 创意类型 ",
          },
          {
            name: " 前3s吸睛方式 ",
          },
          {
            name: " 表现形式 ",
          },
          {
            name: "核心卖点",
          },
        ],
      },
    ],
    color: [
      "#415EF5",
      "#FF972E",
      "#25E1FE",
      "#FC44C9",
      "#00C1D4",
      "#FFC978",
      "#369AFE",
      "#8C54FF",
      "#F54141",
      "#BB41F5",
      "#F96658",
      "#415EF5",
      "#13CE9D",
    ],
    series: [
      {
        type: "graph", // 类型:关系图
        layout: "force", //图的布局，类型为力导图
        symbolSize: 20, // 调整节点的大小
        force: {
          repulsion: 200,
        },
        focusNodeAdjacency: true, //聚焦邻接节点
        legendHoverLink: true, //启用图例 hover 时的联动高亮
        top: "top",
        bottom: 60,
        lineStyle: {
          normal: {
            symbol: "none",
            width: 1,
            color: "#040D1C",
            opacity: 0.1,
            curveness: 0,
          },
        },
        emphasis: {
          itemStyle: {
            shadowColor: " rgba(0, 0, 0, 0.4) ",
            shadowBlur: 4,
          },
          lineStyle: {
            width: 1,
          },
        },
        edgeLabel: {
          normal: {
            show: false,
            textStyle: {
              fontSize: 0,
            },
            formatter: function (x) {
              return "";
            },
          },
        },
        label: {
          normal: {
            show: true,
            offset: [0, 18],
            textStyle: {
              color: " #333333",
              fontSize: 12,
            },
          },
        },

        // 数据
        data: [
          {
            name: "平台电商",
            symbolSize: 24, //节点大小
            category: 0, //设置节点所属类别
          },
          {
            name: "视频版式",
            symbolSize: 16,
            category: 1,
          },
          {
            name: "视频时长",
            symbolSize: 16,
            category: 2,
          },
          {
            name: "角色人设",
            symbolSize: 16,
            category: 3,
          },
          {
            name: "拍摄场景",
            symbolSize: 16,
            category: 4,
          },
          {
            name: "音乐",
            symbolSize: 16,
            category: 5,
          },
          {
            name: "转化引导",
            symbolSize: 16,
            category: 6,
          },
          {
            name: "创意类型",
            symbolSize: 16,
            category: 7,
          },
          {
            name: "前3s吸睛方式",
            symbolSize: 16,
            category: 10,
          },
          {
            name: "表现形式",
            symbolSize: 16,
            category: 11,
          },
          {
            name: "核心卖点",
            symbolSize: 16,
            category: 12,
          },
          {
            symbolSize: 10,
            category: 1,
            name: "竖版",
            des: "100.00%",
            consume: 1236.61,
            click: "2.24%",
            covert: "7.45%",
            cost: 23.51,
          },
          {
            symbolSize: 10,
            category: 2,
            name: "30s-60s",
            des: "33.3%",
            consume: 1529.81,
            click: "1.98%",
            covert: "7.57%",
            cost: 22.27,
          },
          {
            symbolSize: 10,
            category: 2,
            name: "60s以上",
            des: "66.7%",
            consume: 743.59,
            click: "2.71%",
            covert: "4.60%",
            cost: 29.86,
          },
          {
            symbolSize: 10,
            category: 3,
            name: "素人",
            des: "36.7%",
            consume: 1074.41,
            click: "2.20%",
            covert: "5.43%",
            cost: 31.86,
          },
          {
            symbolSize: 10,
            category: 3,
            name: "达人",
            des: "36.7%",
            consume: 499.78,
            click: "3.19%",
            covert: "4.22%",
            cost: 23,
          },
          {
            symbolSize: 10,
            category: 3,
            name: "夫妻/情侣",
            des: "16.7%",
            consume: 992.25,
            click: "2.18%",
            covert: "5.76%",
            cost: 20.42,
          },
          {
            symbolSize: 10,
            category: 3,
            name: "美女/帅哥",
            des: "6.7%",
            consume: 2988.17,
            click: "1.34%",
            covert: "12.50%",
            cost: 25.65,
          },
          {
            symbolSize: 10,
            category: 3,
            name: "亲戚",
            des: "3.3%",
            consume: 1916.19,
            click: "3.48%",
            covert: "7.36%",
            cost: 19.36,
          },
          {
            symbolSize: 10,
            category: 4,
            name: "居家环境",
            des: "40.0%",
            consume: 631.27,
            click: "2.44%",
            covert: "5.10%",
            cost: 24.67,
          },
          {
            symbolSize: 10,
            category: 4,
            name: "办公室",
            des: "30.0%",
            consume: 1282.59,
            click: "2.41%",
            covert: "7.68%",
            cost: 23.18,
          },
          {
            symbolSize: 10,
            category: 4,
            name: "商场",
            des: "10.0%",
            consume: 2206.7,
            click: "0.60%",
            covert: "9.50%",
            cost: 47.29,
          },
          {
            symbolSize: 10,
            category: 4,
            name: "特殊场景",
            des: "10.0%",
            consume: 496.5,
            click: "7.27%",
            covert: "3.23%",
            cost: 18.16,
          },
          {
            symbolSize: 10,
            category: 4,
            name: "高档酒店",
            des: "3.3%",
            consume: 1916.19,
            click: "3.48%",
            covert: "7.36%",
            cost: 19.36,
          },
          {
            symbolSize: 10,
            category: 4,
            name: "户外/街边",
            des: "6.7%",
            consume: 512.81,
            click: "9.22%",
            covert: "2.87%",
            cost: 17.38,
          },
          {
            symbolSize: 10,
            category: 5,
            name: "热门歌单",
            des: "3.2%",
            consume: 6928.3,
            click: "1.47%",
            covert: "30.85%",
            cost: 17.63,
          },
          {
            symbolSize: 10,
            category: 5,
            name: "纯音乐",
            des: "96.8%",
            consume: 1005.66,
            click: "2.32%",
            covert: "5.95%",
            cost: 25.46,
          },
          {
            symbolSize: 10,
            category: 6,
            name: "创意动画引导",
            des: "5.4%",
            consume: 1038.42,
            click: "1.95%",
            covert: "7.01%",
            cost: 22.09,
          },
          {
            symbolSize: 10,
            category: 6,
            name: "常规引导",
            des: "46.4%",
            consume: 1092.79,
            click: "2.17%",
            covert: "6.07%",
            cost: 25.69,
          },
          {
            symbolSize: 10,
            category: 6,
            name: "品牌slogan引导",
            des: "48.2%",
            consume: 985.76,
            click: "2.65%",
            covert: "5.74%",
            cost: 25.28,
          },
          {
            name: "情景剧",
            symbolSize: 10,
            category: 7,
            des: "39.5%",
            consume: 1799.22,
            click: "1.95%",
            covert: "9.55",
            cost: 23.64,
          },
          {
            name: "真人口播",
            symbolSize: 10,
            category: 7,
            des: "30.2%",
            consume: 462.55,
            click: "3.25%",
            covert: "3.84%",
            cost: 22.52,
          },
          {
            name: "图文展示",
            symbolSize: 10,
            category: 7,
            des: "30.2%",
            consume: 1235.43,
            click: "2.13%",
            covert: "9.56%",
            cost: 23.97,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "提问开头",
            des: "25.6%",
            consume: 908.62,
            click: "1.99%",
            covert: "4.05%",
            cost: 35.08,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "反常猎奇",
            des: "20.5%",
            consume: 668.6,
            click: "2.42%",
            covert: "5.07%",
            cost: 34.29,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "冲突打压",
            des: "5.1%",
            consume: 2729.63,
            click: "1.07%",
            covert: "12.60%",
            cost: 21.75,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "写字字幕",
            des: "7.7%",
            consume: 717.73,
            click: "3.01%",
            covert: "6.32%",
            cost: 19.05,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "圈定用户",
            des: "15.4%",
            consume: 565.87,
            click: "3.44%",
            covert: "6.33%",
            cost: 21.22,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "激动情绪",
            des: "5.1%",
            consume: 847.63,
            click: "6.01%",
            covert: "4.08%",
            cost: 14.13,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "两性暗示",
            des: "5.1%",
            consume: 605.98,
            click: "3.64%",
            covert: "2.73%",
            cost: 25.25,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "满足自尊",
            des: "2.6%",
            consume: 1226.45,
            click: "1.03%",
            covert: "14.87%",
            cost: 42.29,
          },
          {
            symbolSize: 10,
            category: 11,
            name: "使用者安利",
            des: "33.3%",
            consume: 1580.16,
            click: "2.35%",
            covert: "6.40%",
            cost: 25.04,
          },
          {
            symbolSize: 10,
            category: 11,
            name: "熟人推荐",
            des: "23.3%",
            consume: 894.59,
            click: "2.63%",
            covert: "5.74%",
            cost: 30.25,
          },
          {
            symbolSize: 10,
            category: 11,
            name: "达人种草",
            des: "30.0%",
            consume: 484.91,
            click: "2.92%",
            covert: "4.42%",
            cost: 24.38,
          },
          {
            symbolSize: 10,
            category: 11,
            name: "对比解说",
            des: "6.7%",
            consume: 566.7,
            click: "4.20%",
            covert: "3.73%",
            cost: 18.89,
          },
          {
            symbolSize: 10,
            category: 11,
            name: "偶然发现",
            des: "3.3%",
            consume: 608.63,
            click: "0.85%",
            covert: "9.68%",
            cost: 22.54,
          },
          {
            symbolSize: 10,
            category: 11,
            name: "支付环节使用",
            des: "3.3%",
            consume: 2000,
            click: "0.56%",
            covert: "15.76%",
            cost: 24.69,
          },
          {
            symbolSize: 10,
            category: 10,
            name: "元素特写",
            des: "12.8%",
            consume: 1078.63,
            click: "3.81%",
            covert: "5.21%",
            cost: 18.86,
          },
          {
            symbolSize: 10,
            category: 12,
            name: "新人礼",
            des: "65.7%",
            consume: 1163.35,
            click: "2.49%",
            covert: "6.00%",
            cost: 24.91,
          },
          {
            symbolSize: 10,
            category: 12,
            name: "折扣",
            des: "17.1%",
            consume: 741.33,
            click: "2.10%",
            covert: "5.93%",
            cost: 23.29,
          },
          {
            symbolSize: 10,
            category: 12,
            name: "送礼包",
            des: "17.1%",
            consume: 628.16,
            click: "3.30%",
            covert: "4.58%",
            cost: 20.26,
          },
        ],
        links: [
          {
            source: "平台电商", //源节点
            target: "视频版式", //目标节点
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "视频时长",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "角色人设",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "拍摄场景",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "音乐",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "转化引导",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "创意类型",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "表现形式",
            label: {
              show: true,
            },
          },
          {
            source: "表现形式",
            target: "使用者安利",
            label: {
              show: true,
            },
          },
          {
            source: "表现形式",
            target: "熟人推荐",
            label: {
              show: true,
            },
          },
          {
            source: "表现形式",
            target: "达人种草",
            label: {
              show: true,
            },
          },
          {
            source: "表现形式",
            target: "对比解说",
            label: {
              show: true,
            },
          },
          {
            source: "表现形式",
            target: "偶然发现",
            label: {
              show: true,
            },
          },
          {
            source: "表现形式",
            target: "支付环节使用",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "前3s吸睛方式",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "表现形式",
            label: {
              show: true,
            },
          },
          {
            source: "平台电商",
            target: "",
            label: {
              show: true,
            },
          },
          {
            source: "视频版式",
            target: "竖版",
            label: {
              show: true,
            },
          },
          {
            source: "视频时长",
            target: "30s-60s",
            label: {
              show: true,
            },
          },
          {
            source: "视频时长",
            target: "60s以上",
            label: {
              show: true,
            },
          },
          {
            source: "音乐",
            target: "热门歌单",
            label: {
              show: true,
            },
          },
          {
            source: "音乐",
            target: "纯音乐",
            label: {
              show: true,
            },
          },
          {
            source: "转化引导",
            target: "品牌slogan引导",
            label: {
              show: true,
            },
          },
          {
            source: "转化引导",
            target: "常规引导",
            label: {
              show: true,
            },
          },
          {
            source: "转化引导",
            target: "创意动画引导",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "反常猎奇",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "提问开头",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "冲突打压",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "写字字幕",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "圈定用户",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "激动情绪",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "两性暗示",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "元素特写",
            label: {
              show: true,
            },
          },
          {
            source: "前3s吸睛方式",
            target: "满足自尊",
            label: {
              show: true,
            },
          },
          {
            source: "创意类型",
            target: "图文展示",
            label: {
              show: true,
            },
          },
          {
            source: "创意类型",
            target: "真人口播",
            label: {
              show: true,
            },
          },
          {
            source: "创意类型",
            target: "情景剧",
            label: {
              show: true,
            },
          },
          {
            source: "角色人设",
            target: "素人",
            label: {
              show: true,
            },
          },
          {
            source: "角色人设",
            target: "达人",
            label: {
              show: true,
            },
          },
          {
            source: "角色人设",
            target: "夫妻/情侣",
            label: {
              show: true,
            },
          },
          {
            source: "角色人设",
            target: "美女/帅哥",
            label: {
              show: true,
            },
          },
          {
            source: "角色人设",
            target: "亲戚",
            label: {
              show: true,
            },
          },
          {
            source: "核心卖点",
            target: "新人礼",
            label: {
              show: true,
            },
          },
          {
            source: "核心卖点",
            target: "折扣",
            label: {
              show: true,
            },
          },
          {
            source: "核心卖点",
            target: "送礼包",
            label: {
              show: true,
            },
          },
          {
            source: "拍摄场景",
            target: "居家环境",
            label: {
              show: true,
            },
          },
          {
            source: "拍摄场景",
            target: "办公室",
            label: {
              show: true,
            },
          },
          {
            source: "拍摄场景",
            target: "商场",
            label: {
              show: true,
            },
          },
          {
            source: "拍摄场景",
            target: "特殊场景",
            label: {
              show: true,
            },
          },
          {
            source: "拍摄场景",
            target: "高档酒店",
            label: {
              show: true,
            },
          },
          {
            source: "拍摄场景",
            target: "户外/街边",
            label: {
              show: true,
            },
          },
        ],
        categories: [
          {
            name: "平台电商",
          },
          {
            name: "视频版式",
          },
          {
            name: " 视频时长 ",
          },
          {
            name: " 角色人设 ",
          },
          {
            name: " 拍摄场景 ",
          },
          {
            name: " 音乐 ",
          },
          {
            name: " 转化引导 ",
          },
          {
            name: " 创意类型 ",
          },
          {
            name: " 视频剪辑 ",
          },
          {
            name: " 前3s吸睛方式 ",
          },
          {
            name: " 表现形式 ",
          },
          {
            name: "核心卖点",
          },
        ],
      },
    ],
  },
};

<template>
  <!-- 词云图 -->
  <div
    ref="echart"
    class="className"
    :style="{ height: height, width: width }"
  />
</template>

<script>
import * as echarts from "echarts";
// import resize from './mixins/resize'
import "echarts-wordcloud/dist/echarts-wordcloud";
import "echarts-wordcloud/dist/echarts-wordcloud.min";
const animationDuration = 1000;

export default {
  // mixins: [resize],
  name: "Wordcloud",
  props: {
    className: {
      type: String,
      default: "chart",
    },
    width: {
      type: String,
      default: "400px",
    },
    height: {
      type: String,
      default: "400px",
    },
    chartData: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chart: null,
    };
  },
  watch: {
    chartData: {
      deep: true,
      handler(val) {
        this.setOptions(val);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.initChart();
    });
  },
  beforeDestroy() {
    if (!this.chart) {
      return;
    }
    this.chart.dispose();
    this.chart = null;
  },
  methods: {
    initChart() {
      this.chart = echarts.init(this.$refs.echart);
      this.setOptions(this.chartData);
    },
    setOptions(data) {
      this.chart.setOption({
        grid: {
          width: "auto",
          height: "auto",
          top: "10px",
          left: "10px",
          right: "10px",
          bottom: "10px",
        },
        series: [
          {
            type: "wordCloud",
            //用来调整字的大小范围
            sizeRange: [15, 40],
            //用来调整词的旋转方向
            rotationRange: [0, 0],
            rotationStep: 60,
            //用来调整词之间的距离
            gridSize: 30,
            shape: "diamond",

            //随机生成字体颜色
            //echarts5.x 使用echarts-wordcloud2版本 textStyle 设置随机色时，不用加normal，否则会出不了颜色
            textStyle: {
              fontWeight: 500,
              color: function (v) {
                let colors = [
                  "#415EF5",
                  "#FF972E",
                  "#25E1FE",
                  "#FC44C9",
                  "#00C1D4",
                  "#FFC978",
                  "#369AFE",
                  "#8C54FF",
                  "#F54141",
                  "#BB41F5",
                  "#F96658",
                  "#415EF5",
                  "#13CE9D",
                ];
                return `${colors[v.dataIndex]}`;
              },
              emphasis: {
                shadowBlur: 10,
                shadowColor: "#333",
              },
            },
            data: this.chartData,
          },
        ],
      });
    },
  },
};
</script>

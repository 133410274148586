var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "creativityVideo-box" }, [
    _c("div", { staticClass: "creativityVideo-title" }, [
      _c("div", [_vm._v("创意视频（累计）")]),
      _c(
        "a",
        {
          staticClass: "creative-dz",
          attrs: {
            href: "https://promo.clipworks.com",
            role: "button",
            target: "_blank",
          },
        },
        [
          _vm._v(" 创意定制 "),
          _c("img", {
            staticClass: "creative-icon",
            attrs: {
              src: require("@/assets/images/icon/goto_icon.png"),
              width: "14",
              height: "14",
            },
          }),
        ]
      ),
    ]),
    _c("div", { staticClass: "creativityVideo-body" }, [
      _c("div", { staticClass: "search-box" }, [
        _c(
          "div",
          {
            staticClass: "search-item",
            class: { "search-item-hide": _vm.isIndustryOpen },
          },
          [
            _c("div", { staticClass: "search-label" }, [_vm._v("流量平台：")]),
            _c(
              "CheckboxGroup",
              {
                staticClass: "search-check-box",
                on: { "on-change": _vm.channelChange },
                model: {
                  value: _vm.channelIds,
                  callback: function ($$v) {
                    _vm.channelIds = $$v
                  },
                  expression: "channelIds",
                },
              },
              _vm._l(_vm.channelList, function (item) {
                return _c(
                  "Checkbox",
                  {
                    key: item.id,
                    attrs: {
                      label: item.id,
                      disabled: !_vm.videoList.length && _vm.isGuide,
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              1
            ),
            _c(
              "div",
              {
                staticClass: "search-open",
                on: {
                  click: function ($event) {
                    _vm.isIndustryOpen = !_vm.isIndustryOpen
                  },
                },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.isIndustryOpen ? "展开" : "收起") + " "
                ),
                _c("Icon", {
                  attrs: {
                    type: _vm.isIndustryOpen
                      ? "ios-arrow-down"
                      : "ios-arrow-up",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "search-item",
            class: { "search-item-hide": _vm.isMediaOpen },
          },
          [
            _c("div", { staticClass: "search-label" }, [_vm._v("广告媒体：")]),
            _c(
              "CheckboxGroup",
              {
                staticClass: "search-check-box",
                on: { "on-change": _vm.mediaChange },
                model: {
                  value: _vm.mediaIds,
                  callback: function ($$v) {
                    _vm.mediaIds = $$v
                  },
                  expression: "mediaIds",
                },
              },
              _vm._l(_vm.mediaList, function (item) {
                return _c(
                  "Checkbox",
                  {
                    key: item.id,
                    attrs: {
                      label: item.id,
                      disabled: !_vm.videoList.length && _vm.isGuide,
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              1
            ),
            _c(
              "div",
              {
                staticClass: "search-open",
                on: {
                  click: function ($event) {
                    _vm.isMediaOpen = !_vm.isMediaOpen
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(_vm.isMediaOpen ? "展开" : "收起") + " "),
                _c("Icon", {
                  attrs: {
                    type: _vm.isMediaOpen ? "ios-arrow-down" : "ios-arrow-up",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "search-item" },
          [
            _c("div", { staticClass: "search-label" }, [_vm._v("视频版式：")]),
            _c(
              "RadioGroup",
              {
                staticClass: "radio-check-box",
                on: { "on-change": _vm.videoLayoutChange },
                model: {
                  value: _vm.videoLayout,
                  callback: function ($$v) {
                    _vm.videoLayout = $$v
                  },
                  expression: "videoLayout",
                },
              },
              _vm._l(_vm.videoLayoutList, function (item) {
                return _c(
                  "Radio",
                  {
                    key: item.id,
                    attrs: {
                      label: item.id,
                      disabled: !_vm.videoList.length && _vm.isGuide,
                    },
                  },
                  [_vm._v(" " + _vm._s(item.name) + " ")]
                )
              }),
              1
            ),
            _c("div", { staticClass: "search-open" }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "video-box" },
        [
          _vm.loading ? _c("Spin", { attrs: { fix: "" } }) : _vm._e(),
          _c("div", { staticClass: "video-box-header" }, [
            _c(
              "div",
              { staticClass: "video-header-title" },
              [
                _vm.total > 0
                  ? [_vm._v(" 视频数量：" + _vm._s(_vm.total) + " ")]
                  : !_vm.isGuide
                  ? [_vm._v(" 视频数量：0 ")]
                  : [_vm._v(" 视频数量：1200 ")],
              ],
              2
            ),
            _c(
              "ul",
              { staticClass: "video-header-tool" },
              _vm._l(_vm.soltTypeList, function (item, index) {
                return _c(
                  "li",
                  {
                    key: "slot-" + index,
                    staticClass: "video-header-tool-item",
                    class: { "custom-disabled": !_vm.videoList.length },
                    on: {
                      click: function ($event) {
                        _vm.videoList.length
                          ? _vm.clickSoltItem(index)
                          : () => {}
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "video-header-tool-label",
                        class: { selected: item.isSelected },
                      },
                      [_vm._v(" " + _vm._s(item.label) + " ")]
                    ),
                    item.isSolt
                      ? _c("div", { staticClass: "video-header-tool-solt" }, [
                          _c("i", {
                            staticClass: "video-header-tool-ascending",
                            class: { ascending: item.soltType === "asc" },
                          }),
                          _c("i", {
                            staticClass: "video-header-tool-descending",
                            class: { descending: item.soltType === "desc" },
                          }),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
          ]),
          _c(
            "ul",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.videoList.length,
                  expression: "videoList.length",
                },
              ],
              staticClass: "video-list",
            },
            _vm._l(_vm.videoList, function (item, index) {
              return _c(
                "li",
                { key: item.id, staticClass: "video-list-item" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "viedeo-item-content",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.play(index)
                        },
                        mouseleave: function ($event) {
                          return _vm.pause(index)
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticClass: "viedeo-item-content-bg",
                        style: { "background-image": "url(" + item[4] + ")" },
                      }),
                      _c(
                        "span",
                        { staticClass: "viedeo-item-content-industry" },
                        [_vm._v(_vm._s(item[1]))]
                      ),
                      _c("img", {
                        staticClass: "viedeo-item-content-play",
                        attrs: {
                          src: require("@/assets/images/icon/icon_play.png"),
                          alt: "",
                        },
                      }),
                      _c("img", {
                        staticClass: "viedeo-item-content-poster",
                        attrs: { src: item[4], alt: "" },
                      }),
                      _c("video", {
                        ref: "video-" + index,
                        refInFor: true,
                        staticClass: "viedeo-item-content-video",
                        attrs: {
                          src: item[3],
                          controls: "",
                          controlslist: "nodownload",
                          muted: "true",
                        },
                        domProps: { muted: true },
                      }),
                      _c("img", {
                        staticClass: "viedeo-item-content-muted",
                        attrs: {
                          src: require(`@/assets/images/icon/icon_${
                            _vm.hasPlay ? "unmuted" : "muted"
                          }.png`),
                          alt: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.playVideo(index)
                          },
                        },
                      }),
                    ]
                  ),
                  _c("div", { staticClass: "viedeo-item-footer" }, [
                    _c(
                      "div",
                      { staticClass: "viedeo-item-footer-title-box" },
                      [
                        _c(
                          "Poptip",
                          {
                            attrs: {
                              trigger: "hover",
                              title: "",
                              content: item[0] || "--",
                            },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "viedeo-item-footer-title" },
                              [_vm._v(" " + _vm._s(item[0] || "--") + " ")]
                            ),
                          ]
                        ),
                        _c("div", [
                          _vm._v(_vm._s(_vm.timeFormat(item[9], "Y-m-d"))),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "viedeo-item-footer-exhibition-box" },
                      [
                        _c(
                          "div",
                          { staticClass: "viedeo-item-footer-exhibition-item" },
                          [
                            _c(
                              "Poptip",
                              {
                                attrs: {
                                  trigger: "hover",
                                  title: "",
                                  content: "点击数: " + _vm.numFormat(item[6]),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "viedeo-item-footer-exhibition",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/icon/icon_click.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.numberFormat(item[6]))),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "viedeo-item-footer-exhibition-item" },
                          [
                            _c(
                              "Poptip",
                              {
                                attrs: {
                                  trigger: "hover",
                                  title: "",
                                  content: "展示数: " + _vm.numFormat(item[7]),
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "viedeo-item-footer-exhibition",
                                  },
                                  [
                                    _c("img", {
                                      attrs: {
                                        src: require("@/assets/images/icon/icon_show.png"),
                                        alt: "",
                                      },
                                    }),
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.numberFormat(item[7]))),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c("div", { staticClass: "viedeo-item-footer-media-box" }, [
                      _c("span", [
                        _c("img", { attrs: { src: item[2], alt: "" } }),
                      ]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          ),
          !_vm.videoList.length && _vm.isGuide
            ? _c("div", { staticStyle: { "margin-bottom": "25px" } }, [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/videolist_default.jpg"),
                    width: "100%",
                  },
                }),
              ])
            : _vm._e(),
          _vm.videoList.length
            ? _c("Page", {
                staticClass: "video-page",
                attrs: {
                  "show-total": "",
                  "show-sizer": "",
                  "show-elevator": "",
                  "page-size-opts": [20, 40, 60, 80, 100],
                  placement: "top",
                  total: _vm.total,
                  current: _vm.pages.page,
                  "page-size": _vm.pages.size,
                },
                on: {
                  "on-change": _vm.pageChange,
                  "on-page-size-change": _vm.pageSizeChange,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="site-wrap detail-main">
    <Header :mode="2" detailType="品牌" :searchKey="searchKey"></Header>
    <MenuSide
      :activeMenu="activeMenu"
      @isOpen="isMenuOpen"
      @onselect="menuSelect"
    ></MenuSide>
    <div
      class="main-scroll"
      ref="scroll"
      :class="menuOpen ? '' : 'main-scroll-change'"
    >
      <div class="map-size" v-if="cardType !== 33">
        <MapPanelLittle
          :markers="mapMarkers"
          v-if="mapMarkers.length != 0"
        ></MapPanelLittle>
      </div>
      <div class="main-size" :class="{ mt0: cardType === 33 }">
        <!--isVip：是否为VIP身份；collectShow：是否显示收藏按钮；claimShow：是否显示认领按钮；shareShow：是否显示分享按钮；-->
        <SummaryCard
          v-if="cardType !== 33"
          :isShowTips="!!componentName"
          :isShowDate="!!componentName && !isGuide()"
          :cardType="cardType"
          :cardInfo="detailInfo"
          :statistics="statistics"
          :isVip="
            userInfo &&
            userInfo.userMember &&
            (userInfo.userMember.key === 'vip' ||
              userInfo.userMember.key === 'svip')
              ? true
              : false
          "
          :isSVip="
            userInfo &&
            userInfo.userMember &&
            userInfo.userMember.key === 'svip'
              ? true
              : false
          "
          :collectShow="true"
          :claimShow="true"
          :shareShow="true"
          :detailID="brandID"
          :isShowGsxx="brandBusinessList.length"
          @changeDaterange="changeDaterange"
          @onselect="menuSelect"
        ></SummaryCard>
        <!-- 菜单动态路由部分 -->
        <!-- <component
          :is="componentName"
          v-if="componentName"
          :brandId="brandID"
          :putData="putData"
          :exposureData="exposureData"
          :clickData="clickData"
          :daterangeData="daterangeData"
          :filterDate="daterangeData"
        ></component> -->
        <section class="guide-section" v-if="isGuide() && cardType == 2">
          <div>
            <img
              :src="require('@/assets/images/guide_section.png')"
              width="644"
            />
          </div>
          <Button class="zx-btn" @click="goToConsult">去咨询</Button>
          <div>
            <img
              :src="require('@/assets/images/demo_pointer.png')"
              width="464"
            />
          </div>
        </section>
        <!-- 菜单锚点部分 -->
        <template v-if="cardType == 2">
          <!-- 创意投放 -->
          <div class="content-item" id="CreativityPut">
            <div class="item-title">创意投放</div>
            <CreativityPut
              :brandId="brandID"
              :putData="putData"
              :componentName="componentName"
              :exposureData="exposureData"
              :clickData="clickData"
              :daterangeData="daterangeData"
              :filterDate="daterangeData"
            >
            </CreativityPut>
          </div>
          <!-- 创意视频 -->
          <CreativityVideo
            id="CreativityVideo"
            :brandId="brandID"
            :putData="putData"
            :isGuide="isGuide()"
            :componentName="componentName"
            :exposureData="exposureData"
            :clickData="clickData"
            :daterangeData="daterangeData"
            :filterDate="daterangeData"
          ></CreativityVideo>
          <!-- 创意归因 -->
          <div class="content-item" id="CreativityAscribe">
            <div class="item-title">
              创意归因<img
                :src="require('@/assets/images/title_icon.png')"
                width="29"
                height="12"
              />
            </div>
            <CreativityAscribe
              :brandId="brandID"
              :putData="putData"
              :componentName="componentName"
              :exposureData="exposureData"
              :clickData="clickData"
              :daterangeData="daterangeData"
              :filterDate="daterangeData"
            ></CreativityAscribe>
          </div>
        </template>
        <template v-else-if="cardType === 33">
          <div class="content-item" ref="offlineAnalysis" id="offlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <offline-analysis></offline-analysis>
          </div>
          <div class="content-item anchor-box9" id="onlineAnalysis">
            <!--demo图片，此div用于锚点定位-->
            <online-analysis></online-analysis>
          </div>
        </template>
        <template v-else>
          <!--门店分布-->
          <div class="content-item" id="mendian">
            <div class="item-title">门店分布</div>
            <div class="chart-box1">
              <div class="chart-title">各线城市门店分布占比</div>
              <div class="chart-size">
                <RoseChart :echartData="echartsData1"></RoseChart>
              </div>
            </div>
            <div class="chart-box2">
              <div class="chart-title">主要城市门店分布Top榜</div>
              <div class="chart-size">
                <BarChart
                  :echartData="echartsData2"
                  :barWidth="28"
                  :barColor="barColorCon2"
                  :textColor="textColorCon2"
                  :yUnit="'家'"
                  :echartSize="echartSizeCon2"
                  :maxLength="6"
                  :isHideY="false"
                  :isHideYline="false"
                  :isTopData="true"
                >
                </BarChart>
              </div>
            </div>
            <div class="chart-box3" v-loading="mendianLoadingStatus">
              <div class="chart-title chart-title2">
                品牌门店列表
                <div class="chart-screen">
                  {{ cityName }}在营门店<span class="screen-num">{{
                    total
                  }}</span
                  >家
                  <!-- <Select
                    v-model="cityScreen"
                    filterable
                    placeholder="全国"
                    style="width: 110px"
                    @on-change="cityScreenChange"
                  >
                    <Option
                      v-for="(item, index) in cityList"
                      :value="item"
                      :key="'city_' + index"
                    >
                      {{ item }}</Option
                    >
                  </Select> -->
                  <Cascader
                    :disabled="!isLogin()"
                    :data="cityList"
                    filterable
                    placeholder="全国"
                    clearable
                    @on-change="cityScreenChange"
                    style="width: 160px"
                  ></Cascader>
                </div>
              </div>
              <div class="table-size" ref="tablebox">
                <Table
                  class="task-status-content"
                  :columns="columns"
                  :data="listData"
                  :height="400"
                >
                  <template slot-scope="{ index }" slot="serialNumber">
                    {{ (pageNum - 1) * pageSize + index + 1 }}
                  </template>
                  <template slot-scope="{ row }" slot="operation">
                    <span class="operation-btn" @click="viewDetail(row)"
                      >查看详情</span
                    >
                  </template>
                </Table>
              </div>
              <!--分页-->
              <template v-if="isLogin()">
                <div class="page-size" v-if="listData.length != 0">
                  <Page
                    prev-text=""
                    next-text=""
                    class-name="dt-pager"
                    :show-total="true"
                    :total="total"
                    :current="pageNum"
                    :page-size="pageSize"
                    show-sizer
                    @on-change="changePage"
                    @on-page-size-change="changePageSize"
                  >
                    共{{ total }}条
                  </Page>
                </div>
              </template>
              <template v-else>
                <div class="visitor-login g-brand-detail-login">
                  <div
                    role="button"
                    class="visitor-login-btn"
                    @click="gotoLogin"
                  >
                    登录查看全部信息<img
                      class="visitor-login-icon"
                      :src="require('@/assets/images/double-arror.png')"
                      width="14"
                      height="14"
                    />
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!--聚客分析-->
          <div class="content-item" id="juke">
            <div class="item-title">
              周边客群
              <Poptip trigger="hover" placement="right" padding="0 0">
                <Icon type="ios-help-circle-outline" class="help_icon" />
                <div class="m-tips" slot="content">
                  <p><span>门店所在区域周边500米的客群分析</span></p>
                </div>
              </Poptip>
              <!-- <img
                class="g-vip-icon"
                :src="require('@/assets/images/vip/g-vip.png')"
                width="21"
                height="10"
              /> -->

              <Icon type="ios-lock-outline" class="g-vip-icon" />
            </div>
            <section class="g-vip-section">
              <div role="button" class="g-vip-btn" @click="toVip">去升级</div>
              <div>
                <img
                  :src="require('@/assets/images/demo_pointer.png')"
                  width="464"
                />
              </div>
              <div class="diliver"></div>
            </section>
            <CircumCustomer
              :id="brandID"
              identify="品牌"
              @getTotal="getTotal"
            ></CircumCustomer>
            <div class="chart-box4">
              <div class="chart-title">
                聚客类型
                <Poptip trigger="hover" placement="right" padding="0 0">
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                  <div class="m-tips" slot="content">
                    <p><span>门店所在区域周边500米的聚客点主力类型</span></p>
                  </div>
                </Poptip>
              </div>
              <div class="chart-size">
                <BarChart
                  :echartData="echartsData3"
                  :barWidth="28"
                  :barColor="barColorCon2"
                  :textColor="textColorCon2"
                  :yUnit="'%'"
                  :echartSize="echartSizeCon3"
                  :maxLength="8"
                  :isHideY="true"
                  :isHideYline="true"
                >
                </BarChart>
              </div>
            </div>
          </div>
          <!-- 门店到访 -->
          <div class="demo-item" id="mddf">
            <Icon type="ios-lock-outline" class="g-vip-icon2" />
            <!-- <img
              src="../../assets/images/demo/detail_mddf.png"
              alt="门店到访"
              width="1128"
              height="3198"
            /> -->
            <img
              src="../../assets/images/demo/detail_mddf2.png"
              alt="门店到访"
              width="1128"
              height="3198"
            />
            <div class="demo-btn" @click="vipClick"></div>
          </div>
          <!--客户画像-->
          <div class="demo-item" id="kehu">
              <Icon type="ios-lock-outline" class="g-vip-icon3" />
            <!-- <img
              src="../../assets/images/demo/detail_khhx.png"
              alt="客户画像"
              width="1128"
              height="3479"
            /> -->
            <img
              src="../../assets/images/demo/detail_khhx2.png"
              alt="客户画像"
              width="1128"
              height="3479"
            />
            <div class="demo-btn" @click="vipClick"></div>
          </div>
          <!--门店销量-->
          <div class="demo-item" id="mdxl">
              <Icon type="ios-lock-outline" class="g-vip-icon2" />
            <!-- <img
              src="../../assets/images/demo/detail_mdxl.png"
              alt="门店销量"
              width="1128"
              height="2449"
            /> -->
            <img
              src="../../assets/images/demo/detail_mdxl2.png"
              alt="门店销量"
              width="1128"
              height="2449"
            />
            <div class="demo-btn" @click="vipClick"></div>
          </div>
        </template>
        <template v-if="cardType !== 33">
          <!--品牌企业工商信息-->
          <div class="content-item" id="gsxx" v-if="brandBusinessList.length">
            <div class="item-title">
              <img
                v-if="
                  brandBusinessList.length &&
                  brandBusinessList[brandBusinessList.length - 1]
                "
                :src="brandBusinessList[brandBusinessList.length - 1]"
                alt=""
                width="34"
                height="34"
              />
              品牌企业工商信息
            </div>
            <div class="brand-business">
              <p
                v-for="(item, index) of brandBusiness"
                :class="{
                  with100:
                    item === '注册地址' ||
                    item === '经营范围' ||
                    item === '数据来源'
                }"
                :key="item"
              >
                <template v-if="index === brandBusiness.length - 1">
                  <div class="data-source">
                    <span>数据来源：</span>
                    <img
                      width="60"
                      height="20"
                      src="../../assets/images/aiqicha-logo.png"
                      alt=""
                    />
                    <template v-if="brandBusinessList[index]">
                      <span class="line"></span>
                      <a target="_blank" :href="brandBusinessList[index]"
                        >查看更多 <Icon custom="icon-more"
                      /></a>
                    </template>
                  </div>
                </template>
                <template v-else>
                  <span>{{ item }}：</span>
                  <!--                  <template v-if="item === '公司主页'">-->
                  <!--                    <a target="_blank">{{brandBusinessList[index]}}</a>-->
                  <!--                  </template>-->
                  <template v-if="item === '营业期限'">
                    <template v-if="!brandBusinessList[index]"
                      >无固定期限</template
                    >
                    <template v-else>
                      {{ brandBusinessList[index] }} ~
                      <template
                        v-if="!brandBusinessList[brandBusinessList.length - 2]"
                      >
                        无固定期限
                      </template>
                      <template v-else>
                        {{ brandBusinessList[brandBusinessList.length - 2] }}
                      </template>
                    </template>
                  </template>
                  <template v-else>
                    <div class="desc">
                      {{ brandBusinessList[index] || "-" }}
                    </div>
                  </template>
                </template>
              </p>
            </div>
          </div>
          <!--相似推荐-->
          <div class="content-item">
            <div class="item-title">相似推荐</div>
            <div class="similar-size">
              <Similar :listData="similarData"></Similar>
            </div>
          </div>
        </template>
        <div class="page-bottom"></div>
        <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
      </div>
    </div>
  </div>
</template>

<script>
import ModalUpgrade from "@/components/ModalUpgrade.vue";
import SummaryCard from "../../components/SummaryCard.vue";
import MenuSide from "../../components/MenuSide.vue";
import { GetCommonInfo, GetDetailBrowsingRecord } from "@/service";
import RoseChart from "../../components/chart/roseChart.vue";
import BarChart from "../../components/chart/barChart.vue";
import Similar from "../../components/Similar.vue";
import CreativityPut from "./onLine/CreativityPut.vue";
import CreativityVideo from "./onLine/CreativityVideo.vue";
import CreativityAscribe from "./onLine/CreativityAscribe.vue";
import MapPanelLittle from "@/components/MapPanelLittle.vue";
import { formatFieldsList, login, isLogin } from "js/utils.js";
import { openNewWindow } from "@/assets/javascript/utils.js";
import axios from "axios";
import CircumCustomer from "@/components/cards/CircumCustomer.vue";
import offlineAnalysis from "../../components/depthAnalysis/offline";
import onlineAnalysis from "../../components/depthAnalysis/online";
import { mapGetters } from "vuex";
export default {
  metaInfo() {
    let list = ["查品牌"];
    if (!this.brandName) {
      return null;
    }
    list.unshift(this.brandName);
    return {
      title: list.join(" - "),
      meta: [
        {
          name: "keywords",
          content: `千里目,${this.detailInfo[0]},品牌数据,门店数据,全国门店分布,对比分析,周边客群画像,门店到访客流,门店流失客流,实时客流,客流统计,客流画像,客流停留时长,门店销量,热销商品,周边聚客类型,竞品信息,实时数据,线上投放广告,投放视频,投放媒体,曝光趋势,点击趋势,创意归因分析`
        },
        {
          name: "description",
          content: `${this.detailInfo[0]}全域商业数据分析，支持查看${this.detailInfo[0]}的全国门店分布和单门店详情、周边客群画像、门店到访和流失客流实时趋势、客流画像和停留时长、门店销量和排行、热销商品、周边聚客类型、竞品信息等；同时支持查看线上投放广告数据，包含投放视频、投放媒体、广告曝光趋势、广告点击趋势、广告创意归因分析等详细数据。`
        }
      ]
    };
  },
  data() {
    return {
      brandBusiness: [
        "企业全称",
        "成立日期",
        "注册资本",
        "企业法人",
        "所属行业",
        "经营状态",
        "企业类型",
        "营业期限",
        "公司主页",
        "工商注册号",
        "组织机构代码",
        "纳税人识别号",
        "核准日期",
        "登记机关",
        "行政区划",
        "注册地址",
        "经营范围",
        "数据来源"
      ],
      // brandBusiness: ['企业全称', '成立日期', '注册资本', '企业法人', '所属行业', '经营状态', '企业类型', '公司主页', '工商注册号', '经营范围', '数据来源'],

      brandBusinessList: [],
      menuOpen: true,
      brandID: "", //测试ID：742df225-df46-49e2-b88e-39f427fcf4ac
      detailInfo: "",
      brandName: "",
      cardType: 1,
      echartsData1: [
        { value: 0, name: "新一线城市", itemStyle: { color: "#FF6161" } },
        { value: 0, name: "其他城市", itemStyle: { color: "#FBC866" } },
        { value: 0, name: "五线城市", itemStyle: { color: "#F89C00" } },
        { value: 0, name: "四线城市", itemStyle: { color: "#897CFD" } },
        { value: 0, name: "三线城市", itemStyle: { color: "#2DBAE9" } },
        { value: 0, name: "二线城市", itemStyle: { color: "#00BB87" } },
        { value: 0, name: "一线城市", itemStyle: { color: "#3519FB" } }
      ],

      echartsData2: [
        { name: "", value: "", percent: "" },
        { name: "", value: "", percent: "" },
        { name: "", value: "", percent: "" },
        { name: "", value: "", percent: "" },
        { name: "", value: "", percent: "" }
      ],
      barColorCon2: "#3519FB",
      textColorCon2: "#848295",
      echartSizeCon2: { x: 30, x2: 30, y: 30, y2: 30 },

      echartsData3: [
        { name: "", value: "" },
        { name: "", value: "" },
        { name: "", value: "" },
        { name: "", value: "" },
        { name: "", value: "" },
        { name: "", value: "" }
      ],
      echartSizeCon3: { x: 60, x2: 30, y: 20, y2: 30 },

      columns: [
        {
          type: "index",
          align: "center",
          title: " ",
          width: 70
        },
        {
          title: "门店名称",
          key: "name",
          align: "left",
          width: 280
        },
        {
          title: "门店地址",
          key: "address",
          align: "left",
          minWidth: 380
        },
        {
          title: "操作",
          key: "operation",
          width: 200,
          align: "center",
          slot: "operation"
        }
      ],

      pageNum: 1,
      pageSize: 10,
      total: 0,
      listData: [],

      cityList: [],
      cityName: "全国",

      similarData: [],
      activeMenu: "",
      componentName: "",

      mapMarkers: [],
      // 线下营销选择时间范围值
      daterangeData: [],
      statistics: {
        putNum: 0,
        exposureNum: 0,
        clickNum: 0
      },
      // 线下营销-创意投放-创意投放趋势图表数据
      putData: [],
      // 线下营销-创意投放-创意曝光趋势图表数据
      exposureData: [],
      // 线下营销-创意投放-创意点击趋势图表数据
      clickData: [],
      mendianLoadingStatus: true,
      searchKey: "",
      isShow: false
    };
  },
  watch: {
    $route() {
      if (this.$route.query.id) {
        location.reload();
      }
    }
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.pageSize);
    },
    scrollBox() {
      return this.$refs.scroll;
    },
    ...mapGetters({
      userInfo: "getUserInfo",
      token: "getToken"
    })
  },
  methods: {
    isMenuOpen(isOpen) {
      this.menuOpen = isOpen;
    },
    isGuide() {
      if (
        localStorage.getItem("cyspDemoData") == "true" &&
        localStorage.getItem("cytfDemoData") == "true"
      ) {
        return true;
      } else {
        return false;
      }
    },
    isLogin,
    gotoLogin: login,
    toVip() {
      this.isShow = true;
      // window.open("/vip", "_blank");
    },
    async menuSelect(name, isComponent) {
      this.originCardType = this.cardType;
      if (
        ["CreativityPut", "CreativityVideo", "CreativityAscribe"].includes(name)
      ) {
        this.componentName = name;
        this.cardType = 2;
      } else if (["offlineAnalysis", "onlineAnalysis"].includes(name)) {
        this.componentName = name;
        this.cardType = 33;
      } else {
        this.cardType = 1;
        this.componentName = "";
      }
      if (this.originCardType != this.cardType) {
        setTimeout(() => {
          document.getElementById(name) &&
            document
              .getElementById(name)
              .scrollIntoView({ behavior: "smooth" });
        }, 350);
      } else {
        document.getElementById(name) &&
          document.getElementById(name).scrollIntoView({ behavior: "smooth" });
      }
    },
    goToConsult() {
      const el = document.getElementById("nb_icon_wrap");
      el.click();
    },
    //获取详情信息（汇总卡片信息）
    async getDetail() {
      let params = {
        identify: "sg-data-品牌-品牌详情信息",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID]
          }
        ],
        fields: [
          "品牌名称",
          "面积要求㎡",
          "品牌年龄",
          "人均消费",
          "品牌图片地址",
          "公司名称",
          "业态分类",
          "经营模式",
          "成立时间",
          "标签",
          "覆盖省数量",
          "覆盖市数量",
          "门店数量",
          "已入驻购物中心数量",
          "更新时间",
          "更新月份",
          "业态大类编号",
          "经营模式代号",
          "品牌别名",
          "版本号"
        ]
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        this.detailInfo = res.data.items[0] || "";
        this.brandName = this.detailInfo && this.detailInfo[0];
      }
    },

    // 获取总人口数和总家庭户数
    getTotal(data) {
      if (this.detailInfo) {
        this.detailInfo.push(data.totalNum);
        this.detailInfo.push(data.totalFamily);
      }
    },
    //门店分布--饼图、柱状图
    async getCityBrand() {
      let params = {
        identify: "sg-data-品牌-城市和城市线级前五及占比",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID]
          }
        ],
        fields: [
          "品牌名称",
          "更新时间",
          "更新月份",
          "门店数量第一城市名称",
          "门店数量第一城市数量",
          "门店数量第二城市名称",
          "门店数量第二城市数量",
          "门店数量第三城市名称",
          "门店数量第三城市数量",
          "门店数量第四城市名称",
          "门店数量第四城市数量",
          "门店数量第五城市名称",
          "门店数量第五城市数量",
          "品牌门店全国数量",
          "门店数量第一城市数量占比",
          "门店数量第二城市数量占比",
          "门店数量第三城市数量占比",
          "门店数量第四城市数量占比",
          "门店数量第五城市数量占比",
          "门店数量除前五外城市数量占比",
          "品牌门店一线城市数量",
          "品牌门店新一线城市数量",
          "品牌门店二线城市数量",
          "品牌门店三线城市数量",
          "品牌门店四线城市数量",
          "品牌门店五线城市数量",
          "品牌门店其他城市数量",
          "品牌门店一线城市占比",
          "品牌门店新一线城市占比",
          "品牌门店二线城市占比",
          "品牌门店三线城市占比",
          "品牌门店四线城市占比",
          "品牌门店五线城市占比",
          "品牌门店其他城市占比",
          "版本号"
        ]
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        this.echartsData1[0].value = res.data.items[0][21] || 0; //新一线城市（数量）
        this.echartsData1[1].value = res.data.items[0][26] || 0; //其他城市（数量）
        this.echartsData1[2].value = res.data.items[0][25] || 0; //五线城市（数量）
        this.echartsData1[3].value = res.data.items[0][24] || 0; //四线城市（数量）
        this.echartsData1[4].value = res.data.items[0][23] || 0; //三线城市（数量）
        this.echartsData1[5].value = res.data.items[0][22] || 0; //二线城市（数量）
        this.echartsData1[6].value = res.data.items[0][20] || 0; //一线城市（数量）
        this.echartsData1[0].name =
          "新一线城市 " +
            Number(res.data.items[0][28] * 100).toFixed(1) +
            "%" || 0; //新一线城市（占比）
        this.echartsData1[1].name =
          "其他城市 " + Number(res.data.items[0][33] * 100).toFixed(1) + "%" ||
          0; //其他城市（占比）
        this.echartsData1[2].name =
          "五线城市 " + Number(res.data.items[0][32] * 100).toFixed(1) + "%" ||
          0; //五线城市（占比）
        this.echartsData1[3].name =
          "四线城市 " + Number(res.data.items[0][31] * 100).toFixed(1) + "%" ||
          0; //四线城市（占比）
        this.echartsData1[4].name =
          "三线城市 " + Number(res.data.items[0][30] * 100).toFixed(1) + "%" ||
          0; //三线城市（占比）
        this.echartsData1[5].name =
          "二线城市 " + Number(res.data.items[0][29] * 100).toFixed(1) + "%" ||
          0; //二线城市（占比）
        this.echartsData1[6].name =
          "一线城市 " + Number(res.data.items[0][27] * 100).toFixed(1) + "%" ||
          0; //一线城市（占比）

        this.echartsData2[0].name = res.data.items[0][3] || "";
        this.echartsData2[1].name = res.data.items[0][5] || "";
        this.echartsData2[2].name = res.data.items[0][7] || "";
        this.echartsData2[3].name = res.data.items[0][9] || "";
        this.echartsData2[4].name = res.data.items[0][11] || "";
        this.echartsData2[0].value = res.data.items[0][4] || 0;
        this.echartsData2[1].value = res.data.items[0][6] || 0;
        this.echartsData2[2].value = res.data.items[0][8] || 0;
        this.echartsData2[3].value = res.data.items[0][10] || 0;
        this.echartsData2[4].value = res.data.items[0][12] || 0;
        this.echartsData2[0].percent =
          Number(res.data.items[0][14] * 100).toFixed(1) + "%" || 0;
        this.echartsData2[1].percent =
          Number(res.data.items[0][15] * 100).toFixed(1) + "%" || 0;
        this.echartsData2[2].percent =
          Number(res.data.items[0][16] * 100).toFixed(1) + "%" || 0;
        this.echartsData2[3].percent =
          Number(res.data.items[0][17] * 100).toFixed(1) + "%" || 0;
        this.echartsData2[4].percent =
          Number(res.data.items[0][18] * 100).toFixed(1) + "%" || 0;

        //筛选掉空数据和零数据
        for (let i = 0; i < this.echartsData2.length; i++) {
          if (Number(this.echartsData2[i].value) === 0) {
            this.echartsData2.splice(i, 1);
            i--;
          }
        }

        for (let i in this.echartsData2) {
          if (String(this.echartsData2[i].name).length > 5) {
            this.echartsData2[i].name =
              String(this.echartsData2[i].name).substring(0, 5) +
              "\n" +
              String(this.echartsData2[i].name).substring(5);
          }
        }

        this.echartsData1 = JSON.parse(JSON.stringify(this.echartsData1));
        this.echartsData2 = JSON.parse(JSON.stringify(this.echartsData2));
      }
    },

    //门店分布--品牌门店列表
    async getBrandList() {
      this.mendianLoadingStatus = true;
      let features = [];
      let screenCity = [];

      if (this.cityName != "全国") {
        screenCity.push(this.cityName);
      }

      let params = {
        identify: "sg-data-品牌-门店详情",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID]
          },
          {
            field: "城市名称",
            cond: 0,
            value: screenCity
          }
        ],
        fields: [
          "品牌名称",
          "门店名称",
          "门店地址",
          "城市",
          "lon",
          "lat",
          "品牌分类",
          "图片url",
          "更新时间",
          "版本号",
          "geom",
          "省名称",
          "省编码",
          "城市名称",
          "市编码",
          "区县名称",
          "区县编码",
          "门店id"
        ],
        pages: {
          page: this.pageNum,
          size: this.pageSize
        }
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        this.listData = [];
        let list = formatFieldsList(res.data);
        for (let i in list) {
          this.listData.push({
            id: list[i]["门店id"] || "",
            name: list[i]["门店名称"] || "",
            address: list[i]["门店地址"] || ""
          });
        }
        features = list.map((item) => {
          let feature = {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [item.lon, item.lat]
            }
          };
          return feature;
        });
        this.total = res.data.pages.total || 0;
      }
      this.mendianLoadingStatus = false;
      this.mapMarkers = features;
    },

    //获取全部城市数据（品牌门店列表城市筛选）
    async getCitys() {
      axios.get("/province.json").then((res) => {
        for (let i in res) {
          if (
            String(res[i].name) === "北京市" ||
            String(res[i].name) === "天津市" ||
            String(res[i].name) === "上海市" ||
            String(res[i].name) === "重庆市" ||
            String(res[i].name) === "香港特别行政区" ||
            String(res[i].name) === "澳门特别行政区"
          ) {
            this.cityList.push({
              value: res[i].name,
              label: res[i].name
            });
          } else {
            this.cityList.push({
              value: res[i].name,
              label: res[i].name,
              children: []
            });
            for (let j in res[i].city) {
              this.cityList[i].children.push({
                value: res[i].city[j].name,
                label: res[i].city[j].name
              });
            }
          }
        }
      });
      // let params = {
      //   identify: "sg-data-城市-城市详情信息",
      //   fields: ["城市名称"],
      // };
      // let res = await GetCommonInfo(params);
      // if (res && res.code === 200) {
      //   for (let i in res.data.items) {
      //     this.cityList.push(res.data.items[i][0]);
      //   }
      // }
    },

    //品牌门店列表--查看详情按钮
    async viewDetail(row) {
      const res = await GetDetailBrowsingRecord({
        module: "storeDetail",
        detailID: row.id
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/storeDetail", {
          id: row.id,
          brandID: this.brandID,
          brandName: this.detailInfo[0]
        });
      }
    },

    //品牌门店列表--页码按钮回调
    changePage(num) {
      this.pageNum = num;
      this.getBrandList();
    },
    changePageSize(num) {
      this.pageNum = 1;
      this.pageSize = num;
      this.getBrandList();
    },

    //品牌门店列表--筛选
    cityScreenChange(value) {
      if (value && value[0] && value.length === 1) {
        this.cityName = value[0];
      } else if (value && value[1] && value.length === 2) {
        this.cityName = value[1];
      } else {
        this.cityName = "全国";
      }
      this.getBrandList();
    },

    //聚客分析
    async getAnalysis() {
      let params = {
        identify: "sg-data-品牌-周边分析",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID]
          }
        ],
        fields: [
          "品牌名称",
          "标签1",
          "标签2",
          "标签3",
          "标签4",
          "标签5",
          "标签6",
          "标签1数量",
          "标签2数量",
          "标签3数量",
          "标签4数量",
          "标签5数量",
          "标签6数量",
          "标签1比率",
          "标签2比率",
          "标签3比率",
          "标签4比率",
          "标签5比率",
          "标签6比率",
          "更新时间",
          "版本号"
        ]
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200 && res.data.items) {
        this.echartsData3[0].name = res.data.items[0][1] || "";
        this.echartsData3[1].name = res.data.items[0][2] || "";
        this.echartsData3[2].name = res.data.items[0][3] || "";
        this.echartsData3[3].name = res.data.items[0][4] || "";
        this.echartsData3[4].name = res.data.items[0][5] || "";
        this.echartsData3[5].name = res.data.items[0][6] || "";
        this.echartsData3[0].value =
          Number(res.data.items[0][13] * 100).toFixed(1) || "";
        this.echartsData3[1].value =
          Number(res.data.items[0][14] * 100).toFixed(1) || "";
        this.echartsData3[2].value =
          Number(res.data.items[0][15] * 100).toFixed(1) || "";
        this.echartsData3[3].value =
          Number(res.data.items[0][16] * 100).toFixed(1) || "";
        this.echartsData3[4].value =
          Number(res.data.items[0][17] * 100).toFixed(1) || "";
        this.echartsData3[5].value =
          Number(res.data.items[0][18] * 100).toFixed(1) || "";
        this.echartsData3 = JSON.parse(JSON.stringify(this.echartsData3));
      }
    },

    //相似推荐
    async getSimilar() {
      let params = {
        identify: "sg-data-品牌-品牌详情信息",
        filters: [
          {
            field: "业态分类",
            cond: 0,
            value: [this.detailInfo[6]]
          },
          {
            field: "key1",
            cond: 7,
            value: [this.brandID]
          }
        ],
        fields: ["品牌id", "品牌名称", "品牌图片地址", "更新时间"],
        pages: {
          page: 1,
          size: 30
        }
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        const data = res.data.items.sort((a, b) => a[3] - b[3]);
        for (let i in data) {
          this.similarData.push({
            imgUrl: res.data.items[i][2] || "",
            title: res.data.items[i][1] || "",
            content: "数据占位暂时取不到",
            id: res.data.items[i][0] || ""
          });
        }
      }
    },

    //锚点和导航联动
    handleScroll() {
      //获取dom滚动距离
      const scrollTop = this.scrollBox.scrollTop;
      //获取可视区高度
      //const offsetHeight = this.scrollBox.offsetHeight;
      //获取滚动条总高度
      //const scrollHeight = this.scrollBox.scrollHeight;
      if (
        ![
          "CreativityPut",
          "CreativityVideo",
          "CreativityAscribe",
          "offlineAnalysis",
          "onlineAnalysis"
        ].includes(this.componentName)
      ) {
        //门店分布锚点
        let mendianTop = this.$el.querySelector("#mendian").offsetTop;
        //聚客分析锚点
        let jukeTop = this.$el.querySelector("#juke").offsetTop;
        // 门店到访锚点
        let mddfTop = this.$el.querySelector("#mddf").offsetTop;
        //客户画像（到访）
        let kehuTop = this.$el.querySelector("#kehu").offsetTop;
        // 门店销量
        let mdxlTop = this.$el.querySelector("#mdxl").offsetTop;

        if (scrollTop >= mendianTop && scrollTop < jukeTop) {
          //滚动到达门店分布
          this.activeMenu = "mendian";
        } else if (scrollTop >= jukeTop && scrollTop < mddfTop) {
          // 滚动到达周边客群
          this.activeMenu = "juke";
        } else if (scrollTop >= mddfTop && scrollTop < kehuTop) {
          // 滚动到达门店到访
          this.activeMenu = "mddf";
        } else if (scrollTop >= kehuTop && scrollTop < mdxlTop) {
          //滚动到达客户画像（到访）
          this.activeMenu = "kehu";
        } else if (scrollTop >= mdxlTop) {
          // 滚动到达门店销量
          this.activeMenu = "mdxl";
        }
      } else {
        if (
          ["offlineAnalysis", "onlineAnalysis"].includes(this.componentName)
        ) {
          // 深度分析
          const offlineAnalysisTop =
            this.$el.querySelector("#offlineAnalysis").offsetTop;
          const onlineAnalysisTop =
            this.$el.querySelector("#onlineAnalysis").offsetTop;
          if (scrollTop > offlineAnalysisTop && scrollTop < onlineAnalysisTop) {
            this.activeMenu = "offlineAnalysis";
          } else if (scrollTop > onlineAnalysisTop) {
            this.activeMenu = "onlineAnalysis";
          }
          return;
        }
        // 创意投放
        const CreativityPutTop =
          this.$el.querySelector("#CreativityPut").offsetTop;
        // 创意视频
        const CreativityVideoTop =
          this.$el.querySelector("#CreativityVideo").offsetTop;
        // 创意归因
        const CreativityAscribeTop =
          this.$el.querySelector("#CreativityAscribe").offsetTop;

        if (scrollTop >= CreativityPutTop && scrollTop < CreativityVideoTop) {
          this.activeMenu = "CreativityPut";
        } else if (
          scrollTop >= CreativityVideoTop &&
          scrollTop < CreativityAscribeTop
        ) {
          this.activeMenu = "CreativityVideo";
        } else if (scrollTop >= CreativityAscribeTop) {
          this.activeMenu = "CreativityAscribe";
        }
      }
    },

    // 线上营销日期组件值切换事件
    changeDaterange(date) {
      this.daterangeData = date;
      this.getOnLineDatas();
    },

    // 线上营销-创意投放-创意投放、曝光、点击趋势图表数据
    async getOnLineDatas() {
      let params = {
        identify: "sg-data-品牌-线上创意投放曝光点击趋势",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID]
          },
          {
            field: "分区日期",
            cond: 5,
            value: this.daterangeData[0] ? this.daterangeData : []
          }
        ],
        fields: [
          "品牌名称",
          "投放量",
          "曝光量",
          "点击量",
          "分区日期",
          "版本号"
        ],
        sort: ["分区日期", "asc"]
      };
      let res = await GetCommonInfo(params);
      /*eslint-disable */
      console.log("创意投放统计数据", res);
      if (res && res.code === 200) {
        let putData = [],
          exposureData = [],
          clickData = [];
        res.data?.items?.forEach((item) => {
          putData.push({ name: item[4], value: parseFloat(item[1]) });
          exposureData.push({ name: item[4], value: parseFloat(item[2]) });
          clickData.push({ name: item[4], value: parseFloat(item[3]) });
        });
        this.putData = putData;
        this.exposureData = exposureData;
        this.clickData = clickData;
        this.statistics.putNum = putData.reduce((sum, obj) => {
          return (sum += obj.value);
        }, 0);
        this.statistics.exposureNum = exposureData.reduce((sum, obj) => {
          return (sum += obj.value);
        }, 0);
        this.statistics.clickNum = clickData.reduce((sum, obj) => {
          return (sum += obj.value);
        }, 0);
      }
    },
    async getBrandBusiness() {
      let params = {
        identify: "sg-data-品牌-品牌公司详情",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandID]
          }
        ],
        fields: [
          "公司全称",
          "成立日期",
          "注册资本",
          "法人",
          "所属行业",
          "公司状态",
          "公司类型",
          "营业期限开始时间",
          "公司主页",
          "工商注册号",
          "组织机构代码",
          "统一社会信用代码",
          "核准日期",
          "登记机关",
          "城市",
          "注册地址",
          "经营范围",
          "爱企查url",
          "营业期限结束时间",
          "logo"
        ]
      };
      let res = await GetCommonInfo(params);
      if (res && res.code === 200) {
        this.brandBusinessList = res.data.items?.[0] || [];
      }
    },
    //开通VIP
    vipClick() {
      this.isShow = true;
      // window.open("/vip", "_blank");
    }
  },
  async mounted() {
    let searchKey = this.$route.query.key;
    if (searchKey) {
      this.searchKey = searchKey;
    }
    //获取地址栏参数
    let getID = this.$route.query.id;
    if (getID) {
      this.brandID = getID;
    }
    //获取品牌详情信息
    await this.getDetail();
    //获取全部城市数据
    this.getCitys();
    //门店分布数据
    this.getCityBrand();
    this.getBrandList();
    //聚客分析
    this.getAnalysis();
    //相似推荐
    this.getSimilar();

    // 线上营销-创意投放-创意投放、曝光、点击趋势图表数据
    this.getOnLineDatas();
    this.getBrandBusiness();
    this.scrollBox.addEventListener("scroll", this.handleScroll);
  },
  components: {
    offlineAnalysis,
    onlineAnalysis,
    SummaryCard,
    MenuSide,
    RoseChart,
    BarChart,
    Similar,
    CreativityPut,
    CreativityVideo,
    CreativityAscribe,
    MapPanelLittle,
    CircumCustomer,
    ModalUpgrade
  }
};
</script>

<style lang="less">
.detail-main {
  .ivu-table-cell {
    color: #000000;
  }

  .ivu-table td:first-child .ivu-table-cell {
    color: #757e96;
  }

  .chart-screen {
    display: flex;
    align-items: center;

    .ivu-cascader {
      margin: 0 0 0 10px;
    }

    .ivu-cascader-menu .ivu-cascader-menu-item-active {
      color: #5139ff;
    }
  }
}
</style>
<style lang="less" scoped>
@import "../../assets/stylesheets/table.less";
@import "@/assets/stylesheets/config.less";
.detail-main {
  display: flex;
  flex-wrap: wrap;

  .help_icon {
    margin-left: 8px;
  }

  .main-scroll {
    width: calc(100% - 200px);
    height: calc(100% - 101px);
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: auto;
    position: fixed;
    right: 0;
    bottom: 0;
    background: #f6f6fa;
    transition: width 0.3s;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-content: flex-start;

    .map-size {
      width: 100%;
      height: 200px;
      background: #d7d7d7;
    }

    .guide-section {
      padding-top: 30px;
      margin-top: 18px;
      width: 1120px;
      height: 205px;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      text-align: center;
      img {
        margin-bottom: 16px;
      }
      .zx-btn {
        margin-bottom: 16px;
        width: 200px;
        height: 44px;
        line-height: 44px;
        font-size: 16px;
        color: #fff;
        background: linear-gradient(223deg, #4f3bff 0%, #5a8eff 100%);
        border-radius: 4px;
      }
      .zx-btn:hover {
        background: linear-gradient(223deg, #3e29f9 0%, #4982fe 100%);
      }
    }

    .main-size {
      // .MediaStyle(
      //   {
      //     max-width: 1500px;
      //     width: 1500px;
      //   }
      // );
      max-width: 1120px;
      // min-width: 1120px;
      margin-top: -62px;
      position: relative;
      z-index: 199;
    }
    .mt0 {
      margin-top: 0;
    }
    .content-item {
      width: 100%;
      height: auto;
      position: relative;
      margin: 18px 0 0 0;
      width: 100%;
      height: auto;
      background: #ffffff;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
      border-radius: 4px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .item-title {
        width: 100%;
        height: 56px;
        display: flex;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        color: #000000;
        box-sizing: border-box;
        padding: 0 0 0 20px;
        border-bottom: 1px solid #e0e0e0;
        img {
          border-radius: 17px;
          border: 1px solid rgba(53, 25, 251, 0.11);
          margin-right: 10px;
        }
      }

      .chart-box1 {
        width: 50%;
        height: 323px;
        border-right: 1px solid #eeeeee;

        .chart-size {
          width: 100%;
          height: 225px;
          margin: 20px 0 0 0;
        }
      }

      .chart-box2 {
        width: 50%;
        height: 323px;

        .chart-size {
          width: 100%;
          height: 235px;
          margin: 15px 0 0 0;
        }
      }

      .chart-box3 {
        width: 100%;
        height: 530px;
        border-top: 1px solid #eeeeee;
        position: relative;
        .table-size {
          position: relative;
          margin: 20px 0 0 0;
          width: 100%;
          box-sizing: border-box;
          padding: 0 30px;

          .operation-btn {
            font-size: 14px;
            font-weight: 500;
            color: #3519fb;
            cursor: pointer;
          }
        }
      }

      .chart-box4 {
        width: 100%;
        height: 317px;
        border-top: 1px solid #eeeeee;
        .chart-size {
          width: 100%;
          height: 238px;
          margin: 15px 0 0 0;
        }
        .chart-title {
          justify-content: normal;
          color: rgba(0, 0, 0, 0.8);
        }
        ::v-deep .ivu-poptip {
          margin-left: 6px;
        }
      }

      .chart-title {
        width: 100%;
        box-sizing: border-box;
        padding: 25px 30px 0 30px;
        font-size: 14px;
        font-weight: 500;
        color: rgba(0, 0, 0, 0.7);
        display: flex;
        justify-content: space-between;
        align-items: center;

        .chart-screen {
          font-size: 14px;
          font-weight: 500;
          color: rgba(0, 0, 0, 0.7);

          .screen-num {
            color: #3519fb;
          }
        }
      }

      .chart-title2 {
        padding: 16px 30px 0 30px;
      }

      .similar-size {
        width: 100%;
        height: 270px;
        padding: 30px 0 0 0;
        box-sizing: border-box;
      }
    }

    .demo-item {
      //  .MediaStyle(
      //   {
      //     width: 1128px;
      //   }
      // );
      width: 1128px;
      margin: 15px 0 0 -4px;
      display: flex;
      position: relative;
      .g-vip-icon2{
        position: absolute;
        left: 114px;
        top: 18px;
        font-size: 16px;
      }
      .g-vip-icon3{
         position: absolute;
        left: 164px;
        top: 18px;
        font-size: 16px;
      }
      img {
        width: 100%;
      }
      .demo-btn {
        width: 200px;
        height: 45px;
        position: absolute;
        top: 386px;
        left: 465px;
        cursor: pointer;
      }
    }

    .page-bottom {
      width: 100%;
      height: 60px;
    }
  }

  .main-scroll-change {
    width: calc(100% - 74px);
    transition: width 0.3s;
  }
}
.m-tips {
  padding: 16px;
  font-size: 12px;
}
.brand-business {
  display: flex;
  flex-wrap: wrap;
  padding: 30px 40px;
  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.8);
    line-height: 20px;
    width: 33%;
    margin-bottom: 24px;
    .desc {
      display: inline-block;
    }
    &.with100 {
      width: 100%;
      .desc {
        width: calc(100% - 80px);
        vertical-align: top;
      }
    }
    & > a {
      color: #3519fb;
    }
  }

  span {
    color: rgba(0, 0, 0, 0.4);
    line-height: 20px;
  }
  .data-source {
    display: flex;
    align-items: center;
    .line {
      width: 1px;
      height: 16px;
      border-right: 1px solid #dbdada;
      margin: 0 10px;
    }
    a {
      color: #999;
      &:hover {
        color: #3519fb;
        .icon-more {
          background-image: url("../../assets/images/icon/more1_cur.png");
        }
      }
    }
    /deep/ .ivu-icon {
      width: 12px;
      height: 12px;
      vertical-align: middle;
      margin-top: -3px;
    }
  }
}
</style>

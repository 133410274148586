<template>
  <div class="creative-box">
    <div class="creative-demo">
      <div class="creative-demo-bg">
        <div class="creative-demo-btn" @click="toVip">去升级</div>
        <div class="creative-demo-demoBg"></div>
      </div>
    </div>
    <section class="creative-chart">
      <section class="creative-chart-one">
        <Card class="tags-pic-con">
          <template slot="title">
            <div class="card-title">
              创意素材标签图谱
              <Poptip trigger="hover" placement="right" padding="0 0">
                <Icon type="ios-help-circle-outline" class="help_icon" />
                <div class="m-tips" slot="content">
                  <h5>分析目的：</h5>
                  <p>
                    <span
                      >•
                      提取创意素材中的标签结构，并按标签使用率进行分布展示。</span
                    >
                  </p>
                  <p>
                    <span>• 气泡越大，表示标签的使用率就越高。 </span>
                  </p>
                </div>
              </Poptip>
            </div>
          </template>
          <Mcharts
            :options="relationOption"
            type="cross"
            class="relation-echart"
          />
        </Card>
        <section>
          <Card class="material-format-con">
            <template slot="title">
              <div class="card-title">
                素材版式占比
                <Poptip trigger="hover" placement="left" padding="0 0">
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                  <div class="m-tips" slot="content">
                    <h5>分析目的：</h5>
                    <p>
                      <span
                        >呈现该行业（产品）分析的创意素材里，使用的视频版式，以及各版式投放表现。</span
                      >
                    </p>
                    <p>
                      <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                    </p>
                    <p>
                      <span
                        >• 标签的使用率（标签数量/总标签数量之和）*100%</span
                      >
                    </p>
                    <p><span>• 标签的点击率（点击数/展示数）*100%</span></p>
                    <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                    <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                  </div>
                </Poptip>
              </div>
            </template>
            <div class="material-format">
              <Mcharts
                :options="formatOption"
                type="cross"
                class="format-echart"
              />
            </div>
          </Card>
          <Card class="material-time-con">
            <template slot="title">
              <div class="card-title">
                素材时长占比
                <Poptip trigger="hover" placement="left" padding="0 0">
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                  <div class="m-tips" slot="content">
                    <h5>分析目的：</h5>
                    <p>
                      <span
                        >呈现该行业（产品）分析的创意素材里，使用的视频时长，以及投放表现。</span
                      >
                    </p>
                    <p>
                      <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                    </p>
                    <p>
                      <span
                        >• 标签的使用率（标签数量/总标签数量之和）*100%</span
                      >
                    </p>
                    <p><span>• 标签的点击率（点击数/展示数）*100%</span></p>
                    <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                    <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                  </div>
                </Poptip>
              </div>
            </template>
            <div class="material-time">
              <Mcharts
                :options="timeBarOption"
                type="cross"
                class="time-echart"
              />
            </div>
          </Card>
        </section>
      </section>
      <section class="creative-chart-two">
        <Card class="columnar-con">
          <template slot="title">
            <div class="card-title">
              创意类型-占比
              <Poptip trigger="hover" placement="right" padding="0 0">
                <Icon type="ios-help-circle-outline" class="help_icon" />
                <div class="m-tips" slot="content">
                  <h5>分析目的：</h5>
                  <p>
                    <span
                      >呈现该行业（产品）分析的创意素材里，使用的拍摄手法，以及投放表现。</span
                    >
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p>
                    <span>• 标签的使用率（标签数量/总标签数量之和）*100%</span>
                  </p>
                  <p><span>• 标签的点击率（点击数/展示数）*100%</span></p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                </div>
              </Poptip>
            </div>
          </template>
          <Mcharts :options="typeBarOption" type="cross" class="bar-echart" />
        </Card>
        <Card class="columnar-con">
          <template slot="title">
            <div class="card-title">
              创意类型-元素解析
              <Poptip trigger="hover" placement="left" padding="0 0">
                <Icon type="ios-help-circle-outline" class="help_icon" />
                <div class="m-tips" slot="content">
                  <h5>分析目的：</h5>
                  <p>
                    <span>• 深入解析创意类型的详情元素，并呈现投放表现。</span>
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p>
                    <span>• 标签的使用率（标签数量/总标签数量之和）*100%</span>
                  </p>
                  <p><span>• 标签的点击率（点击数/展示数）*100%</span></p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                </div>
              </Poptip>
            </div>
          </template>
          <template>
            <div
              v-for="(h, index) in elementEchart"
              :key="index"
              class="c-element-item-pie"
            >
              <h5>{{ h.name }}</h5>
              <Mcharts :options="h.option" type="pie" class="element-chart" />
              <ul class="showText">
                <li v-for="(item, ind) in h.data" :key="ind" class="item">
                  <span>
                    <span
                      class="circleIcon"
                      :style="{ background: item.color }"
                    ></span>
                    <span class="item1">{{ item.name }}</span>
                    <span class="itemLine"></span>
                    <span class="item2 item11"
                      ><label>转化率：</label>{{ item.cost }}</span
                    >
                  </span>
                  <span class="item2"
                    ><label>平均消耗：</label>¥{{
                      item.conver.toLocaleString("en-US")
                    }}</span
                  >
                </li>
              </ul>
            </div>
          </template>
        </Card>
        <Card class="columnar-con">
          <template slot="title">
            <div class="card-title">
              创意元素-音乐
              <Poptip trigger="hover" placement="right" padding="0 0">
                <Icon type="ios-help-circle-outline" class="help_icon" />
                <div class="m-tips" slot="content">
                  <h5>分析目的：</h5>
                  <p>
                    <span
                      >解析音乐元素，呈现该行业（产品）分析的创意素材里，使用的音乐元素，以及各自的投放表现。</span
                    >
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p>
                    <span>• 标签的使用率（标签数量/总标签数量之和）*100%</span>
                  </p>
                  <p><span>• 标签的点击率（点击数/展示数）*100%</span></p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                </div>
              </Poptip>
            </div>
          </template>
          <Mcharts :options="musicBarOption" type="cross" class="bar-echart" />
        </Card>
        <Card class="columnar-con">
          <template slot="title">
            <div class="card-title">
              <div class="card-title">
                创意元素-转化引导
                <Poptip trigger="hover" placement="left" padding="0 0">
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                  <div class="m-tips" slot="content">
                    <h5>分析目的：</h5>
                    <p>
                      <span
                        >呈现该行业（产品）分析的创意素材里，使用的转化引导，以及各自的投放表现。</span
                      >
                    </p>
                    <p>
                      <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                    </p>
                    <p>
                      <span
                        >• 标签的使用率（标签数量/总标签数量之和）*100%</span
                      >
                    </p>
                    <p><span>• 标签的点击率（点击数/展示数）*100%</span></p>
                    <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                    <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                  </div>
                </Poptip>
              </div>
            </div>
          </template>
          <Mcharts :options="costBarOption" type="cross" class="bar-echart" />
        </Card>
        <Card class="columnar-con">
          <template slot="title">
            <div class="card-title">
              创意元素-角色人设
              <Poptip trigger="hover" placement="right" padding="0 0">
                <Icon type="ios-help-circle-outline" class="help_icon" />
                <div class="m-tips" slot="content">
                  <h5>分析目的：</h5>
                  <p>
                    <span
                      >解析角色定位，呈现该行业（产品）分析的创意素材里，使用的角色人设，以及各自的投放表现。</span
                    >
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p>
                    <span>• 标签的使用率（标签数量/总标签数量之和）*100%</span>
                  </p>
                  <p><span>• 标签的点击率（点击数/展示数）*100</span></p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                </div>
              </Poptip>
            </div>
          </template>
          <Mcharts :options="roleBarOption" type="cross" class="bar-echart" />
        </Card>
        <Card class="columnar-con">
          <template slot="title">
            <div class="card-title">
              创意元素-拍摄场景
              <Poptip trigger="hover" placement="bottom" padding="0 0">
                <Icon type="ios-help-circle-outline" class="help_icon" />
                <div class="m-tips" slot="content">
                  <h5>分析目的：</h5>
                  <p>
                    <span
                      >解析拍摄场景，呈现该行业（产品）分析的创意素材里，使用的场景类型，以及各自的投放表现。</span
                    >
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p>
                    <span>• 标签的使用率（标签数量/总标签数量之和）*100%</span>
                  </p>
                  <p><span>• 标签的点击率（点击数/展示数）*100%</span></p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 标签的转化成本（总花费/转化数)</span></p>
                </div>
              </Poptip>
            </div>
          </template>
          <Mcharts :options="shootBarOption" type="cross" class="bar-echart" />
        </Card>
      </section>
      <Card class="m-bottom" title="内容策略-前3s吸睛方式">
        <template v-slot>
          <section class="strategy-con">
            <div class="four-items">
              <Poptip trigger="hover" placement="right" padding="0 0">
                <div class="m-tips" slot="content">
                  <p>
                    <span
                      >呈现该行业（产品）分析的创意素材里，使用的吸睛方式，以及各自的投放表现。</span
                    >
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 气泡越大，表示对应的数据指标越高</span></p>
                </div>
                <div class="items-title">
                  标签表现分析
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                </div>
              </Poptip>
              <Mcharts :options="fourTime" type="cross" class="four-echart" />
            </div>
            <div class="cloud-chart">
              <Poptip trigger="hover" placement="left" padding="0 0">
                <div class="m-tips" slot="content">
                  <p>
                    <span
                      >创意素材中【吸睛】标签的使用量分布，云图中标签的字号越大表示该标签的使用频率越高。</span
                    >
                  </p>
                </div>
                <div class="items-title">
                  标签使用率云图
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                </div>
              </Poptip>
              <data-words
                :chartData="threeTimeList"
                :width="'398px'"
                :height="'370px'"
              ></data-words>
            </div>
          </section>
        </template>
      </Card>
      <Card class="m-bottom" title="内容策略-表现形式">
        <template v-slot>
          <section class="strategy-con">
            <div class="four-items">
              <Poptip trigger="hover" placement="right" padding="0 0">
                <div class="m-tips" slot="content">
                  <p>
                    <span
                      >呈现该行业（产品）分析的创意素材里，使用的表达形式，以及各自的投放表现。</span
                    >
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 气泡越大，表示对应的数据指标越高</span></p>
                </div>
                <div class="items-title">
                  标签表现分析
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                </div>
              </Poptip>
              <Mcharts :options="fourTags" type="cross" class="four-echart" />
            </div>
            <div class="cloud-chart">
              <Poptip trigger="hover" placement="left" padding="0 0">
                <div class="m-tips" slot="content">
                  <p>
                    <span
                      >呈现该行业（产品）分析的创意素材里，使用的表达形式，以及各自的投放表现。</span
                    >
                  </p>
                </div>
                <div class="items-title">
                  标签使用率云图
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                </div>
              </Poptip>
              <data-words
                :chartData="performList"
                :width="'398px'"
                :height="'370px'"
              ></data-words>
            </div>
          </section>
        </template>
      </Card>
      <Card title="内容策略-核心卖点">
        <template v-slot>
          <section class="strategy-con">
            <div class="four-items">
              <Poptip trigger="hover" placement="right" padding="0 0">
                <div class="m-tips" slot="content">
                  <p>
                    <span
                      >呈现该行业（产品）分析的创意素材里的核心卖点，以及各自的投放表现。</span
                    >
                  </p>
                  <p>
                    <span>• 标签的平均消费（标签消费总金额/标签数量）</span>
                  </p>
                  <p><span>• 标签的转化率（转化数/点击数）*100%</span></p>
                  <p><span>• 气泡越大，表示对应的数据指标越高</span></p>
                </div>
                <div class="items-title">
                  标签表现分析
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                </div>
              </Poptip>
              <Mcharts :options="fourSell" type="cross" class="four-echart" />
            </div>
            <div class="cloud-chart">
              <Poptip trigger="hover" placement="left" padding="0 0">
                <div class="m-tips" slot="content">
                  <p>
                    <span
                      >创意素材中【核心卖点】标签的使用量分布，云图中标签的字号越大表示该标签的使用频率越高。</span
                    >
                  </p>
                </div>
                <div class="items-title">
                  标签使用率云图
                  <Icon type="ios-help-circle-outline" class="help_icon" />
                </div>
              </Poptip>
              <data-words
                :chartData="sallList"
                :width="'398px'"
                :height="'370px'"
              ></data-words>
            </div>
          </section>
        </template>
      </Card>
    </section>
    <section class="small-box">
      <section class="small-view">
        <div class="small-title">
          <img
            src="https://fe-1301391939.cos.ap-shanghai.myqcloud.com/shantou/AI-Insight/dc-view.png"
            alt=""
          />
          <p>
            <span>某服装品牌</span>，在<span>{{ yesterday }}</span
            >投放时间内的<span>30条</span>创意素材，进行创意分析
          </p>
        </div>
        <div class="small-list-box">
          <div class="small-list">
            <div class="small-item">
              <img
                class="small-item-icon"
                :src="require('@/assets/images/icon/icon_serial.png')"
              />
              <span class="small-item-title">创意目标消群体：</span>
              <span class="small-item-text"
                >某服装品牌目标客户为20岁至45岁的消费者</span
              >
              <div class="small-item-border"></div>
            </div>
            <div class="small-item">
              <img
                class="small-item-icon"
                :src="require('@/assets/images/icon/icon_serial.png')"
              />
              <span class="small-item-title">创意目标客户定位：</span>
              <span class="small-item-text"
                >一类应该男性用户，第二类少男少女用户，第三类情侣用户</span
              >
              <div class="small-item-border"></div>
            </div>
            <div class="small-item">
              <img
                class="small-item-icon"
                :src="require('@/assets/images/icon/icon_serial.png')"
              />
              <span class="small-item-title">明星代言：</span>
              <span class="small-item-text"
                >周杰伦作为“跨时代顶流”，对80、90后消费人群有强大号召力，某服装品牌有望借助周杰伦巨大的粉丝流量，扩大知名度</span
              >
              <div class="small-item-border"></div>
            </div>
            <div class="small-item">
              <img
                class="small-item-icon"
                :src="require('@/assets/images/icon/icon_serial.png')"
              />
              <span class="small-item-title">创意基础信息：</span>
              <span class="small-item-text"
                >视频样本中，竖版视频占比高达70.8%，明显符合抖音电商信息流投放的视频使用；视频时长基本稳定在60s内</span
              >
              <div class="small-item-border"></div>
            </div>
            <div class="small-item">
              <img
                class="small-item-icon"
                :src="require('@/assets/images/icon/icon_serial.png')"
              />
              <span class="small-item-title">创意类型：</span>
              <span class="small-item-text"
                >口播类视频占比明显突出达49.7%，其中达人种草类口播以及明星达人混合类混剪更瘦抖音电商用户喜欢，剪辑类以及口播类型的视频，鉴于其卖点突出直接了当的表现形式，深受潜在用户关注。</span
              >
              <div class="small-item-border"></div>
            </div>
            <div class="small-item">
              <img
                class="small-item-icon"
                :src="require('@/assets/images/icon/icon_serial.png')"
              />
              <span class="small-item-title">核心卖点：</span>
              <span class="small-item-text"
                >某服装品牌，创意视频的核心卖点体现服装的普适性、打造高品质服装的同时保证价格亲民总占比禁50%，卖点从“男人的衣柜”到“服装国民品牌”逐步过度</span
              >
            </div>
          </div>
        </div>
      </section>
    </section>
    <ModalUpgrade :isShow="isShow" @close="isShow = false"></ModalUpgrade>
  </div>
</template>

<script>
import ModalUpgrade from "@/components/ModalUpgrade.vue"
import Mcharts from "@/components/Mcharts.vue";
import DataWords from "@/components/Wordcloud.vue";
import { numFormat } from "js/utils.js";
import aiEchartsData from "js/aiEchartsData";
export default {
  name: "CreativeAscribe",
  data() {
    return {
      isShow:false,
      formatOption: {}, // 版式
      timeBarOption: {},
      musicBarOption: {},
      roleBarOption: {},
      shootBarOption: {},
      typeBarOption: {},
      costBarOption: {},
      sallList: aiEchartsData.sallList, //核心卖点，词云
      performList: aiEchartsData.performList, //表现形式，词云
      threeTimeList: aiEchartsData.threeTimeList, //3秒吸精，词云
      fourSell: aiEchartsData.fourOption,
      fourTime: aiEchartsData.fourTime,
      fourTags: aiEchartsData.fourTags,
      elementEchart: aiEchartsData.elementEchart, //元素解析
      relationOption: null,
      yesterday: "",
    };
  },
  created() {
    const now = new Date();
    const year = now.getFullYear(); //得到年份
    const month = (now.getMonth() + 1).toString().padStart(2, "0"); //得到月份
    const upday = (now.getDate() - 1).toString().padStart(2, "0"); //得到日期
    this.yesterday = `${year}-${month}-${upday}`;
    this.$nextTick(() => {
      this.init();
    });
  },
  components: {
    Mcharts,
    DataWords,
    ModalUpgrade
  },
  methods: {
    init() {
      this.elementEchart = aiEchartsData.elementEchart;
      this.relationOption = aiEchartsData.relationOption;
      this.sallList = aiEchartsData.sallList; //核心卖点，词云
      this.performList = aiEchartsData.performList; //表现形式，词云
      this.threeTimeList = aiEchartsData.threeTimeList; //3秒吸精，词云
      this.fourSell = aiEchartsData.fourOption;
      {
        let format = this.deepClone(aiEchartsData.BarOption);
        format.series = [
          {
            name: "使用率",
            type: "bar",
            barWidth: 12,
            data: [100],
          },
          {
            name: "点击率",
            type: "bar",
            barWidth: 12,
            data: [7.45],
          },
          {
            name: "转化率",
            type: "bar",
            barWidth: 12,
            data: [2.24],
          },
          {
            name: "转化成本",
            type: "line",
            xAxisIndex: 1,
            data: [23.51],
          },
          {
            name: "平均消耗",
            type: "line",
            xAxisIndex: 2,
            data: [1236.61],
          },
        ];
        format.yAxis.data = ["竖版"]; //"横版", "横版拍摄 竖版输出"
        format.xAxis[1].max = this.calMax(format.series[3].data);
        format.xAxis[1].interval = this.calMax(format.series[3].data) / 5;
        format.xAxis[2].max = this.calMax(format.series[4].data);
        format.xAxis[2].interval = this.calMax(format.series[4].data) / 5;
        this.formatOption = format;
      }

      {
        let timeBar = this.deepClone(aiEchartsData.BarOption);
        timeBar.series = [
          {
            name: "使用率",
            type: "bar",
            barWidth: 12,
            data: [66.7, 33.3],
          },
          {
            name: "点击率",
            type: "bar",
            barWidth: 12,
            data: [2.71, 1.98],
          },
          {
            name: "转化率",
            type: "bar",
            barWidth: 12,
            data: [4.6, 7.57],
          },
          {
            name: "转化成本",
            type: "line",
            xAxisIndex: 1,
            data: [29.86, 22.27],
          },
          {
            name: "平均消耗",
            type: "line",
            xAxisIndex: 2,
            data: [743.59, 1529.81],
          },
        ];
        timeBar.yAxis.data = ["60s以上", "30s-60s"];
        timeBar.xAxis[1].max = this.calMax(timeBar.series[3].data);
        timeBar.xAxis[1].interval = this.calMax(timeBar.series[3].data) / 5;
        timeBar.xAxis[2].max = this.calMax(timeBar.series[4].data);
        timeBar.xAxis[2].interval = this.calMax(timeBar.series[4].data) / 5;
        this.timeBarOption = timeBar;
      }

      {
        let musicBar = this.deepClone(aiEchartsData.BarOption);
        musicBar.series = [
          {
            name: "使用率",
            type: "bar",
            barWidth: 12,
            data: [96.8, 3.2],
          },
          {
            name: "点击率",
            type: "bar",
            barWidth: 12,
            data: [2.32, 1.47],
          },
          {
            name: "转化率",
            type: "bar",
            barWidth: 12,
            data: [5.95, 30.85],
          },
          {
            name: "转化成本",
            type: "line",
            xAxisIndex: 1,
            data: [25.46, 17.63],
          },
          {
            name: "平均消耗",
            type: "line",
            xAxisIndex: 2,
            data: [1005.66, 6928.3],
          },
        ];
        musicBar.yAxis.data = ["纯音乐", "热门歌单"];
        musicBar.xAxis[1].max = this.calMax(musicBar.series[3].data);
        musicBar.xAxis[1].interval = this.calMax(musicBar.series[3].data) / 5;
        musicBar.xAxis[2].max = this.calMax(musicBar.series[4].data);
        musicBar.xAxis[2].interval = this.calMax(musicBar.series[4].data) / 5;
        this.musicBarOption = musicBar;
      }

      {
        let roleBar = this.deepClone(aiEchartsData.BarOption);
        roleBar.series = [
          {
            name: "使用率",
            type: "bar",
            barWidth: 12,
            data: [36.7, 36.7, 16.7, 6.7, 3.3],
          },
          {
            name: "点击率",
            type: "bar",
            barWidth: 12,
            data: [2.2, 3.19, 2.18, 1.34, 3.48],
          },
          {
            name: "转化率",
            type: "bar",
            barWidth: 12,
            data: [2.2, 3.19, 2.18, 1.34, 3.48],
          },
          {
            name: "转化成本",
            type: "line",
            xAxisIndex: 1,
            data: [31.86, 23, 20.42, 25.65, 19.36],
          },
          {
            name: "平均消耗",
            type: "line",
            xAxisIndex: 2,
            barWidth: 12,
            data: [1074.41, 499.78, 992.25, 2988.17, 1916.19],
          },
        ];
        roleBar.yAxis.data = ["素人", "达人", "夫妻/情侣", "美女/帅哥", "亲戚"];
        roleBar.xAxis[1].max = this.calMax(roleBar.series[3].data);
        roleBar.xAxis[1].interval = this.calMax(roleBar.series[3].data) / 5;
        roleBar.xAxis[2].max = this.calMax(roleBar.series[4].data);
        roleBar.xAxis[2].interval = this.calMax(roleBar.series[4].data) / 5;
        this.roleBarOption = roleBar;
      }

      {
        let shootBar = this.deepClone(aiEchartsData.BarOption);
        shootBar.series = [
          {
            name: "使用率",
            type: "bar",
            barWidth: 12,
            data: [40.0, 30.0, 10.0, 10.0, 3.3, 6.7],
          },
          {
            name: "点击率",
            type: "bar",
            barWidth: 12,
            data: [2.44, 2.41, 0.6, 7.27, 3.48, 9.22],
          },
          {
            name: "转化率",
            type: "bar",
            barWidth: 12,
            data: [5.1, 7.68, 9.5, 3.23, 7.36, 2.87],
          },
          {
            name: "转化成本",
            type: "line",
            xAxisIndex: 1,
            data: [24.67, 23.18, 47.29, 18.16, 19.36, 17.38],
          },
          {
            name: "平均消耗",
            type: "line",
            xAxisIndex: 2,
            data: [631.27, 1282.59, 2206.7, 496.5, 1916.19, 512.81],
          },
        ];
        shootBar.yAxis.data = [
          "居家环境",
          "办公室",
          "商场",
          "特殊场景",
          "高档酒店",
          "户外/街边",
        ];
        shootBar.xAxis[1].max = this.calMax(shootBar.series[3].data);
        shootBar.xAxis[1].interval = this.calMax(shootBar.series[3].data) / 5;
        shootBar.xAxis[2].max = this.calMax(shootBar.series[4].data);
        shootBar.xAxis[2].interval = this.calMax(shootBar.series[4].data) / 5;
        this.shootBarOption = shootBar;
      }

      {
        let typeBar = this.deepClone(aiEchartsData.BarOption);
        typeBar.series = [
          {
            name: "使用率",
            type: "bar",
            barWidth: 12,
            data: [39.5, 30.2, 30.2],
          },
          {
            name: "点击率",
            type: "bar",
            barWidth: 12,
            data: [1.95, 3.25, 2.13],
          },
          {
            name: "转化率",
            type: "bar",
            barWidth: 12,
            data: [9.11, 3.84, 9.56],
          },
          {
            name: "转化成本",
            type: "line",
            xAxisIndex: 1,
            data: [23.64, 22.52, 23.97],
          },
          {
            name: "平均消耗",
            type: "line",
            xAxisIndex: 2,
            data: [1799.23, 462.55, 1235.43],
          },
        ];
        typeBar.yAxis.data = ["情景剧", "真人口播", "图文展示"];
        typeBar.xAxis[1].max = this.calMax(typeBar.series[3].data);
        typeBar.xAxis[1].interval = this.calMax(typeBar.series[3].data) / 5;
        typeBar.xAxis[2].max = this.calMax(typeBar.series[4].data);
        typeBar.xAxis[2].interval = this.calMax(typeBar.series[4].data) / 5;
        this.typeBarOption = typeBar;
      }

      {
        let costBar = this.deepClone(aiEchartsData.BarOption);
        costBar.series = [
          {
            name: "使用率",
            type: "bar",
            barWidth: 12,
            data: [48.2, 46.4, 5.4],
          },
          {
            name: "点击率",
            type: "bar",
            barWidth: 12,
            data: [2.65, 2.17, 1.95],
          },
          {
            name: "转化率",
            type: "bar",
            barWidth: 12,
            data: [5.74, 6.07, 7.01],
          },
          {
            name: "转化成本",
            type: "line",
            xAxisIndex: 1,
            data: [25.28, 25.69, 22.09],
          },
          {
            name: "平均消耗",
            type: "line",
            xAxisIndex: 2,
            data: [985.76, 1092.79, 1038.42],
          },
        ];
        costBar.yAxis.data = ["品牌slogan引导", "常规引导", "创意动画引导"];
        costBar.xAxis[1].max = this.calMax(costBar.series[3].data);
        costBar.xAxis[1].interval = this.calMax(costBar.series[3].data) / 5;
        costBar.xAxis[2].max = this.calMax(costBar.series[4].data);
        costBar.xAxis[2].interval = this.calMax(costBar.series[4].data) / 5;
        this.costBarOption = costBar;
      }

      {
        let Tags = this.deepClone(aiEchartsData.fourOption);
        Tags.series[0].data = [
          {
            name: "使用者安利",
            value: [6.4, 1580.16],
          },
          {
            name: "熟人推荐",
            value: [5.74, 894.59],
          },
          {
            name: "达人种草",
            value: [4.42, 484.91],
          },
          {
            name: "对比解说",
            value: [3.73, 566.7],
          },
          {
            name: "偶然发现",
            value: [9.68, 608.63],
          },
          {
            name: "支付环节使用",
            value: [15.76, 2000.0],
          },
        ];
        Tags.series[0].markLine.data = [
          {
            name: "平均消费",
            xAxis: 9,
          },
          {
            name: "转化率",
            yAxis: 1300,
          },
        ];
        this.fourTags = Tags;
      }
      {
        let Time = this.deepClone(aiEchartsData.fourOption);
        Time.series[0].data = [
          { name: "提问开头", value: [4.05, 908.62] },
          { name: "反常猎奇", value: [5.07, 668.6] },
          { name: "冲突打压", value: [12.6, 2729.63] },
          { name: "写字字幕", value: [6.32, 717.73] },
          { name: "圈定用户", value: [6.33, 565.87] },
          { name: "激动情绪", value: [4.08, 847.63] },
          { name: "两性暗示", value: [2.73, 605.98] },
          { name: "满足自尊", value: [14.87, 1226.45] },
          { name: "元素特写", value: [5.21, 1078.63] },
        ];
        Time.series[0].markLine.data = [
          {
            name: "平均消费",
            xAxis: 8,
          },
          {
            name: "转化率",
            yAxis: 1600,
          },
        ];
        this.fourTime = Time;
      }
    },
    calMax(arr) {
      var max = arr[0];
      for (var i = 1; i < arr.length; i++) {
        // 求出一组数组中的最大值
        if (max < arr[i]) {
          max = arr[i];
        }
      }
      let bite = 0;
      if (max < 10) {
        return 10;
      }
      while (max >= 10) {
        max /= 10;
        bite += 1;
      }
      return Math.ceil(max) * Math.pow(10, bite);
    },
    _numFormat(num) {
      return numFormat(num);
    },
    deepClone(obj) {
      // 定义一个映射，初始化的时候将 obj 本身加入映射中
      const map = new WeakMap();
      map.set(obj, true);
      // 封装原来的递归逻辑
      const copy = (obj) => {
        if (!obj || typeof obj !== "object") {
          return {};
        }
        const newObj = Array.isArray(obj) ? [] : {};
        for (const key in obj) {
          const value = obj[key];
          // 如果拷贝的是简单类型的值直接进行赋值
          if (typeof value !== "object") {
            newObj[key] = value;
          } else {
            // 如果拷贝的是复杂数据类型第一次拷贝后存入 map
            // 第二次再次遇到该值时直接赋值为 null，结束递归
            if (map.has(value)) {
              newObj[key] = null;
            } else {
              map.set(value, true);
              newObj[key] = copy(value);
            }
          }
        }
        return newObj;
      };
      return copy(obj);
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value2 - value1;
      };
    },
    toVip() {
      this.isShow = true
      // window.open("/vip", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.showText {
  font-size: 12px;
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 6px;
    margin-bottom: 16px;
    &:last-child {
      margin-bottom: 0px;
    }
    .circleIcon {
      display: inline-block;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: linear-gradient(270deg, #48ffff 0%, #0068ff 100%);
      margin-right: 4px;
    }
    .item1 {
      display: inline-block;
      font-weight: 500;
      width: 76px;
    }
    .itemLine {
      display: inline-block;
      width: 1px;
      height: 12px;
      background: #d9d9d9;
      margin-right: 4px;
    }
    .item2 {
      color: #666;
      margin-right: 8px;
      lable {
        color: #999999;
      }
    }
  }
}
.creative-box {
  margin: 0;
  width: 1120px;
  height: 1231px;
  border-radius: 4px;
  padding-top: 0;
  padding-bottom: 0;
  border-bottom: 22px solid #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  content-visibility: auto;
  contain-intrinsic-size: 1120px 1231px;
  /deep/.ivu-card:hover {
    box-shadow: none;
    border-color: none;
  }
  .creative-demo {
    height: 173px;
    position: relative;
    .creative-demo-bg {
      width: 608px;
      height: 123px;
      background: url("../../../assets/images/bg-vip.png") no-repeat center/100%;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      .creative-demo-btn {
        position: absolute;
        top: 56px;
        left: 50%;
        transform: translateX(-50%);
        width: 200px;
        height: 44px;
        background: linear-gradient(134deg, #8c92b7 0%, #43496e 100%);
        box-shadow: 0px 1px 2px 0px rgba(255, 176, 0, 0.1);
        border-radius: 4px;
        font-size: 18px;
        font-weight: 600;
        color: #faeed8;
        line-height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        &:hover {
          background: linear-gradient(134deg, #6c74a5 0%, #2f3453 100%);
        }
      }
      .creative-demo-demoBg {
        width: 464px;
        height: 58px;
        background: url("../../../assets/images/bg-demo.png") no-repeat
          center/100%;
        position: absolute;
        top: 112px;
        left: 70px;
      }
    }
  }
  .creative-chart {
    padding: 15px 20px;
    background-color: rgba(0, 0, 0, 0.03);
    .creative-chart-one {
      display: flex;
      .tags-pic-con {
        width: 646px;
        height: 796px;
        background: #ffffff;
        border-radius: 4px;
        margin-right: 16px;
        margin-bottom: 16px;
        .relation-echart {
          width: 100%;
          /deep/ .chart {
            height: 700px !important;
          }
        }
      }
      .material-format-con {
        width: 418px;
        height: 389px;
        .material-format {
          margin-top: -30px;
          /deep/ .format-echart {
            height: 330px !important;
            .chart {
              height: 330px !important;
            }
          }
        }
      }
      .material-time-con {
        width: 418px;
        height: 389px;
        margin-top: 16px;
        &.ivu-card:hover {
          box-shadow: none;
          border-color: none;
        }
        .material-time {
          margin-top: -30px;
          .time-echart {
            height: 330px !important;
            /deep/ .chart {
              height: 330px !important;
            }
          }
        }
      }
    }
    .creative-chart-two {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 16px;
      .columnar-con {
        width: 531px;
        background: #ffffff;
        border-radius: 4px;
        margin-right: 16px;
        margin-bottom: 15px;
        &:nth-child(2n) {
          margin-right: 0px;
          margin-top: 0px;
          flex: 1;
        }
        &:nth-child(5) {
          margin-bottom: 0px;
        }
        &:nth-child(6) {
          margin-bottom: 0px;
        }
        .c-element-item-pie {
          display: flex;
          align-items: center;
          padding-left: 32px;
          padding-bottom: 32px;
          /deep/ .element-chart {
            width: 80px;
            .chart {
              height: 80px !important;
            }
          }
          h5 {
            width: 60px;
            line-height: 100px;
            font-size: 14px;
            font-weight: 500;
            color: #333333;
          }
        }
      }
    }
    .strategy-con {
      margin-bottom: 16px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      .four-items {
        width: 626px;
        height: 428px;
        .four-echart {
          /deep/ .chart {
            height: 374px !important;
            background: rgba(245, 247, 250, 0.5) !important;
          }
        }
        .items-title {
          margin-bottom: 16px;
        }
      }
      .cloud-chart {
        width: 398px;
        margin-left: 16px;
        /deep/ .className {
          height: 374px !important;
          background: rgba(245, 247, 250, 0.5) !important;
        }
        .items-title {
          margin-bottom: 16px;
        }
      }
    }
  }
  .small-box {
    margin-top: 33px;
    width: 1080px;
    border-radius: 4px;
    .small-view {
      padding: 0;
      width: 1080px;
      padding: 34px;
      margin: 0 auto;
      margin-top: 17px;
      position: relative;
      background: #ffffff;
      border-radius: 4px;
      .small-title {
        img {
          position: absolute;
          top: -17px;
          left: 45px;
          width: 85px;
          height: 100px;
          z-index: 2;
        }
        p {
          background: url("../../../assets/images/bg-text.png");
          background-size: 100% 100%;
          position: absolute;
          top: 28px;
          left: 86px;
          padding-left: 48px;
          width: 960px;
          height: 54px;
          line-height: 54px;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          span {
            color: #415ef5;
          }
        }
      }
      .small-list-box {
        padding-top: 70px;
        .small-list {
          // border: 1px dashed #979a9f;
          background: url("../../../assets/images/bg-dotted.png") center / 100%
            100% no-repeat;
          padding: 30px;
          height: auto;
          .small-item {
            .small-item-icon {
              width: 12px;
              height: 12px;
              vertical-align: middle;
            }
            .small-item-title {
              font-weight: 500;
              color: #333333;
              line-height: 22px;
              margin-left: 20px;
            }
            .small-item-text {
              font-weight: 400;
              color: #666666;
              line-height: 22px;
            }
          }
          .small-item-border {
            width: 1px;
            height: 18px;
            border-left: 1px dashed #979797;
            margin-left: 5px;
          }
        }
      }
    }
  }
  .m-bottom {
    margin-bottom: 16px;
  }
  .Card {
    width: 1080px;
    // padding: 10px 20px 20px;
  }
  /deep/ .ivu-card-head {
    padding: 17px 19px;
  }
}
.help_icon {
  /* margin-top: 1px; */
  width: 16px;
  height: 16px;
  // margin-left: 8px;
  color: #999;
  &:hover {
    color: #415ef5;
  }
}
/deep/.m-tips {
  // width: 520px;
  // height: 500px;
  padding: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  h5 {
    font-size: 14px;
    margin-bottom: 16px;
  }
  p {
    display: block;
    margin-bottom: 10px;
    font-size: 12px;
    font-weight: normal;
  }
  h5,
  span {
    color: #333333;
  }
}
</style>

<template>
  <!-- 玫瑰图 -->
  <div class="rose-chart-size" v-loading="loading">
    <div
      class="chart-box"
      ref="chartBox"
      style="width: 100%; height: 100%"
      v-if="echartData.length !== 0"
    ></div>
    <div class="hollow-box">
      <div class="hollow-center">
        <span></span>
      </div>
    </div>
    <dataNull v-if="echartData.length === 0"></dataNull>
  </div>
</template>
<script>
import dataNull from "../chart/dataNull.vue";
// vue文件中引入echarts工具
let echarts = require("echarts");
// 以下的组件按需引入
require("echarts/lib/chart/pie");
require("echarts/lib/component/tooltip"); // tooltip组件
require("echarts/lib/component/title"); // title组件
require("echarts/lib/component/legend"); // legend组件

export default {
  props: [
    "echartData", //柱状图数据，格式：{value:''}
  ],
  data() {
    return {
      //图表
      barChart: {},

      barOption: null,
      loading: true,
      timer: 0,
    };
  },
  computed: {},
  components: {
    dataNull,
  },
  watch: {
    echartData: {
      handler: function (val, oldVal) {
        /**
         * 为解决 各种不同默认数据 以及接口返回数据情况情况做的处理
         * 保证 没有接收到正确数据时，显示loading状态 或者 数据为空
         */
        let oldValStr = JSON.stringify(oldVal);
        let valStr = JSON.stringify(val);
        if (valStr && !oldValStr) {
          this.loading = true;

          this.timer = setTimeout(() => {
            this.drawChart();
            this.loading = false;
            clearTimeout(this.timer);
          }, 3000);
          return;
        } else if (valStr === oldValStr) {
          return;
        } else {
          clearTimeout(this.timer);
        }
        this.loading = true;
        this.drawChart();
        this.loading = false;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    // this.drawChart();
  },
  beforeDestroy() {
    window.addEventListener("resize", () => {
      this.myChart.resize();
    });
  },
  methods: {
    //数据更新
    drawChart: function () {
      if (this.echartData.length === 0) {
        return false;
      }
      this.loading = true;
      //整理玫瑰图配置
      this.barOption = {
        tooltip: {
          trigger: "item",
          formatter: "{b}  {c}家",
        },
        series: [
          {
            type: "pie",
            radius: [18, 100],
            center: ["50%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 0,
            },
            data: this.echartData,
          },
        ],
      };
      // 基于准备好的dom，初始化echarts实例
      this.myChart = echarts.init(this.$refs.chartBox);
      // 绘制图表
      this.myChart.setOption(this.barOption);

      //动态改变图表尺寸（适配）
      setTimeout(() => {
        this.myChart.resize();
        this.loading = false;
      }, 20);
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
  },
};
</script>
<style lang="less">
.rose-chart-size {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  .chart-box {
    width: 100%;
    height: 100%;
  }

  .hollow-box {
    width: 36px;
    height: 36px;
    background: #ffffff;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -18px 0 0 -18px;
    border-radius: 50%;
    .hollow-center {
      width: 26px;
      height: 26px;
      opacity: 0.31;
      border: 2px solid #b8b3da;
      border-radius: 50%;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -13px 0 0 -13px;
      span {
        width: 16px;
        height: 16px;
        background: #f5f3ff;
        border: 2px solid #170887;
        border-radius: 50%;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -8px 0 0 -8px;
      }
    }
  }
}
</style>

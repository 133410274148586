<script>
// import chartConfig from '../lib/chartConfig'
import { use } from "echarts/core";

import ECharts, { THEME_KEY } from "vue-echarts";

import { CanvasRenderer } from "echarts/renderers";

import {
  GaugeChart,
  ScatterChart,
  LineChart,
  BarChart,
  PieChart,
} from "echarts/charts";

import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
  DataZoomComponent,
} from "echarts/components";

use([
  GaugeChart,
  ScatterChart,
  GridComponent,
  LineChart,
  BarChart,
  CanvasRenderer,
  PieChart,
  DataZoomComponent,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  name: "Mcharts",
  components: {
    ECharts,
  },
  props: {
    options: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
  provide: {
    [THEME_KEY]: "light",
  },
};
</script>

<template>
  <div>
    <template v-if="Object.keys(options).length">
      <e-charts
        :option="options"
        class="chart"
        :update-options="{ notMerge: true }"
      />
    </template>
  </div>
</template>

<style lang="less" scoped>
.chart {
  height: 350px;
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "creative-box" },
    [
      _c("div", { staticClass: "creative-demo" }, [
        _c("div", { staticClass: "creative-demo-bg" }, [
          _c(
            "div",
            { staticClass: "creative-demo-btn", on: { click: _vm.toVip } },
            [_vm._v("去升级")]
          ),
          _c("div", { staticClass: "creative-demo-demoBg" }),
        ]),
      ]),
      _c(
        "section",
        { staticClass: "creative-chart" },
        [
          _c(
            "section",
            { staticClass: "creative-chart-one" },
            [
              _c(
                "Card",
                { staticClass: "tags-pic-con" },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      { staticClass: "card-title" },
                      [
                        _vm._v(" 创意素材标签图谱 "),
                        _c(
                          "Poptip",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "right",
                              padding: "0 0",
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "help_icon",
                              attrs: { type: "ios-help-circle-outline" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "m-tips",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("h5", [_vm._v("分析目的：")]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 提取创意素材中的标签结构，并按标签使用率进行分布展示。"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 气泡越大，表示标签的使用率就越高。 "
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("Mcharts", {
                    staticClass: "relation-echart",
                    attrs: { options: _vm.relationOption, type: "cross" },
                  }),
                ],
                2
              ),
              _c(
                "section",
                [
                  _c(
                    "Card",
                    { staticClass: "material-format-con" },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "div",
                          { staticClass: "card-title" },
                          [
                            _vm._v(" 素材版式占比 "),
                            _c(
                              "Poptip",
                              {
                                attrs: {
                                  trigger: "hover",
                                  placement: "left",
                                  padding: "0 0",
                                },
                              },
                              [
                                _c("Icon", {
                                  staticClass: "help_icon",
                                  attrs: { type: "ios-help-circle-outline" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "m-tips",
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("h5", [_vm._v("分析目的：")]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "呈现该行业（产品）分析的创意素材里，使用的视频版式，以及各版式投放表现。"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的平均消费（标签消费总金额/标签数量）"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的点击率（点击数/展示数）*100%"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的转化率（转化数/点击数）*100%"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的转化成本（总花费/转化数)"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "material-format" },
                        [
                          _c("Mcharts", {
                            staticClass: "format-echart",
                            attrs: { options: _vm.formatOption, type: "cross" },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "Card",
                    { staticClass: "material-time-con" },
                    [
                      _c("template", { slot: "title" }, [
                        _c(
                          "div",
                          { staticClass: "card-title" },
                          [
                            _vm._v(" 素材时长占比 "),
                            _c(
                              "Poptip",
                              {
                                attrs: {
                                  trigger: "hover",
                                  placement: "left",
                                  padding: "0 0",
                                },
                              },
                              [
                                _c("Icon", {
                                  staticClass: "help_icon",
                                  attrs: { type: "ios-help-circle-outline" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "m-tips",
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("h5", [_vm._v("分析目的：")]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "呈现该行业（产品）分析的创意素材里，使用的视频时长，以及投放表现。"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的平均消费（标签消费总金额/标签数量）"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的点击率（点击数/展示数）*100%"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的转化率（转化数/点击数）*100%"
                                        ),
                                      ]),
                                    ]),
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "• 标签的转化成本（总花费/转化数)"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "material-time" },
                        [
                          _c("Mcharts", {
                            staticClass: "time-echart",
                            attrs: {
                              options: _vm.timeBarOption,
                              type: "cross",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "section",
            { staticClass: "creative-chart-two" },
            [
              _c(
                "Card",
                { staticClass: "columnar-con" },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      { staticClass: "card-title" },
                      [
                        _vm._v(" 创意类型-占比 "),
                        _c(
                          "Poptip",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "right",
                              padding: "0 0",
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "help_icon",
                              attrs: { type: "ios-help-circle-outline" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "m-tips",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("h5", [_vm._v("分析目的：")]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "呈现该行业（产品）分析的创意素材里，使用的拍摄手法，以及投放表现。"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的平均消费（标签消费总金额/标签数量）"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的点击率（点击数/展示数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的转化率（转化数/点击数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v("• 标签的转化成本（总花费/转化数)"),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("Mcharts", {
                    staticClass: "bar-echart",
                    attrs: { options: _vm.typeBarOption, type: "cross" },
                  }),
                ],
                2
              ),
              _c(
                "Card",
                { staticClass: "columnar-con" },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      { staticClass: "card-title" },
                      [
                        _vm._v(" 创意类型-元素解析 "),
                        _c(
                          "Poptip",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "left",
                              padding: "0 0",
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "help_icon",
                              attrs: { type: "ios-help-circle-outline" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "m-tips",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("h5", [_vm._v("分析目的：")]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 深入解析创意类型的详情元素，并呈现投放表现。"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的平均消费（标签消费总金额/标签数量）"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的点击率（点击数/展示数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的转化率（转化数/点击数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v("• 标签的转化成本（总花费/转化数)"),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _vm._l(_vm.elementEchart, function (h, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "c-element-item-pie" },
                      [
                        _c("h5", [_vm._v(_vm._s(h.name))]),
                        _c("Mcharts", {
                          staticClass: "element-chart",
                          attrs: { options: h.option, type: "pie" },
                        }),
                        _c(
                          "ul",
                          { staticClass: "showText" },
                          _vm._l(h.data, function (item, ind) {
                            return _c("li", { key: ind, staticClass: "item" }, [
                              _c("span", [
                                _c("span", {
                                  staticClass: "circleIcon",
                                  style: { background: item.color },
                                }),
                                _c("span", { staticClass: "item1" }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                                _c("span", { staticClass: "itemLine" }),
                                _c("span", { staticClass: "item2 item11" }, [
                                  _c("label", [_vm._v("转化率：")]),
                                  _vm._v(_vm._s(item.cost)),
                                ]),
                              ]),
                              _c("span", { staticClass: "item2" }, [
                                _c("label", [_vm._v("平均消耗：")]),
                                _vm._v(
                                  "¥" +
                                    _vm._s(item.conver.toLocaleString("en-US"))
                                ),
                              ]),
                            ])
                          }),
                          0
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
              _c(
                "Card",
                { staticClass: "columnar-con" },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      { staticClass: "card-title" },
                      [
                        _vm._v(" 创意元素-音乐 "),
                        _c(
                          "Poptip",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "right",
                              padding: "0 0",
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "help_icon",
                              attrs: { type: "ios-help-circle-outline" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "m-tips",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("h5", [_vm._v("分析目的：")]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "解析音乐元素，呈现该行业（产品）分析的创意素材里，使用的音乐元素，以及各自的投放表现。"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的平均消费（标签消费总金额/标签数量）"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的点击率（点击数/展示数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的转化率（转化数/点击数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v("• 标签的转化成本（总花费/转化数)"),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("Mcharts", {
                    staticClass: "bar-echart",
                    attrs: { options: _vm.musicBarOption, type: "cross" },
                  }),
                ],
                2
              ),
              _c(
                "Card",
                { staticClass: "columnar-con" },
                [
                  _c("template", { slot: "title" }, [
                    _c("div", { staticClass: "card-title" }, [
                      _c(
                        "div",
                        { staticClass: "card-title" },
                        [
                          _vm._v(" 创意元素-转化引导 "),
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "left",
                                padding: "0 0",
                              },
                            },
                            [
                              _c("Icon", {
                                staticClass: "help_icon",
                                attrs: { type: "ios-help-circle-outline" },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "m-tips",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("h5", [_vm._v("分析目的：")]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "呈现该行业（产品）分析的创意素材里，使用的转化引导，以及各自的投放表现。"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的平均消费（标签消费总金额/标签数量）"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的点击率（点击数/展示数）*100%"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的转化率（转化数/点击数）*100%"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的转化成本（总花费/转化数)"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("Mcharts", {
                    staticClass: "bar-echart",
                    attrs: { options: _vm.costBarOption, type: "cross" },
                  }),
                ],
                2
              ),
              _c(
                "Card",
                { staticClass: "columnar-con" },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      { staticClass: "card-title" },
                      [
                        _vm._v(" 创意元素-角色人设 "),
                        _c(
                          "Poptip",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "right",
                              padding: "0 0",
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "help_icon",
                              attrs: { type: "ios-help-circle-outline" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "m-tips",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("h5", [_vm._v("分析目的：")]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "解析角色定位，呈现该行业（产品）分析的创意素材里，使用的角色人设，以及各自的投放表现。"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的平均消费（标签消费总金额/标签数量）"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的点击率（点击数/展示数）*100"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的转化率（转化数/点击数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v("• 标签的转化成本（总花费/转化数)"),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("Mcharts", {
                    staticClass: "bar-echart",
                    attrs: { options: _vm.roleBarOption, type: "cross" },
                  }),
                ],
                2
              ),
              _c(
                "Card",
                { staticClass: "columnar-con" },
                [
                  _c("template", { slot: "title" }, [
                    _c(
                      "div",
                      { staticClass: "card-title" },
                      [
                        _vm._v(" 创意元素-拍摄场景 "),
                        _c(
                          "Poptip",
                          {
                            attrs: {
                              trigger: "hover",
                              placement: "bottom",
                              padding: "0 0",
                            },
                          },
                          [
                            _c("Icon", {
                              staticClass: "help_icon",
                              attrs: { type: "ios-help-circle-outline" },
                            }),
                            _c(
                              "div",
                              {
                                staticClass: "m-tips",
                                attrs: { slot: "content" },
                                slot: "content",
                              },
                              [
                                _c("h5", [_vm._v("分析目的：")]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "解析拍摄场景，呈现该行业（产品）分析的创意素材里，使用的场景类型，以及各自的投放表现。"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的平均消费（标签消费总金额/标签数量）"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的使用率（标签数量/总标签数量之和）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的点击率（点击数/展示数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v(
                                      "• 标签的转化率（转化数/点击数）*100%"
                                    ),
                                  ]),
                                ]),
                                _c("p", [
                                  _c("span", [
                                    _vm._v("• 标签的转化成本（总花费/转化数)"),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                  _c("Mcharts", {
                    staticClass: "bar-echart",
                    attrs: { options: _vm.shootBarOption, type: "cross" },
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c("Card", {
            staticClass: "m-bottom",
            attrs: { title: "内容策略-前3s吸睛方式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("section", { staticClass: "strategy-con" }, [
                      _c(
                        "div",
                        { staticClass: "four-items" },
                        [
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "right",
                                padding: "0 0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "m-tips",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "呈现该行业（产品）分析的创意素材里，使用的吸睛方式，以及各自的投放表现。"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的平均消费（标签消费总金额/标签数量）"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的转化率（转化数/点击数）*100%"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 气泡越大，表示对应的数据指标越高"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "items-title" },
                                [
                                  _vm._v(" 标签表现分析 "),
                                  _c("Icon", {
                                    staticClass: "help_icon",
                                    attrs: { type: "ios-help-circle-outline" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("Mcharts", {
                            staticClass: "four-echart",
                            attrs: { options: _vm.fourTime, type: "cross" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "cloud-chart" },
                        [
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "left",
                                padding: "0 0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "m-tips",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "创意素材中【吸睛】标签的使用量分布，云图中标签的字号越大表示该标签的使用频率越高。"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "items-title" },
                                [
                                  _vm._v(" 标签使用率云图 "),
                                  _c("Icon", {
                                    staticClass: "help_icon",
                                    attrs: { type: "ios-help-circle-outline" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("data-words", {
                            attrs: {
                              chartData: _vm.threeTimeList,
                              width: "398px",
                              height: "370px",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("Card", {
            staticClass: "m-bottom",
            attrs: { title: "内容策略-表现形式" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("section", { staticClass: "strategy-con" }, [
                      _c(
                        "div",
                        { staticClass: "four-items" },
                        [
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "right",
                                padding: "0 0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "m-tips",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "呈现该行业（产品）分析的创意素材里，使用的表达形式，以及各自的投放表现。"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的平均消费（标签消费总金额/标签数量）"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的转化率（转化数/点击数）*100%"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 气泡越大，表示对应的数据指标越高"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "items-title" },
                                [
                                  _vm._v(" 标签表现分析 "),
                                  _c("Icon", {
                                    staticClass: "help_icon",
                                    attrs: { type: "ios-help-circle-outline" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("Mcharts", {
                            staticClass: "four-echart",
                            attrs: { options: _vm.fourTags, type: "cross" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "cloud-chart" },
                        [
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "left",
                                padding: "0 0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "m-tips",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "呈现该行业（产品）分析的创意素材里，使用的表达形式，以及各自的投放表现。"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "items-title" },
                                [
                                  _vm._v(" 标签使用率云图 "),
                                  _c("Icon", {
                                    staticClass: "help_icon",
                                    attrs: { type: "ios-help-circle-outline" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("data-words", {
                            attrs: {
                              chartData: _vm.performList,
                              width: "398px",
                              height: "370px",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _c("Card", {
            attrs: { title: "内容策略-核心卖点" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function () {
                  return [
                    _c("section", { staticClass: "strategy-con" }, [
                      _c(
                        "div",
                        { staticClass: "four-items" },
                        [
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "right",
                                padding: "0 0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "m-tips",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "呈现该行业（产品）分析的创意素材里的核心卖点，以及各自的投放表现。"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的平均消费（标签消费总金额/标签数量）"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 标签的转化率（转化数/点击数）*100%"
                                      ),
                                    ]),
                                  ]),
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "• 气泡越大，表示对应的数据指标越高"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "items-title" },
                                [
                                  _vm._v(" 标签表现分析 "),
                                  _c("Icon", {
                                    staticClass: "help_icon",
                                    attrs: { type: "ios-help-circle-outline" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("Mcharts", {
                            staticClass: "four-echart",
                            attrs: { options: _vm.fourSell, type: "cross" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "cloud-chart" },
                        [
                          _c(
                            "Poptip",
                            {
                              attrs: {
                                trigger: "hover",
                                placement: "left",
                                padding: "0 0",
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "m-tips",
                                  attrs: { slot: "content" },
                                  slot: "content",
                                },
                                [
                                  _c("p", [
                                    _c("span", [
                                      _vm._v(
                                        "创意素材中【核心卖点】标签的使用量分布，云图中标签的字号越大表示该标签的使用频率越高。"
                                      ),
                                    ]),
                                  ]),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "items-title" },
                                [
                                  _vm._v(" 标签使用率云图 "),
                                  _c("Icon", {
                                    staticClass: "help_icon",
                                    attrs: { type: "ios-help-circle-outline" },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                          _c("data-words", {
                            attrs: {
                              chartData: _vm.sallList,
                              width: "398px",
                              height: "370px",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _c("section", { staticClass: "small-box" }, [
        _c("section", { staticClass: "small-view" }, [
          _c("div", { staticClass: "small-title" }, [
            _c("img", {
              attrs: {
                src: "https://fe-1301391939.cos.ap-shanghai.myqcloud.com/shantou/AI-Insight/dc-view.png",
                alt: "",
              },
            }),
            _c("p", [
              _c("span", [_vm._v("某服装品牌")]),
              _vm._v("，在"),
              _c("span", [_vm._v(_vm._s(_vm.yesterday))]),
              _vm._v("投放时间内的"),
              _c("span", [_vm._v("30条")]),
              _vm._v("创意素材，进行创意分析 "),
            ]),
          ]),
          _c("div", { staticClass: "small-list-box" }, [
            _c("div", { staticClass: "small-list" }, [
              _c("div", { staticClass: "small-item" }, [
                _c("img", {
                  staticClass: "small-item-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_serial.png"),
                  },
                }),
                _c("span", { staticClass: "small-item-title" }, [
                  _vm._v("创意目标消群体："),
                ]),
                _c("span", { staticClass: "small-item-text" }, [
                  _vm._v("某服装品牌目标客户为20岁至45岁的消费者"),
                ]),
                _c("div", { staticClass: "small-item-border" }),
              ]),
              _c("div", { staticClass: "small-item" }, [
                _c("img", {
                  staticClass: "small-item-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_serial.png"),
                  },
                }),
                _c("span", { staticClass: "small-item-title" }, [
                  _vm._v("创意目标客户定位："),
                ]),
                _c("span", { staticClass: "small-item-text" }, [
                  _vm._v(
                    "一类应该男性用户，第二类少男少女用户，第三类情侣用户"
                  ),
                ]),
                _c("div", { staticClass: "small-item-border" }),
              ]),
              _c("div", { staticClass: "small-item" }, [
                _c("img", {
                  staticClass: "small-item-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_serial.png"),
                  },
                }),
                _c("span", { staticClass: "small-item-title" }, [
                  _vm._v("明星代言："),
                ]),
                _c("span", { staticClass: "small-item-text" }, [
                  _vm._v(
                    "周杰伦作为“跨时代顶流”，对80、90后消费人群有强大号召力，某服装品牌有望借助周杰伦巨大的粉丝流量，扩大知名度"
                  ),
                ]),
                _c("div", { staticClass: "small-item-border" }),
              ]),
              _c("div", { staticClass: "small-item" }, [
                _c("img", {
                  staticClass: "small-item-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_serial.png"),
                  },
                }),
                _c("span", { staticClass: "small-item-title" }, [
                  _vm._v("创意基础信息："),
                ]),
                _c("span", { staticClass: "small-item-text" }, [
                  _vm._v(
                    "视频样本中，竖版视频占比高达70.8%，明显符合抖音电商信息流投放的视频使用；视频时长基本稳定在60s内"
                  ),
                ]),
                _c("div", { staticClass: "small-item-border" }),
              ]),
              _c("div", { staticClass: "small-item" }, [
                _c("img", {
                  staticClass: "small-item-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_serial.png"),
                  },
                }),
                _c("span", { staticClass: "small-item-title" }, [
                  _vm._v("创意类型："),
                ]),
                _c("span", { staticClass: "small-item-text" }, [
                  _vm._v(
                    "口播类视频占比明显突出达49.7%，其中达人种草类口播以及明星达人混合类混剪更瘦抖音电商用户喜欢，剪辑类以及口播类型的视频，鉴于其卖点突出直接了当的表现形式，深受潜在用户关注。"
                  ),
                ]),
                _c("div", { staticClass: "small-item-border" }),
              ]),
              _c("div", { staticClass: "small-item" }, [
                _c("img", {
                  staticClass: "small-item-icon",
                  attrs: {
                    src: require("@/assets/images/icon/icon_serial.png"),
                  },
                }),
                _c("span", { staticClass: "small-item-title" }, [
                  _vm._v("核心卖点："),
                ]),
                _c("span", { staticClass: "small-item-text" }, [
                  _vm._v(
                    "某服装品牌，创意视频的核心卖点体现服装的普适性、打造高品质服装的同时保证价格亲民总占比禁50%，卖点从“男人的衣柜”到“服装国民品牌”逐步过度"
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
      _c("ModalUpgrade", {
        attrs: { isShow: _vm.isShow },
        on: {
          close: function ($event) {
            _vm.isShow = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
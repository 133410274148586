var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      Object.keys(_vm.options).length
        ? [
            _c("e-charts", {
              staticClass: "chart",
              attrs: {
                option: _vm.options,
                "update-options": { notMerge: true },
              },
            }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
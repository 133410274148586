var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "site-wrap detail-main" },
    [
      _c("Header", {
        attrs: { mode: 2, detailType: "品牌", searchKey: _vm.searchKey },
      }),
      _c("MenuSide", {
        attrs: { activeMenu: _vm.activeMenu },
        on: { isOpen: _vm.isMenuOpen, onselect: _vm.menuSelect },
      }),
      _c(
        "div",
        {
          ref: "scroll",
          staticClass: "main-scroll",
          class: _vm.menuOpen ? "" : "main-scroll-change",
        },
        [
          _vm.cardType !== 33
            ? _c(
                "div",
                { staticClass: "map-size" },
                [
                  _vm.mapMarkers.length != 0
                    ? _c("MapPanelLittle", {
                        attrs: { markers: _vm.mapMarkers },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "main-size", class: { mt0: _vm.cardType === 33 } },
            [
              _vm.cardType !== 33
                ? _c("SummaryCard", {
                    attrs: {
                      isShowTips: !!_vm.componentName,
                      isShowDate: !!_vm.componentName && !_vm.isGuide(),
                      cardType: _vm.cardType,
                      cardInfo: _vm.detailInfo,
                      statistics: _vm.statistics,
                      isVip:
                        _vm.userInfo &&
                        _vm.userInfo.userMember &&
                        (_vm.userInfo.userMember.key === "vip" ||
                          _vm.userInfo.userMember.key === "svip")
                          ? true
                          : false,
                      isSVip:
                        _vm.userInfo &&
                        _vm.userInfo.userMember &&
                        _vm.userInfo.userMember.key === "svip"
                          ? true
                          : false,
                      collectShow: true,
                      claimShow: true,
                      shareShow: true,
                      detailID: _vm.brandID,
                      isShowGsxx: _vm.brandBusinessList.length,
                    },
                    on: {
                      changeDaterange: _vm.changeDaterange,
                      onselect: _vm.menuSelect,
                    },
                  })
                : _vm._e(),
              _vm.isGuide() && _vm.cardType == 2
                ? _c(
                    "section",
                    { staticClass: "guide-section" },
                    [
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/guide_section.png"),
                            width: "644",
                          },
                        }),
                      ]),
                      _c(
                        "Button",
                        {
                          staticClass: "zx-btn",
                          on: { click: _vm.goToConsult },
                        },
                        [_vm._v("去咨询")]
                      ),
                      _c("div", [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/demo_pointer.png"),
                            width: "464",
                          },
                        }),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.cardType == 2
                ? [
                    _c(
                      "div",
                      {
                        staticClass: "content-item",
                        attrs: { id: "CreativityPut" },
                      },
                      [
                        _c("div", { staticClass: "item-title" }, [
                          _vm._v("创意投放"),
                        ]),
                        _c("CreativityPut", {
                          attrs: {
                            brandId: _vm.brandID,
                            putData: _vm.putData,
                            componentName: _vm.componentName,
                            exposureData: _vm.exposureData,
                            clickData: _vm.clickData,
                            daterangeData: _vm.daterangeData,
                            filterDate: _vm.daterangeData,
                          },
                        }),
                      ],
                      1
                    ),
                    _c("CreativityVideo", {
                      attrs: {
                        id: "CreativityVideo",
                        brandId: _vm.brandID,
                        putData: _vm.putData,
                        isGuide: _vm.isGuide(),
                        componentName: _vm.componentName,
                        exposureData: _vm.exposureData,
                        clickData: _vm.clickData,
                        daterangeData: _vm.daterangeData,
                        filterDate: _vm.daterangeData,
                      },
                    }),
                    _c(
                      "div",
                      {
                        staticClass: "content-item",
                        attrs: { id: "CreativityAscribe" },
                      },
                      [
                        _c("div", { staticClass: "item-title" }, [
                          _vm._v(" 创意归因"),
                          _c("img", {
                            attrs: {
                              src: require("@/assets/images/title_icon.png"),
                              width: "29",
                              height: "12",
                            },
                          }),
                        ]),
                        _c("CreativityAscribe", {
                          attrs: {
                            brandId: _vm.brandID,
                            putData: _vm.putData,
                            componentName: _vm.componentName,
                            exposureData: _vm.exposureData,
                            clickData: _vm.clickData,
                            daterangeData: _vm.daterangeData,
                            filterDate: _vm.daterangeData,
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                : _vm.cardType === 33
                ? [
                    _c(
                      "div",
                      {
                        ref: "offlineAnalysis",
                        staticClass: "content-item",
                        attrs: { id: "offlineAnalysis" },
                      },
                      [_c("offline-analysis")],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "content-item anchor-box9",
                        attrs: { id: "onlineAnalysis" },
                      },
                      [_c("online-analysis")],
                      1
                    ),
                  ]
                : [
                    _c(
                      "div",
                      { staticClass: "content-item", attrs: { id: "mendian" } },
                      [
                        _c("div", { staticClass: "item-title" }, [
                          _vm._v("门店分布"),
                        ]),
                        _c("div", { staticClass: "chart-box1" }, [
                          _c("div", { staticClass: "chart-title" }, [
                            _vm._v("各线城市门店分布占比"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "chart-size" },
                            [
                              _c("RoseChart", {
                                attrs: { echartData: _vm.echartsData1 },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "chart-box2" }, [
                          _c("div", { staticClass: "chart-title" }, [
                            _vm._v("主要城市门店分布Top榜"),
                          ]),
                          _c(
                            "div",
                            { staticClass: "chart-size" },
                            [
                              _c("BarChart", {
                                attrs: {
                                  echartData: _vm.echartsData2,
                                  barWidth: 28,
                                  barColor: _vm.barColorCon2,
                                  textColor: _vm.textColorCon2,
                                  yUnit: "家",
                                  echartSize: _vm.echartSizeCon2,
                                  maxLength: 6,
                                  isHideY: false,
                                  isHideYline: false,
                                  isTopData: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.mendianLoadingStatus,
                                expression: "mendianLoadingStatus",
                              },
                            ],
                            staticClass: "chart-box3",
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "chart-title chart-title2" },
                              [
                                _vm._v(" 品牌门店列表 "),
                                _c(
                                  "div",
                                  { staticClass: "chart-screen" },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.cityName) + "在营门店"
                                    ),
                                    _c("span", { staticClass: "screen-num" }, [
                                      _vm._v(_vm._s(_vm.total)),
                                    ]),
                                    _vm._v("家 "),
                                    _c("Cascader", {
                                      staticStyle: { width: "160px" },
                                      attrs: {
                                        disabled: !_vm.isLogin(),
                                        data: _vm.cityList,
                                        filterable: "",
                                        placeholder: "全国",
                                        clearable: "",
                                      },
                                      on: { "on-change": _vm.cityScreenChange },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { ref: "tablebox", staticClass: "table-size" },
                              [
                                _c("Table", {
                                  staticClass: "task-status-content",
                                  attrs: {
                                    columns: _vm.columns,
                                    data: _vm.listData,
                                    height: 400,
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "serialNumber",
                                      fn: function ({ index }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                (_vm.pageNum - 1) *
                                                  _vm.pageSize +
                                                  index +
                                                  1
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "operation",
                                      fn: function ({ row }) {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass: "operation-btn",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.viewDetail(row)
                                                },
                                              },
                                            },
                                            [_vm._v("查看详情")]
                                          ),
                                        ]
                                      },
                                    },
                                  ]),
                                }),
                              ],
                              1
                            ),
                            _vm.isLogin()
                              ? [
                                  _vm.listData.length != 0
                                    ? _c(
                                        "div",
                                        { staticClass: "page-size" },
                                        [
                                          _c(
                                            "Page",
                                            {
                                              attrs: {
                                                "prev-text": "",
                                                "next-text": "",
                                                "class-name": "dt-pager",
                                                "show-total": true,
                                                total: _vm.total,
                                                current: _vm.pageNum,
                                                "page-size": _vm.pageSize,
                                                "show-sizer": "",
                                              },
                                              on: {
                                                "on-change": _vm.changePage,
                                                "on-page-size-change":
                                                  _vm.changePageSize,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " 共" +
                                                  _vm._s(_vm.total) +
                                                  "条 "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              : [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "visitor-login g-brand-detail-login",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "visitor-login-btn",
                                          attrs: { role: "button" },
                                          on: { click: _vm.gotoLogin },
                                        },
                                        [
                                          _vm._v(" 登录查看全部信息"),
                                          _c("img", {
                                            staticClass: "visitor-login-icon",
                                            attrs: {
                                              src: require("@/assets/images/double-arror.png"),
                                              width: "14",
                                              height: "14",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "content-item", attrs: { id: "juke" } },
                      [
                        _c(
                          "div",
                          { staticClass: "item-title" },
                          [
                            _vm._v(" 周边客群 "),
                            _c(
                              "Poptip",
                              {
                                attrs: {
                                  trigger: "hover",
                                  placement: "right",
                                  padding: "0 0",
                                },
                              },
                              [
                                _c("Icon", {
                                  staticClass: "help_icon",
                                  attrs: { type: "ios-help-circle-outline" },
                                }),
                                _c(
                                  "div",
                                  {
                                    staticClass: "m-tips",
                                    attrs: { slot: "content" },
                                    slot: "content",
                                  },
                                  [
                                    _c("p", [
                                      _c("span", [
                                        _vm._v(
                                          "门店所在区域周边500米的客群分析"
                                        ),
                                      ]),
                                    ]),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c("Icon", {
                              staticClass: "g-vip-icon",
                              attrs: { type: "ios-lock-outline" },
                            }),
                          ],
                          1
                        ),
                        _c("section", { staticClass: "g-vip-section" }, [
                          _c(
                            "div",
                            {
                              staticClass: "g-vip-btn",
                              attrs: { role: "button" },
                              on: { click: _vm.toVip },
                            },
                            [_vm._v("去升级")]
                          ),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("@/assets/images/demo_pointer.png"),
                                width: "464",
                              },
                            }),
                          ]),
                          _c("div", { staticClass: "diliver" }),
                        ]),
                        _c("CircumCustomer", {
                          attrs: { id: _vm.brandID, identify: "品牌" },
                          on: { getTotal: _vm.getTotal },
                        }),
                        _c("div", { staticClass: "chart-box4" }, [
                          _c(
                            "div",
                            { staticClass: "chart-title" },
                            [
                              _vm._v(" 聚客类型 "),
                              _c(
                                "Poptip",
                                {
                                  attrs: {
                                    trigger: "hover",
                                    placement: "right",
                                    padding: "0 0",
                                  },
                                },
                                [
                                  _c("Icon", {
                                    staticClass: "help_icon",
                                    attrs: { type: "ios-help-circle-outline" },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "m-tips",
                                      attrs: { slot: "content" },
                                      slot: "content",
                                    },
                                    [
                                      _c("p", [
                                        _c("span", [
                                          _vm._v(
                                            "门店所在区域周边500米的聚客点主力类型"
                                          ),
                                        ]),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "chart-size" },
                            [
                              _c("BarChart", {
                                attrs: {
                                  echartData: _vm.echartsData3,
                                  barWidth: 28,
                                  barColor: _vm.barColorCon2,
                                  textColor: _vm.textColorCon2,
                                  yUnit: "%",
                                  echartSize: _vm.echartSizeCon3,
                                  maxLength: 8,
                                  isHideY: true,
                                  isHideYline: true,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "demo-item", attrs: { id: "mddf" } },
                      [
                        _c("Icon", {
                          staticClass: "g-vip-icon2",
                          attrs: { type: "ios-lock-outline" },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/demo/detail_mddf2.png"),
                            alt: "门店到访",
                            width: "1128",
                            height: "3198",
                          },
                        }),
                        _c("div", {
                          staticClass: "demo-btn",
                          on: { click: _vm.vipClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "demo-item", attrs: { id: "kehu" } },
                      [
                        _c("Icon", {
                          staticClass: "g-vip-icon3",
                          attrs: { type: "ios-lock-outline" },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/demo/detail_khhx2.png"),
                            alt: "客户画像",
                            width: "1128",
                            height: "3479",
                          },
                        }),
                        _c("div", {
                          staticClass: "demo-btn",
                          on: { click: _vm.vipClick },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "demo-item", attrs: { id: "mdxl" } },
                      [
                        _c("Icon", {
                          staticClass: "g-vip-icon2",
                          attrs: { type: "ios-lock-outline" },
                        }),
                        _c("img", {
                          attrs: {
                            src: require("../../assets/images/demo/detail_mdxl2.png"),
                            alt: "门店销量",
                            width: "1128",
                            height: "2449",
                          },
                        }),
                        _c("div", {
                          staticClass: "demo-btn",
                          on: { click: _vm.vipClick },
                        }),
                      ],
                      1
                    ),
                  ],
              _vm.cardType !== 33
                ? [
                    _vm.brandBusinessList.length
                      ? _c(
                          "div",
                          {
                            staticClass: "content-item",
                            attrs: { id: "gsxx" },
                          },
                          [
                            _c("div", { staticClass: "item-title" }, [
                              _vm.brandBusinessList.length &&
                              _vm.brandBusinessList[
                                _vm.brandBusinessList.length - 1
                              ]
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.brandBusinessList[
                                        _vm.brandBusinessList.length - 1
                                      ],
                                      alt: "",
                                      width: "34",
                                      height: "34",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" 品牌企业工商信息 "),
                            ]),
                            _c(
                              "div",
                              { staticClass: "brand-business" },
                              _vm._l(_vm.brandBusiness, function (item, index) {
                                return _c(
                                  "p",
                                  {
                                    key: item,
                                    class: {
                                      with100:
                                        item === "注册地址" ||
                                        item === "经营范围" ||
                                        item === "数据来源",
                                    },
                                  },
                                  [
                                    index === _vm.brandBusiness.length - 1
                                      ? [
                                          _c(
                                            "div",
                                            { staticClass: "data-source" },
                                            [
                                              _c("span", [
                                                _vm._v("数据来源："),
                                              ]),
                                              _c("img", {
                                                attrs: {
                                                  width: "60",
                                                  height: "20",
                                                  src: require("../../assets/images/aiqicha-logo.png"),
                                                  alt: "",
                                                },
                                              }),
                                              _vm.brandBusinessList[index]
                                                ? [
                                                    _c("span", {
                                                      staticClass: "line",
                                                    }),
                                                    _c(
                                                      "a",
                                                      {
                                                        attrs: {
                                                          target: "_blank",
                                                          href: _vm
                                                            .brandBusinessList[
                                                            index
                                                          ],
                                                        },
                                                      },
                                                      [
                                                        _vm._v("查看更多 "),
                                                        _c("Icon", {
                                                          attrs: {
                                                            custom: "icon-more",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                : _vm._e(),
                                            ],
                                            2
                                          ),
                                        ]
                                      : [
                                          _c("span", [
                                            _vm._v(_vm._s(item) + "："),
                                          ]),
                                          item === "营业期限"
                                            ? [
                                                !_vm.brandBusinessList[index]
                                                  ? [_vm._v("无固定期限")]
                                                  : [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .brandBusinessList[
                                                              index
                                                            ]
                                                          ) +
                                                          " ~ "
                                                      ),
                                                      !_vm.brandBusinessList[
                                                        _vm.brandBusinessList
                                                          .length - 2
                                                      ]
                                                        ? [
                                                            _vm._v(
                                                              " 无固定期限 "
                                                            ),
                                                          ]
                                                        : [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm
                                                                    .brandBusinessList[
                                                                    _vm
                                                                      .brandBusinessList
                                                                      .length -
                                                                      2
                                                                  ]
                                                                ) +
                                                                " "
                                                            ),
                                                          ],
                                                    ],
                                              ]
                                            : [
                                                _c(
                                                  "div",
                                                  { staticClass: "desc" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.brandBusinessList[
                                                            index
                                                          ] || "-"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                        ],
                                  ],
                                  2
                                )
                              }),
                              0
                            ),
                          ]
                        )
                      : _vm._e(),
                    _c("div", { staticClass: "content-item" }, [
                      _c("div", { staticClass: "item-title" }, [
                        _vm._v("相似推荐"),
                      ]),
                      _c(
                        "div",
                        { staticClass: "similar-size" },
                        [
                          _c("Similar", {
                            attrs: { listData: _vm.similarData },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]
                : _vm._e(),
              _c("div", { staticClass: "page-bottom" }),
              _c("ModalUpgrade", {
                attrs: { isShow: _vm.isShow },
                on: {
                  close: function ($event) {
                    _vm.isShow = false
                  },
                },
              }),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
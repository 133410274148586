<template>
  <div class="creativityVideo-box">
    <div class="creativityVideo-title">
      <div>创意视频（累计）</div>
      <a
        class="creative-dz"
        href="https://promo.clipworks.com"
        role="button"
        target="_blank"
      >
        创意定制
        <img
          class="creative-icon"
          :src="require('@/assets/images/icon/goto_icon.png')"
          width="14"
          height="14"
        />
      </a>
    </div>
    <div class="creativityVideo-body">
      <!-- 搜索筛选 -->
      <div class="search-box">
        <div
          class="search-item"
          :class="{ 'search-item-hide': isIndustryOpen }"
        >
          <div class="search-label">流量平台：</div>
          <CheckboxGroup
            class="search-check-box"
            v-model="channelIds"
            @on-change="channelChange"
          >
            <Checkbox
              v-for="item in channelList"
              :key="item.id"
              :label="item.id"
              :disabled="!videoList.length && isGuide"
            >
              {{ item.name }}
            </Checkbox>
          </CheckboxGroup>
          <div class="search-open" @click="isIndustryOpen = !isIndustryOpen">
            {{ isIndustryOpen ? "展开" : "收起" }}
            <Icon :type="isIndustryOpen ? 'ios-arrow-down' : 'ios-arrow-up'" />
          </div>
        </div>
        <div class="search-item" :class="{ 'search-item-hide': isMediaOpen }">
          <div class="search-label">广告媒体：</div>
          <CheckboxGroup
            class="search-check-box"
            v-model="mediaIds"
            @on-change="mediaChange"
          >
            <Checkbox
              v-for="item in mediaList"
              :key="item.id"
              :label="item.id"
              :disabled="!videoList.length && isGuide"
            >
              {{ item.name }}
            </Checkbox>
          </CheckboxGroup>
          <div class="search-open" @click="isMediaOpen = !isMediaOpen">
            {{ isMediaOpen ? "展开" : "收起" }}
            <Icon :type="isMediaOpen ? 'ios-arrow-down' : 'ios-arrow-up'" />
          </div>
        </div>
        <div class="search-item">
          <div class="search-label">视频版式：</div>
          <RadioGroup
            class="radio-check-box"
            v-model="videoLayout"
            @on-change="videoLayoutChange"
          >
            <Radio
              v-for="item in videoLayoutList"
              :key="item.id"
              :label="item.id"
              :disabled="!videoList.length && isGuide"
            >
              {{ item.name }}
            </Radio>
          </RadioGroup>
          <div class="search-open"></div>
        </div>
      </div>
      <!-- 视频展示列表 -->
      <div class="video-box">
        <Spin fix v-if="loading"></Spin>
        <div class="video-box-header">
          <div class="video-header-title">
            <template v-if="total > 0">
              视频数量：{{ total }}
            </template>
            <template v-else-if="!isGuide">
              视频数量：0
            </template>
            <template v-else="!videoList.length && isGuide">
              视频数量：1200
            </template>
          </div>
          <ul class="video-header-tool">
            <li
              class="video-header-tool-item"
              v-for="(item, index) in soltTypeList"
              @click="videoList.length ? clickSoltItem(index) : () => {}"
              :class="{ 'custom-disabled': !videoList.length }"
              :key="'slot-' + index"
            >
              <div
                class="video-header-tool-label"
                :class="{ selected: item.isSelected }"
              >
                {{ item.label }}
              </div>
              <div class="video-header-tool-solt" v-if="item.isSolt">
                <i
                  class="video-header-tool-ascending"
                  :class="{ ascending: item.soltType === 'asc' }"
                />
                <i
                  class="video-header-tool-descending"
                  :class="{ descending: item.soltType === 'desc' }"
                />
              </div>
            </li>
          </ul>
        </div>
        <ul class="video-list" v-show="videoList.length">
          <li
            class="video-list-item"
            v-for="(item, index) in videoList"
            :key="item.id"
          >
            <div
              class="viedeo-item-content"
              @mouseenter="play(index)"
              @mouseleave="pause(index)"
            >
              <div
                class="viedeo-item-content-bg"
                :style="{ 'background-image': 'url(' + item[4] + ')' }"
              ></div>
              <span class="viedeo-item-content-industry">{{ item[1] }}</span>
              <img
                class="viedeo-item-content-play"
                :src="require('@/assets/images/icon/icon_play.png')"
                alt=""
              />
              <img class="viedeo-item-content-poster" :src="item[4]" alt="" />
              <video
                :src="item[3]"
                :ref="'video-' + index"
                controls
                controlslist="nodownload"
                muted="true"
                class="viedeo-item-content-video"
              ></video>
              <img
                class="viedeo-item-content-muted"
                :src="
                  require(`@/assets/images/icon/icon_${
                    hasPlay ? 'unmuted' : 'muted'
                  }.png`)
                "
                alt=""
                @click.stop="playVideo(index)"
              />
            </div>
            <div class="viedeo-item-footer">
              <div class="viedeo-item-footer-title-box">
                <Poptip trigger="hover" title="" :content="item[0] || '--'">
                  <div class="viedeo-item-footer-title">
                    {{ item[0] || "--" }}
                  </div>
                </Poptip>
                <div>{{ timeFormat(item[9], "Y-m-d") }}</div>
              </div>
              <div class="viedeo-item-footer-exhibition-box">
                <div class="viedeo-item-footer-exhibition-item">
                  <Poptip
                    trigger="hover"
                    title=""
                    :content="'点击数: ' + numFormat(item[6])"
                  >
                    <div class="viedeo-item-footer-exhibition">
                      <img
                        :src="require('@/assets/images/icon/icon_click.png')"
                        alt=""
                      />
                      <span>{{ numberFormat(item[6]) }}</span>
                    </div>
                  </Poptip>
                </div>
                <div class="viedeo-item-footer-exhibition-item">
                  <Poptip
                    trigger="hover"
                    title=""
                    :content="'展示数: ' + numFormat(item[7])"
                  >
                    <div class="viedeo-item-footer-exhibition">
                      <img
                        :src="require('@/assets/images/icon/icon_show.png')"
                        alt=""
                      />
                      <span>{{ numberFormat(item[7]) }}</span>
                    </div>
                  </Poptip>
                </div>
                <!-- 千里目暂时不需要转化数 -->
                <!-- <div>
                  <Poptip
                    trigger="hover"
                    title=""
                    :content="'转化数: ' + numFormat(item[5])"
                  >
                    <div class="viedeo-item-footer-exhibition">
                      <img
                        :src="require('@/assets/images/icon/icon_conversion.png')"
                        alt=""
                      />
                      <span>{{ numberFormat(item[5]) }}</span>
                    </div>
                  </Poptip>
                </div> -->
              </div>
              <div class="viedeo-item-footer-media-box">
                <span><img :src="item[2]" alt="" /></span>
              </div>
            </div>
          </li>
        </ul>
        <div style="margin-bottom: 25px" v-if="!videoList.length && isGuide">
          <img
            :src="require('@/assets/images/videolist_default.jpg')"
            width="100%"
          />
        </div>
        <!-- <div class="video-no-date" v-show="!videoList.length">
          <NoData></NoData>
        </div> -->
        <Page
          v-if="videoList.length"
          class="video-page"
          show-total
          show-sizer
          show-elevator
          :page-size-opts="[20, 40, 60, 80, 100]"
          placement="top"
          :total="total"
          :current="pages.page"
          :page-size="pages.size"
          @on-change="pageChange"
          @on-page-size-change="pageSizeChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
// import NoData from "@/components/NoData.vue";
import { timeFormat, numFormat, numberFormat } from "js/utils.js";
import { GetCommonInfo } from "@/service";
export default {
  name: "creativity-video",
  data() {
    return {
      // 流量平台搜索集合
      channelList: [
        {
          id: 102,
          name: "腾讯广告",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/e86f1736-f41c-11ea-87b9-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 105,
          name: "巨量引擎",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/e8cb8552-f41c-11ea-917a-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 209,
          name: "快手",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ea38b5a8-f41c-11ea-b951-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 204,
          name: "Bilibili 必选",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ebd04568-f41c-11ea-8814-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 147,
          name: "小红书",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ec1ecbca-f41c-11ea-a9e9-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 123,
          name: "知乎",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ebf8bdac-f41c-11ea-9956-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 110,
          name: "百度信息流",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/e91a4b70-f41c-11ea-b785-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 112,
          name: "百度百青藤",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ef1d6bde-f41c-11ea-be64-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 120,
          name: "百度百通",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ef41c424-f41c-11ea-b070-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 107,
          name: "阿里汇川",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/e9c2bb8c-f41c-11ea-af2d-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 601,
          name: "淘宝 Tanx",
          category: "DSP平台",
          icon: "https://file3.clipworks.com/f3b4d262-f41c-11ea-aa6a-0c7a15549768.jpg",
          status: 2,
        },
        {
          id: 604,
          name: "京东直投",
          category: "DSP平台",
          icon: "https://file3.clipworks.com/f40d009e-f41c-11ea-853d-0c7a15549768.jpg",
          status: 2,
        },
        {
          id: 111,
          name: "微博粉丝通",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/e9e4f690-f41c-11ea-b0fa-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 113,
          name: "新浪扶翼",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ed118c3a-f41c-11ea-8eed-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 117,
          name: "搜狐汇算",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/efae7ee6-f41c-11ea-9d0b-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 118,
          name: "搜狗星耀",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ed931ce8-f41c-11ea-9614-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 108,
          name: "网易易效",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ec43334a-f41c-11ea-acdb-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 114,
          name: "360点睛",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ec934bca-f41c-11ea-87b9-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 201,
          name: "优酷睿视",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/eaa160e6-f41c-11ea-93ce-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 115,
          name: "一点资讯",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/f0983f66-f41c-11ea-8fc3-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 116,
          name: "凤凰凤羽",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ed6c3dfa-f41c-11ea-8676-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 119,
          name: "美柚女人通",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ee86e20a-f41c-11ea-84e3-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 122,
          name: "WiFi 万能钥匙",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/edb36cd8-f41c-11ea-83f2-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 127,
          name: "汽车之家",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ee1d47e2-f41c-11ea-9906-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 129,
          name: "趣头条",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/eaef274c-f41c-11ea-b2dd-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 138,
          name: "懂球帝",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/eeabc174-f41c-11ea-87a4-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 141,
          name: "虎扑",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/f187081e-f41c-11ea-867c-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 146,
          name: "悦头条",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/f1ad3e2e-f41c-11ea-8d79-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 202,
          name: "奇麟神算",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ea77e612-f41c-11ea-a754-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 203,
          name: "芒果 TV",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/f0eeeac8-f41c-11ea-9abc-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 213,
          name: "斗鱼",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/ee658ca8-f41c-11ea-b67d-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 215,
          name: "央视影音",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/f1110b98-f41c-11ea-8b51-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 502,
          name: "OPPO",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/f20aaaa8-f41c-11ea-89a8-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 503,
          name: "vivo",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/f2341a64-f41c-11ea-a47e-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 504,
          name: "小米",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/f25f6dec-f41c-11ea-91a2-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 106,
          name: "猎豹广告",
          category: "自有流量平台",
          icon: "https://file3.clipworks.com/edf7f5a2-f41c-11ea-9fa2-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 607,
          name: "易通天下",
          category: "DSP平台",
          icon: "https://file3.clipworks.com/f4514d0c-f41c-11ea-b5cf-0c7a15549768.jpg",
          status: 2,
        },
        {
          id: 713,
          name: "4399游戏盒",
          category: "应用商店",
          icon: "https://file3.clipworks.com/f3844b62-f41c-11ea-a446-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 1401,
          name: "UnityAds",
          category: "广告网络",
          icon: "https://file3.clipworks.com/f2aa2fac-f41c-11ea-a2a8-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 1402,
          name: "Vungle",
          category: "广告网络",
          icon: "https://file3.clipworks.com/f2c6f4a8-f41c-11ea-8467-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 1403,
          name: "Applovin",
          category: "广告网络",
          icon: "https://file3.clipworks.com/f2e984e4-f41c-11ea-b7fe-0c7a15549768.jpg",
          status: 1,
        },
        {
          id: 1404,
          name: "Adcolony",
          category: "广告网络",
          icon: "https://file3.clipworks.com/f35528c0-f41c-11ea-bb80-0c7a15549768.jpg",
          status: 1,
        },
      ],
      // 选中的流量平台ID集合
      channelIds: [],
      // 是否展示更多流量平台搜索内容
      isIndustryOpen: true,
      // 广告媒体搜索集合
      mediaList: [
        {
          id: 4,
          name: "今日头条",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9c6d0fe8-f41e-11ea-bd60-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 105, 604, 607]",
        },
        {
          id: 8,
          name: "抖音短视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9efe5274-f41e-11ea-b43f-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 105, 604, 607]",
        },
        {
          id: 7,
          name: "抖音火山版",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9f1ed0b8-f41e-11ea-acd0-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 105, 604, 607]",
        },
        {
          id: 6,
          name: "西瓜视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9f3c3abe-f41e-11ea-a944-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 105, 604, 607]",
        },
        {
          id: 69,
          name: "穿山甲联盟",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a24d8e12-f41e-11ea-8a81-0c7a15549768.jpg",
          status: 1,
          channels: "[105]",
        },
        {
          id: 68,
          name: "快手",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9f5f0aee-f41e-11ea-bb0d-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 209, 604]",
        },
        {
          id: 1,
          name: "微信",
          category: "社交媒体",
          icon: "https://file3.clipworks.com/9bad75ba-f41e-11ea-b647-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102]",
        },
        {
          id: 54,
          name: "QQ",
          category: "社交媒体",
          icon: "https://file3.clipworks.com/9bf75e9a-f41e-11ea-aacb-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102, 607]",
        },
        {
          id: 2,
          name: "QQ 空间",
          category: "社交媒体",
          icon: "https://file3.clipworks.com/9c11f99a-f41e-11ea-acca-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102]",
        },
        {
          id: 3,
          name: "QQ 浏览器",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a4f031fe-f41e-11ea-ad83-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102, 607]",
        },
        {
          id: 56,
          name: "腾讯视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9f9d222c-f41e-11ea-ba9c-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102]",
        },
        {
          id: 57,
          name: "腾讯新闻",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9cca96fa-f41e-11ea-86d4-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102]",
        },
        {
          id: 101,
          name: "腾讯手机管家",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a52b721a-f41e-11ea-a4af-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102, 607]",
        },
        {
          id: 48,
          name: "Bilibili",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9fe5c9d8-f41e-11ea-99e1-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 204, 604]",
        },
        {
          id: 38,
          name: "知乎",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9e030412-f41e-11ea-8dc7-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 123, 604]",
        },
        {
          id: 100,
          name: "小红书",
          category: "社交媒体",
          icon: "https://file3.clipworks.com/9c4e0bba-f41e-11ea-ab79-0c7a15549768.jpg",
          status: 1,
          channels: "[147]",
        },
        {
          id: 19,
          name: "手机百度",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9c8e7962-f41e-11ea-a827-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 604, 110, 607]",
        },
        {
          id: 18,
          name: "百度贴吧",
          category: "社交媒体",
          icon: "https://file3.clipworks.com/9c2bd22c-f41e-11ea-a12a-0c7a15549768.jpg",
          status: 1,
          channels: "[110]",
        },
        {
          id: 21,
          name: "百度视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a022d79c-f41e-11ea-9c51-0c7a15549768.jpg",
          status: 1,
          channels: "[112, 601, 102, 105, 604, 607]",
        },
        {
          id: 82,
          name: "好看视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a0794d98-f41e-11ea-8e57-0c7a15549768.jpg",
          status: 1,
          channels: "[112, 604]",
        },
        {
          id: 20,
          name: "百度浏览器",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a11d6934-f41e-11ea-97fb-0c7a15549768.jpg",
          status: 1,
          channels: "[110]",
        },
        {
          id: 80,
          name: "百青藤联盟",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a28880e8-f41e-11ea-a71e-0c7a15549768.jpg",
          status: 1,
          channels: "[112]",
        },
        {
          id: 67,
          name: "百度手机助手",
          category: "应用商店",
          icon: "https://file3.clipworks.com/a4d40bb4-f41e-11ea-b841-0c7a15549768.jpg",
          status: 1,
          channels: "[120]",
        },
        {
          id: 22,
          name: "微博",
          category: "社交媒体",
          icon: "https://file3.clipworks.com/9bdaf0e6-f41e-11ea-b7f1-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 604, 111]",
        },
        {
          id: 23,
          name: "新浪新闻",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9d1f13ee-f41e-11ea-8af7-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 113, 604]",
        },
        {
          id: 24,
          name: "新浪体育",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9d996d36-f41e-11ea-abf0-0c7a15549768.jpg",
          status: 1,
          channels: "[113]",
        },
        {
          id: 25,
          name: "新浪财经",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9de81e5a-f41e-11ea-8ef5-0c7a15549768.jpg",
          status: 1,
          channels: "[113, 604]",
        },
        {
          id: 43,
          name: "优酷",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9fcab324-f41e-11ea-b91c-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 201, 604]",
        },
        {
          id: 44,
          name: "土豆视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a0bd244a-f41e-11ea-ab02-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 201, 107, 604]",
        },
        {
          id: 45,
          name: "爱奇艺",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/9f803f36-f41e-11ea-9287-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 202, 604]",
        },
        {
          id: 47,
          name: "芒果 TV",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a097c1b8-f41e-11ea-a038-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 203, 604, 607]",
        },
        {
          id: 9,
          name: "猎豹浏览器",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a2135228-f41e-11ea-acf6-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 106, 604]",
        },
        {
          id: 10,
          name: "猎豹清理大师",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a2328bfa-f41e-11ea-bcd5-0c7a15549768.jpg",
          status: 1,
          channels: "[102, 601, 106, 604]",
        },
        {
          id: 12,
          name: "UC 浏览器",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a10151a4-f41e-11ea-9e15-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 107]",
        },
        {
          id: 13,
          name: "UC 头条",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9cad5bdc-f41e-11ea-b04c-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 107]",
        },
        {
          id: 14,
          name: "网易新闻",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9ce7a6c6-f41e-11ea-8739-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 108, 607, 604]",
        },
        {
          id: 17,
          name: "网易邮箱大师",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a1f3ab98-f41e-11ea-acec-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 108, 604, 607]",
        },
        {
          id: 27,
          name: "一点资讯",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9d800c9e-f41e-11ea-a458-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 115, 604]",
        },
        {
          id: 28,
          name: "凤凰新闻",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9d022662-f41e-11ea-bf64-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 116, 604]",
        },
        {
          id: 30,
          name: "凤凰视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a03efb0a-f41e-11ea-8d73-0c7a15549768.jpg",
          status: 1,
          channels: "[116]",
        },
        {
          id: 33,
          name: "搜狐视频",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a0e10e86-f41e-11ea-87d7-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 117]",
        },
        {
          id: 31,
          name: "搜狐新闻",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9d5b620a-f41e-11ea-89ed-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 604, 117]",
        },
        {
          id: 34,
          name: "搜狗浏览器",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a138959c-f41e-11ea-95e6-0c7a15549768.jpg",
          status: 1,
          channels: "[118]",
        },
        {
          id: 35,
          name: "搜狗搜索",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9dc017c0-f41e-11ea-8bd7-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 118]",
        },
        {
          id: 63,
          name: "网易公开课",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a05bbe8a-f41e-11ea-af7f-0c7a15549768.jpg",
          status: 1,
          channels: "[108, 604]",
        },
        {
          id: 36,
          name: "今日十大热点",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9d3b1562-f41e-11ea-b671-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 118]",
        },
        {
          id: 37,
          name: "WiFi 万能钥匙",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a1c5683a-f41e-11ea-9866-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 105, 122, 604, 607]",
        },
        {
          id: 39,
          name: "汽车之家",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9ea7c8ae-f41e-11ea-9854-0c7a15549768.jpg",
          status: 1,
          channels: "[127]",
        },
        {
          id: 42,
          name: "趣头条",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9e20a880-f41e-11ea-9822-0c7a15549768.jpg",
          status: 1,
          channels: "[129, 102, 601, 604, 607]",
        },
        {
          id: 58,
          name: "看点快报",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9e6fa13a-f41e-11ea-a559-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 102]",
        },
        {
          id: 59,
          name: "懂球帝",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9e8b79f4-f41e-11ea-b5df-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 138, 604]",
        },
        {
          id: 60,
          name: "虎扑",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9ec615b6-f41e-11ea-8fde-0c7a15549768.jpg",
          status: 1,
          channels: "[102, 105, 604, 141, 607]",
        },
        {
          id: 61,
          name: "优量联盟",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a268c33a-f41e-11ea-84c6-0c7a15549768.jpg",
          status: 1,
          channels: "[102]",
        },
        {
          id: 62,
          name: "央视影音",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a50e86d4-f41e-11ea-801e-0c7a15549768.jpg",
          status: 1,
          channels: "[604, 215]",
        },
        {
          id: 64,
          name: "4399游戏盒",
          category: "应用商店",
          icon: "https://file3.clipworks.com/a4b59e88-f41e-11ea-a844-0c7a15549768.jpg",
          status: 1,
          channels: "[713]",
        },
        {
          id: 65,
          name: "360手机卫士",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a1a1f406-f41e-11ea-9ba8-0c7a15549768.jpg",
          status: 1,
          channels: "[114, 604, 607]",
        },
        {
          id: 71,
          name: "悦头条",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9ee3c21c-f41e-11ea-8fcf-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 146, 102, 105, 604, 607]",
        },
        {
          id: 73,
          name: "vivo 浏览器",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a2e6ca0c-f41e-11ea-878e-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 604, 503]",
        },
        {
          id: 77,
          name: "OPPO 浏览器",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a2a6f902-f41e-11ea-b07d-0c7a15549768.jpg",
          status: 1,
          channels: "[502]",
        },
        {
          id: 70,
          name: "小米浏览器",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a2cbb336-f41e-11ea-b6c8-0c7a15549768.jpg",
          status: 1,
          channels: "[504, 604, 607]",
        },
        {
          id: 81,
          name: "小米应用商店",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a30542b8-f41e-11ea-8ad5-0c7a15549768.jpg",
          status: 1,
          channels: "[504]",
        },
        {
          id: 83,
          name: "小米音乐",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a3235c28-f41e-11ea-9626-0c7a15549768.jpg",
          status: 1,
          channels: "[504]",
        },
        {
          id: 84,
          name: "小米视频",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a3430a2e-f41e-11ea-aa27-0c7a15549768.jpg",
          status: 1,
          channels: "[504, 601, 604]",
        },
        {
          id: 85,
          name: "小米安全中心",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a381c738-f41e-11ea-837b-0c7a15549768.jpg",
          status: 1,
          channels: "[504, 604]",
        },
        {
          id: 86,
          name: "小米下载管理",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a3a0a20c-f41e-11ea-b8db-0c7a15549768.jpg",
          status: 1,
          channels: "[504]",
        },
        {
          id: 87,
          name: "小米天气",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a3be6374-f41e-11ea-975b-0c7a15549768.jpg",
          status: 1,
          channels: "[504]",
        },
        {
          id: 88,
          name: "小米个性主题",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a3d9e55a-f41e-11ea-9a19-0c7a15549768.jpg",
          status: 1,
          channels: "[504, 607]",
        },
        {
          id: 89,
          name: "美柚",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a16055b6-f41e-11ea-940e-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 604, 119]",
        },
        {
          id: 90,
          name: "柚宝宝",
          category: "工具媒体",
          icon: "https://file3.clipworks.com/a183c2ca-f41e-11ea-b01d-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 604, 119]",
        },
        {
          id: 93,
          name: "OPPO 阅读",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a442d45c-f41e-11ea-9c81-0c7a15549768.jpg",
          status: 1,
          channels: "[502]",
        },
        {
          id: 94,
          name: "OPPO 书城",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a41b90fa-f41e-11ea-92e0-0c7a15549768.jpg",
          status: 1,
          channels: "[502]",
        },
        {
          id: 95,
          name: "小米画报",
          category: "手机厂商",
          icon: "https://file3.clipworks.com/a3fb5aa2-f41e-11ea-b963-0c7a15549768.jpg",
          status: 1,
          channels: "[504, 604, 607]",
        },
        {
          id: 96,
          name: "皮皮虾",
          category: "资讯媒体",
          icon: "https://file3.clipworks.com/9e5131e8-f41e-11ea-825c-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 105, 604, 607]",
        },
        {
          id: 99,
          name: "斗鱼直播",
          category: "视频媒体",
          icon: "https://file3.clipworks.com/a0027542-f41e-11ea-b0ba-0c7a15549768.jpg",
          status: 1,
          channels: "[601, 604, 213]",
        },
        {
          id: 103,
          name: "淘宝 Tanx 媒体",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a55205d8-f41e-11ea-af22-0c7a15549768.jpg",
          status: 1,
          channels: "[601]",
        },
        {
          id: 104,
          name: "京东直投媒体",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a56e4482-f41e-11ea-a862-0c7a15549768.jpg",
          status: 1,
          channels: "[604]",
        },
        {
          id: 108,
          name: "Unity 联盟",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a5886ef0-f41e-11ea-9948-0c7a15549768.jpg",
          status: 1,
          channels: "[1401]",
        },
        {
          id: 109,
          name: "Vungle 联盟",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a5ab72ae-f41e-11ea-b6fe-0c7a15549768.jpg",
          status: 1,
          channels: "[1402]",
        },
        {
          id: 110,
          name: "Applovin 联盟",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a5c9e400-f41e-11ea-8c3c-0c7a15549768.jpg",
          status: 1,
          channels: "[1403]",
        },
        {
          id: 111,
          name: "AdColony 联盟",
          category: "联盟媒体",
          icon: "https://file3.clipworks.com/a5e2a1ca-f41e-11ea-9532-0c7a15549768.jpg",
          status: 1,
          channels: "[1404]",
        },
      ],
      // 选中的广告媒体ID集合
      mediaIds: [],
      // 是否展示更多广告媒体搜索内容
      isMediaOpen: true,
      // 视频版式搜索集合
      videoLayoutList: [
        { id: "", name: "全部版式" },
        { id: "horizontal", name: "横版" },
        { id: "vertical", name: "竖版" },
      ],
      // 广告媒体搜索集合
      videoLayout: "",
      // 排序选择条件
      soltTypeList: [
        {
          label: "推荐排序",
          isSolt: false,
          isSelected: true,
          soltType: "",
        },
        {
          label: "点击数",
          isSolt: true,
          isSelected: false,
          soltType: "",
        },
        {
          label: "展示数",
          isSolt: true,
          isSelected: false,
          soltType: "",
        },
        // {
        //   label: "转化数",
        //   isSolt: true,
        //   isSelected: false,
        //   soltType: "",
        // },
      ],
      // 当前选中排序条件
      currentSort: ["分区日期", "desc"],
      // 视频展示列表数据
      videoList: [],
      // 视频列表加载中状态
      loading: false,
      // 视频列表总数
      total: 0,
      // 视频列表分页信息
      pages: {
        page: 1,
        size: 20,
      },
      //
      hasPlay: false,
      isAdd: false,
    };
  },
  props: {
    brandId: {
      type: String,
      required: true,
    },
    isGuide: {
      type: Boolean,
      required: true
    },
    daterangeData: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    daterangeData: {
      deep: true,
      immediate: true,
      handler() {
        this.pages.page = 1;
        this.getViedeoList();
      },
    },
  },
  components: {
    // NoData,
  },
  mounted() {
    this.hasPlay = localStorage.getItem("hasPlay") || false;
    this.getViedeoList();
  },
  methods: {
    timeFormat(date, format) {
      return timeFormat(date, format);
    },
    numFormat(num) {
      return numFormat(num);
    },
    numberFormat(num) {
      return numberFormat(num);
    },
    // 获取视频列表
    async getViedeoList() {
      this.loading = true;
      let params = {
        identify: "sg-data-品牌-线上创意视频",
        filters: [
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandId],
          },
          {
            field: "流量平台id",
            cond: 6,
            value: this.channelIds,
          },
          {
            field: "广告媒体id",
            cond: 6,
            value: this.mediaIds,
          },
          {
            field: "版式",
            cond: 0,
            value: this.videoLayout ? [this.videoLayout] : [],
          },
          {
            field: "分区日期",
            cond: 5,
            value: this.daterangeData[0] ? this.daterangeData : [],
          },
        ],
        fields: [
          "品牌名称",
          "行业名称",
          "流量平台icon",
          "水印url",
          "封面",
          "转化数（总数)",
          "点击数（总数)",
          "展示（总数）",
          "播放时长",
          "分区日期",
          "流量平台id",
        ],
        sort: this.currentSort,
        pages: this.pages,
      };
      let res = await GetCommonInfo(params);
      /*eslint-disable*/
      console.log("创意视频列表", res);
      if (res && res.code === 200) {
        localStorage.setItem('cyspDemoData', res?.data?.demoData)
        this.videoList = res.data.items || [];
        this.total = res.data.pages.total;
      }
      this.loading = false;
    },
    // 当前页码切换
    pageChange(page) {
      this.pages.page = page;
      this.getViedeoList();
    },
    // 分页页数切换
    pageSizeChange(size) {
      this.pages.size = size;
      this.getViedeoList();
    },
    // 流量平台筛选条件变化事件
    channelChange() {
      // console.log("流量平台筛选条件切换事件", this.channelIds);
      this.pages.page = 1;
      this.getViedeoList();
    },
    // 广告媒体筛选条件变化事件
    mediaChange() {
      // console.log("流量平台筛选条件切换事件", this.mediaIds);
      this.pages.page = 1;
      this.getViedeoList();
    },
    // 视频版式筛选条件变化事件
    videoLayoutChange(select) {
      this.pages.page = 1;
      this.getViedeoList();
    },
    // 排序选择事件
    clickSoltItem(selectIndex) {
      let corresponding = {
        点击数: "点击数（总数)",
        // 转化数: "转化数（总数)",
        展示数: "展示（总数）",
      };
      this.soltTypeList.forEach((item, index) => {
        if (selectIndex === index) {
          item.isSelected = true;
          if (item.isSolt && item.soltType === "asc") {
            item.soltType = "desc";
            this.currentSort = [corresponding[item.label], item.soltType];
          } else {
            item.soltType = "asc";
            this.currentSort = corresponding[item.label]
              ? [corresponding[item.label], item.soltType]
              : ["分区日期", "desc"];
          }
        } else {
          item.isSelected = false;
          item.soltType = "";
        }
      });
      // console.log("currentSort", this.currentSort);
      this.getViedeoList();
    },
    // 视频播放
    play(index) {
      const video = "video-" + index;
      this.$refs[video][0].play();
      const videoId = this.videoList[index].videoId;
      // 监听视频播放2s后增加播放次数
      this.$refs[video][0].addEventListener("timeupdate", () => {
        const time = Math.floor(this.$refs[video][0].currentTime);
        if (time === 2 && !this.isAdd) {
          // this.addClick(videoId, 0);
          this.isAdd = true;
        }
      });
      if (this.hasPlay) {
        this.$refs[video][0].muted = false;
      }
    },
    // 视频暂停
    pause(index) {
      const video = "video-" + index;
      this.$refs[video][0].muted = true;
      this.$refs[video][0].pause();
      this.isAdd = false;
    },
    // 静音按钮
    playVideo(index) {
      const video = "video-" + index;
      if (this.hasPlay) {
        this.$refs[video][0].muted = true;
        this.hasPlay = false;
        localStorage.setItem("hasPlay", false);
      } else {
        this.$refs[video][0].muted = false;
        this.hasPlay = true;
        localStorage.setItem("hasPlay", true);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.creativityVideo-box {
  background-color: #ffffff;
  width: 100%;
  margin: 18px 0;
  border-radius: 4px;
  .creativityVideo-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 16px;
    font-weight: 500;
    color: #000000;
    line-height: 22px;
    padding: 10px 17px;
    border-bottom: 1px solid #e0e0e0;

    .creative-dz {
      width: 140px;
      height: 40px;
      line-height: 40px;
      font-size: 16px;
      font-weight: 500;
      color: #FFFFFF;
      text-align: center;
      background: linear-gradient(223deg, #4F3BFF 0%, #5A8EFF 100%);
      border-radius: 4px;
    }
    .creative-icon {
      position: relative;
      top: 2px;
    }
    .creative-dz:hover {
      background: linear-gradient(223deg, #3E29F9 0%, #4982FE 100%);
    }
  }
  .creativityVideo-body {
    height: 1231px;
    overflow-y: auto;
    content-visibility: auto;
    contain-intrinsic-size: 1120px 1231px;
  }
  .custom-disabled .selected, .custom-disabled {
    color: #333333 !important;
    cursor: not-allowed !important;
  }
  // 搜索筛选
  .search-box {
    background: #ffffff;
    padding: 14px 0;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid #e0e0e0;
    .search-item {
      display: flex;
      padding: 5px;
      .search-label {
        white-space: nowrap;
        line-height: 23px;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.4);
        // line-height: 16px;
      }
      .search-check-box {
        margin-left: 20px;
        .ivu-form-item-label {
          height: 100%;
        }
        .ivu-checkbox-group-item {
          padding: 3px 0;
          line-height: 20px;
          font-weight: 400;
          margin-right: 10px;
          margin-bottom: 10px;
          width: 100px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          /deep/ .ivu-checkbox {
            display: none;
          }
        }
        .ivu-checkbox-wrapper-disabled {
          color: rgba(0, 0, 0, 0.2);
        }
        .ivu-checkbox-wrapper-checked {
          color: #3519fb;
          cursor: pointer;
        }
      }
      .radio-check-box {
        margin-left: 20px;
        .ivu-form-item-label {
          height: 100%;
        }
        .ivu-radio-group-item {
          padding: 3px 0;
          line-height: 20px;
          font-weight: 400;
          margin-right: 10px;
          margin-bottom: 10px;
          width: 100px;
          text-align: center;
          font-size: 14px;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.8);
          /deep/ .ivu-radio {
            display: none;
          }
        }
        .ivu-radio-wrapper-disabled {
          color: rgba(0, 0, 0, 0.2) !important;
        }
        .ivu-radio-wrapper-checked {
          color: #3519fb;
          cursor: pointer;
        }
      }
      .search-open {
        white-space: nowrap;
        margin-right: 20px;
        line-height: 23px;
        cursor: pointer;
        &:hover {
          color: #3519fb;
        }
      }
    }
    .search-item-hide {
      overflow: hidden;
      height: 35px;
    }
  }
  // 视频展示列表
  .video-box {
    margin-left: 34px;
    margin-right: 34px;
    background: #ffffff;
    position: relative;
    .video-box-header {
      display: flex;
      justify-content: space-between;
      padding: 34px 0;
      .video-header-title {
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        // line-height: 22px;
      }
      .video-header-tool {
        display: flex;
        .video-header-tool-item {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
          line-height: 22px;
          margin-left: 32px;
          display: flex;
          cursor: pointer;
          .video-header-tool-label {
            margin-right: 4px;
          }
          .selected {
            color: #3519fb;
          }
          .video-header-tool-solt {
            position: relative;
            width: 8px;
            height: 22px;
            margin-left: 4px;
            .video-header-tool-ascending {
              font-size: 0;
              position: absolute;
              left: 0;
              top: 5px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 4px;
              border-bottom-width: 5px;
              border-top-width: 0;
              border-color: transparent;
              border-bottom-color: #999999;
            }
            .video-header-tool-descending {
              font-size: 0;
              position: absolute;
              left: 0;
              bottom: 5px;
              width: 0;
              height: 0;
              border-style: solid;
              border-width: 4px;
              border-top-width: 5px;
              border-bottom-width: 0;
              border-color: transparent;
              border-top-color: #999999;
            }
            .ascending {
              border-bottom-color: #3519fb;
            }
            .descending {
              border-top-color: #3519fb;
            }
          }
        }
      }
    }

    .video-list {
      display: flex;
      flex-wrap: wrap;
      .video-list-item {
        margin-right: 20px;
        margin-bottom: 20px;
        width: 246px;
        height: 505px;
        background: #ffffff;
        border-radius: 10px;
        border: 1px solid #e6e6e6;
        border-radius: 10px;
        .viedeo-item-content {
          display: flex;
          align-items: center;
          overflow: hidden;
          position: relative;
          z-index: 1;
          width: 246px;
          height: 377px;
          border-radius: 10px;
          .viedeo-item-content-bg {
            position: absolute;
            top: 0;
            left: 0;
            display: inline-block;
            width: 100%;
            height: 100%;
            filter: blur(10px);
            z-index: -1;
            background-size: 100% 100%;
          }
          .viedeo-item-content-industry {
            background: rgba(0, 0, 0, 0.49);
            border-radius: 0px 0px 0px 19px;
            padding: 5px 16px;
            color: #ffffff;
            line-height: 20px;
            position: absolute;
            top: 0;
            right: 0;
            z-index: 100;
          }
          .viedeo-item-content-play {
            width: 50px;
            height: 50px;
            position: absolute;
            top: calc(50% - 25px);
            left: calc(50% - 25px);
            z-index: 10;
          }
          .viedeo-item-content-poster,
          .viedeo-item-content-video {
            position: absolute;
            width: 100%;
            border-radius: 10px;
            border: none;
          }
          .viedeo-item-content-video {
            visibility: hidden;
          }
          .viedeo-item-content-muted {
            width: 42px;
            height: 34px;
            background: rgba(0, 0, 0, 0.57);
            padding: 8px 12px;
            line-height: 20px;
            position: absolute;
            bottom: 18px;
            right: 16px;
            z-index: 100;
            border-radius: 4px;
            display: none;
          }
          &:hover {
            transform: scale(1.1);
            transition: all 0.28s ease-in 0.1s;
            & .viedeo-item-content-video {
              visibility: visible;
              z-index: 2;
              width: 100%;
              height: auto;
              border-radius: 10px;
            }
            .viedeo-item-content-industry,
            .viedeo-item-content-play,
            .viedeo-item-content-poster {
              display: none;
            }
            .viedeo-item-content-muted {
              display: block;
            }
          }
        }
        .viedeo-item-footer {
          padding: 20px;
          padding-bottom: 0;
          .viedeo-item-footer-title-box {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px dashed #c9c9c9;
            padding-bottom: 7px;
            color: rgba(0, 0, 0, 0.8);
            line-height: 20px;
            font-weight: 400;
            /deep/ .viedeo-item-footer-title {
              white-space: nowrap;
              text-overflow: ellipsis;
              // width: 120px;
              overflow: hidden;
              display: inline-block;
              cursor: pointer;
            }
          }
          .viedeo-item-footer-exhibition-box {
            display: flex;
            // justify-content: space-between;
            padding-top: 9px;
            padding-bottom: 13px;
            .viedeo-item-footer-exhibition-item {
              margin-right:30px
            }
            .viedeo-item-footer-exhibition {
              img {
                width: 16px;
                height: 16px;
                vertical-align: middle;
                margin-right: 4px;
              }
              span {
                line-height: 20px;
                vertical-align: middle;
              }
            }
          }
          .viedeo-item-footer-media-box {
            span {
              display: inline-flex;
              align-items: center;
            }
            span + span {
              margin-left: 10px;
            }
            img {
              width: 20px;
              height: 20px;
              border-radius: 4px;
              border: none;
              /*margin-right: 5px;*/
            }
          }
        }
      }
      .video-list-item:nth-child(4n + 4) {
        margin-right: 0px;
      }
    }
    .video-no-date {
      width: 100%;
      height: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .video-page {
      padding-top: 12px;
      padding-bottom: 24px;
      text-align: right;
      display: flex;
      justify-content: flex-end;
      /deep/ .ivu-page-item,
      /deep/ .ivu-page-prev,
      /deep/ .ivu-page-next {
        line-height: 22px;
        padding: 5px 12px;
        width: auto;
        height: auto;
        &:hover {
          color: #ffffff;
          background: #3519fb;
          a {
            color: #ffffff;
          }
        }
      }
      /deep/ .ivu-select-selection {
        line-height: 22px;
        padding: 5px 12px;
        width: auto;
        height: auto;
        &:hover {
          // color: #ffffff;
          border-color: #3519fb;
        }
      }
      /deep/ .ivu-select-visible .ivu-select-selection {
        border-color: #3519fb;
      }
      /deep/ .ivu-page-item-active {
        background: #3519fb;
        color: #ffffff;
        &:hover a,
        a {
          color: #ffffff;
          background: #3519fb;
        }
      }
      /deep/ .ivu-select-item-selected {
        color: #3519fb;
      }
      /deep/ .ivu-page-item-jump-next .ivu-icon-ios-arrow-forward {
        color: #3519fb;
      }
    }
  }
}
</style>

<template>
  <div class="creativityPut-box">
    <div class="content-item">
      <div class="item-title">
        投放媒体占比
        <Poptip trigger="hover" placement="right" padding="0 0">
          <Icon type="ios-help-circle-outline" class="help_icon" />
          <div class="m-tips" slot="content">
            <p><span>• 广告创意投放的媒体平台偏好占比。</span></p>
          </div>
        </Poptip>
      </div>
      <div class="chart-box">
        <pieChart :echartData="PreferenceData" :legendShow="true"></pieChart>
      </div>
    </div>
    <div class="content-item">
      <div class="item-title">
        创意投放趋势
        <Poptip trigger="hover" placement="right" padding="0 0">
          <Icon type="ios-help-circle-outline" class="help_icon" />
          <div class="m-tips" slot="content">
            <p><span>• 每日创意广告的投放趋势。</span></p>
          </div>
        </Poptip>
      </div>
      <div class="chart-box">
        <LineChart
          :echartData="putData"
          :lineColor="'#3519FB'"
          :textColor="'#848295'"
          :colorStopsStart="'rgba(62,78,255,0.1)'"
          :colorStopsEnd="'rgba(62,78,255,0)'"
          categoryTitle="投放量"
          :minLength="putData.length - 7"
          :maxLength="putData.length - 1"
          :isSmooth="false"
        ></LineChart>
      </div>
    </div>
    <div class="content-item">
      <div class="item-title">
        创意曝光趋势
        <Poptip trigger="hover" placement="right" padding="0 0">
          <Icon type="ios-help-circle-outline" class="help_icon" />
          <div class="m-tips" slot="content">
            <p><span>• 每日广告投放曝光次数趋势。</span></p>
          </div>
        </Poptip>
      </div>
      <div class="chart-box">
        <LineChart
          :echartData="exposureData"
          :lineColor="'#03B7A3'"
          :textColor="'#848295'"
          :colorStopsStart="'rgba(0,187,135,0.08)'"
          :colorStopsEnd="'rgba(0,187,135,0)'"
          categoryTitle="曝光量"
          :minLength="exposureData.length - 7"
          :maxLength="exposureData.length - 1"
          :isSmooth="false"
        ></LineChart>
      </div>
    </div>
    <div class="content-item">
      <div class="item-title">
        创意点击趋势
        <Poptip trigger="hover" placement="right" padding="0 0">
          <Icon type="ios-help-circle-outline" class="help_icon" />
          <div class="m-tips" slot="content">
            <p><span>• 每日广告投放点击次数趋势。</span></p>
          </div>
        </Poptip>
      </div>
      <div class="chart-box">
        <LineChart
          :echartData="clickData"
          :lineColor="'#F89C00'"
          :textColor="'#848295'"
          :colorStopsStart="'rgba(248,156,0,0.1) '"
          :colorStopsEnd="'rgba(248,156,0,0)'"
          categoryTitle="点击量"
          :minLength="clickData.length - 7"
          :maxLength="clickData.length - 1"
          :isSmooth="false"
        ></LineChart>
      </div>
    </div>
  </div>
</template>
<script>
import LineChart from "@/components/chart/lineChart.vue";
import PieChart from "@/components/chart/pieChart.vue";
import { GetCommonInfo } from "@/service";

export default {
  name: "CreativityPut",
  props: {
    brandId: {
      type: String,
      required: true,
    },
    // 创意投放趋势图表数据
    putData: {
      type: Array,
      default: () => [],
    },
    // 创意曝光趋势图表数据
    exposureData: {
      type: Array,
      default: () => [],
    },
    // 创意点击趋势图表数据
    clickData: {
      type: Array,
      default: () => [],
    },
    filterDate: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 投放媒体偏好图表数据
      PreferenceData: [],
    };
  },
  components: {
    LineChart,
    PieChart,
  },
  async mounted() {
    this.$nextTick(() => {
      this.getPreference();
    });
  },
  watch: {
    filterDate(val) {
      this.getPreference();
    },
  },
  methods: {
    // 获取投放媒体偏好图表信息
    async getPreference() {
      let params = {
        identify: "sg-data-品牌-线上创意视频",
        filters: [
          {
            field: "分区日期",
            cond: 5,
            value: this.filterDate[0] ? this.filterDate : [],
          },
          {
            field: "品牌id",
            cond: 0,
            value: [this.brandId],
          },
        ],
        fields: ["流量平台名称", "数量"],
      };
      let res = await GetCommonInfo(params);
      /*eslint-disable*/
      console.log("创意投放门店分布", res);
      if (res && res.code === 200) {
        localStorage.setItem('cytfDemoData', res?.data?.demoData)
        this.PreferenceData = res?.data?.items?.map(item => {
          return { name: item[0], value: item[1] }
        })
      }
    },
  },
};
</script>
<style lang="less" scoped>
.creativityPut-box {
  display: flex;
  flex-wrap: wrap;
  .content-item:nth-child(odd) {
    border-right: 1px solid #eeeeee;
  }
  .content-item {
    width: 551px;
     height: 433px;
     background: #ffffff;
     display: flex;
     flex-wrap: wrap;
     align-content: flex-start;
     border-bottom: 1px solid #eeeeee;
     .item-title {
       width: 100%;
       display: flex;
       align-items: center;
       font-size: 14px;
       font-weight: 500;
       color: rgba(0, 0, 0, 0.7);
       box-sizing: border-box;
       padding: 25px 30px 0 30px;
     }

    .chart-box {
      @cap: 56px;
      width: 100%;
      height: calc(~"100% - @{cap}");
      padding: 20px;
    }
  }
  .content-item:nth-child(2n) {
    margin-right: 0;
  }
}
.help_icon {
  /* margin-top: 1px; */
  width: 16px;
  height: 16px;
  margin-left: 8px;
  color: #999;
  &:hover {
    color: #415ef5;
  }
}
/deep/.m-tips {
  // width: 520px;
  // height: 500px;
  padding: 16px;
  padding-bottom: 0px;
  font-size: 14px;
  color: #999999;
  line-height: 20px;
  h5 {
    font-size: 14px;
    margin-bottom: 16px;
  }
  p {
    display: block;
    margin-bottom: 16px;
  }
  h5,
  span {
    color: #333333;
  }
}
</style>

<template>
  <!--相似推荐-->
  <div class="similar-list" v-if="swiperShow">
    <swiper ref="mySwiper" v-bind:options="swiperOptions">
      <swiper-slide
        v-for="(item, index) in listData"
        v-bind:key="index"
        class="list-item"
        :id="item.id"
      >
        <div class="item-img" @click="itemClick(item)">
          <img :src="item.imgUrl" v-if="item.imgUrl" />
          <template v-else>
            <span class="pic-name text-ellipsis2 center-middle-box">{{ item.title }}</span>
            <img src="@/assets/images/business/logobg2.png" alt="">
          </template>
        </div>
        <div class="item-title">{{ item.title }}</div>
        <div class="item-content" v-if="false">{{ item.content }}</div>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
    </swiper>
    <div class="swiper-button-prev" slot="button-prev"></div>
    <div class="swiper-button-next" slot="button-next"></div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import { openNewWindow } from "@/assets/javascript/utils.js";
import { GetDetailBrowsingRecord } from "@/service";

export default {
  props: ["listData", "type"],
  data() {
    return {
      swiperOptions: {
        loop: false,
        speed: 600,
        slidesPerView: 6,
        slidesPerGroup: 6,
        // autoplay: {
        //     delay: 3000,
        //     stopOnLastSlide: false,
        //     disableOnInteraction: false,
        // },
        // 显示分页
        // pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true, //允许分页点击跳转
        // },
        // on: {
          // click: async (e) => {
          //   console.log('e', e);
            // let index_id = e.path[1].id;
            // const res = await GetDetailBrowsingRecord({
            //   module: "brandDetail",
            //   detailID: index_id,
            // });
            // console.log('res',res);
            // if (res && res.code == 200 && res.data == "success") {
            //   openNewWindow("/brandDetail", {
            //     id: index_id,
            //   });
            // }
          // },
        // },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      },
      swiperShow: true,
    };
  },
  components: {
    Swiper,
    SwiperSlide
  },
  watch: {
    listData(val) {
      //this.resetSwiper()
    },
  },
  created() {},
  methods: {
    resetSwiper() {
      this.swiperShow = true;
      this.swiperOptions = {
        loop: false,
        speed: 600,
        slidesPerView: 6,
        slidesPerGroup: 6,
        // autoplay: {
        //     delay: 3000,
        //     stopOnLastSlide: false,
        //     disableOnInteraction: false,
        // },
        // 显示分页
        // pagination: {
        //     el: ".swiper-pagination",
        //     clickable: true, //允许分页点击跳转
        // },
        // 设置点击箭头
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true, //修改swiper的父元素时，自动初始化swiper
      };
      //一屏展示N个，数量少于N时，禁止循环，避免造成复制多个出现
      if (this.listData.length <= this.swiperOptions.slidesPerView) {
        this.swiperOptions.loop = false;
      }
    },
    async itemClick (item) {
      let index_id = item.id;
      const res = await GetDetailBrowsingRecord({
        module: "brandDetail",
        detailID: index_id,
      });
      if (res && res.code == 200 && res.data == "success") {
        openNewWindow("/brandDetail", {
          id: index_id,
        });
      }
    }
    // detailClick(item){
    //   console.log(this.type,1111)
    //   if(this.type === "品牌"){
    //     this.$router.push("/brandDetail?id=" + item.id + "")
    //   }
    // }
  },
};
</script>

<style lang="less" scoped>
.similar-list {
  width: 100%;
  -moz-user-select: none;
  /*火狐*/
  -webkit-user-select: none;
  /*webkit浏览器*/
  -ms-user-select: none;
  /*IE10*/
  -khtml-user-select: none;
  /*早期浏览器*/
  user-select: none;

  .swiper-container {
    width: 960px;
    margin-left: 90px;
  }

  .swiper-button-prev,
  .swiper-button-next {
    width: 32px;
    height: 32px;
    background-color: #ececec;
    border-radius: 2px;
    top: 52%;
  }

  .swiper-button-next:after,
  .swiper-container-rtl .swiper-button-prev:after {
    content: "";
  }

  .swiper-button-prev:after,
  .swiper-container-rtl .swiper-button-next:after {
    content: "";
  }

  .swiper-button-next,
  .swiper-container-rtl .swiper-button-prev {
    background-image: url("../assets/images/icon/group_right_hover.png");
    background-repeat: no-repeat;
    background-size: 14px 14px;
    opacity: 1;
    background-position: center center;
    right: 30px;
  }

  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
    background-image: url("../assets/images/icon/group_left_hover.png");
    background-repeat: no-repeat;
    background-size: 14px 14px;
    opacity: 1;
    background-position: center center;
    left: 30px;
  }

  .list-item {
    width: 140px;
    height: auto;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    line-height: 1;
    cursor: pointer;

    .item-img {
      width: 140px;
      height: 140px;
      border-radius: 8px;
      overflow: hidden;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: auto;
        height: auto;
        max-width: 140px;
        max-height: 140px;
        border-radius: 8px;
      }
      .pic-name {
        width: 90px;
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 1.5;
      }
    }
    .item-img::after {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      border: 1px solid rgba(0, 0, 0, 0.1);
      border-radius: 8px;
      z-index: 9;
    }

    .item-title {
      width: 100%;
      margin: 16px 0 0 0;
      font-size: 16px;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.8);
      line-height: 22px;
    }

    .item-content {
      font-size: 14px;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.4);
      margin: 8px 0 0 0;
      line-height: 22px;
    }

    .item-id {
      display: none;
    }
  }

  .swiper-button-prev.swiper-button-disabled {
    background-image: url("../assets/images/icon/group_left.png");
    background-repeat: no-repeat;
    background-size: 14px 14px;
    opacity: 1;
    background-position: center center;
  }

  .swiper-button-next.swiper-button-disabled {
    background-image: url("../assets/images/icon/group_right.png");
    background-repeat: no-repeat;
    background-size: 14px 14px;
    opacity: 1;
    background-position: center center;
  }
}
</style>
